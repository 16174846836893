import { commonServices } from './common.services';
import { config } from './config';

export const addressService = {
    SaveAddress,
    UpdateAddress,
    DeleteAddress,
    FetchAddressesByUser,
    UpdateDefaultAddress,
    ShippingAddress
}

async function SaveAddress(addressDetails) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "title": "",
            "receiver_name": addressDetails?.firstName  + " " + addressDetails?.lastName,
            "pin_code": addressDetails?.pinCode,
            "email": addressDetails?.emailID,
            "mobile": addressDetails?.mobileNo,
            "address": addressDetails?.Address,
            "country": "India",
            "state": addressDetails?.state.split("_")[0],
            "city": addressDetails?.city,
            "is_default": addressDetails?.is_default,
            "addressType": addressDetails?.AddressType,
            "locality": addressDetails?.locality
         })
    }

    return await fetch(config.apiUrl + 'Address/SaveAddress', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function UpdateAddress(addressDetails) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "address_id": addressDetails?.address_id,
            "title": "",
            "receiver_name":addressDetails?.receiver_name ? addressDetails?.receiver_name : addressDetails?.firstName  + " " + addressDetails?.lastName,
            "pin_code": addressDetails?.pin_code ?? addressDetails?.pinCode,
            "email": addressDetails?.email ?? addressDetails?.emailID,
            "mobile": addressDetails?.mobile ?? addressDetails?.mobileNo,
            "address": addressDetails?.address ?? addressDetails?.Address,
            "country": addressDetails?.country ?? "India",
            "state": addressDetails?.state.includes("_") ? addressDetails?.state.split("_")[0] : addressDetails?.state,
            "city": addressDetails?.city,
            "is_default": addressDetails?.is_default,
            "addressType": addressDetails?.addressType ?? addressDetails?.AddressType,
            "locality": addressDetails?.locality,
            "user_id": "",
         })
    }

    return await fetch(config.apiUrl + 'Address/UpdateAddress', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function DeleteAddress(addressId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "address_id": addressId        
         })
    }

    return await fetch(config.apiUrl + 'Address/DeleteAddress', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function FetchAddressesByUser() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'Address/FetchAddressesByUser', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function UpdateDefaultAddress(addressDetails) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "address_id": addressDetails?.address_id,
            "feature_id": "PRO013",
            "title": "",
            "receiver_name": addressDetails?.firstName  + " " + addressDetails?.lastName,
            "pin_code": addressDetails?.pinCode,
            "email": addressDetails?.emailID,
            "mobile": addressDetails?.mobileNo,
            "address": addressDetails?.Address,
            "country": "India",
            "state": addressDetails?.state.includes("_") ? addressDetails?.state.split("_")[0] : addressDetails?.state,
            "city": addressDetails?.city,
            "is_default": addressDetails?.is_default,
            "addressType": addressDetails?.AddressType,
            "locality": addressDetails?.locality,
            "is_shippingAddress": true
        })
    }

    return await fetch(config.apiUrl + 'Address/UpdateDefaultAddress', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function ShippingAddress(addressDetails) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "address_id": addressDetails?.address_id,
            "feature_id": "PRO013",
            "title": "",
            "receiver_name": addressDetails?.receiver_name,
            "pin_code": addressDetails?.pinCode,
            "email": addressDetails?.emailID,
            "mobile": addressDetails?.mobileNo,
            "address": addressDetails?.Address,
            "country": "India",
            "state": addressDetails?.state.includes("_") ? addressDetails?.state.split("_")[0] : addressDetails?.state,
            "city": addressDetails?.city,
            "is_default": addressDetails?.is_default,
            "addressType": addressDetails?.AddressType,
            "locality": addressDetails?.locality,
            "is_shippingAddress": true
        })
    }

    return await fetch(config.apiUrl + 'Address/ShippingAddress', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}