import { commonServices } from './common.services';
import { config } from './config';

export const paymentService = {
    PaymentProcess,
    GetAvailableBalance,
    ResendPaymentOTP,
    VerifyOtp,
    RedeemCode
}

async function PaymentProcess(points, paymentMode, orderId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "points": points ? points : "",
            "paymentMode": paymentMode,
            "orderId": orderId
         })
    }

    return await fetch(config.apiUrl + 'Payment/PaymentProcess', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetAvailableBalance() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
         })
    }

    return await fetch(config.apiUrl + 'Payment/GetAvailableBalance', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function ResendPaymentOTP(orderId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "orderId": orderId
         })
    }

    return await fetch(config.apiUrl + 'Payment/ResendPaymentOTP', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function VerifyOtp(otp, points, orderId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "otp": otp,
            "points": points,
            "orderId": orderId
         })
    }

    return await fetch(config.apiUrl + 'Payment/VerifyOtp', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function RedeemCode(mobile, code) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "mobile": mobile ? mobile : "",
            "code": code
         })
    }

    return await fetch(config.apiUrl + 'Payment/RedeemCode', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}