import React, { useState } from "react";
import "./_footer.scss";
import { commonServices } from "../../Utility/common.services";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

function Footer(props) {
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  // const [screnS, setScrenS] = useState(window.innerWidth);

  const [showHumburger, setShowHumburger] = useState(false)
  const openHumburger = () => {
    setShowHumburger(!showHumburger)
  }
  const outSideClick = () => {
    setShowHumburger(false)
  }
  return (
    <div className="footer_mainDiv">
      {props.screenSize <= 735 ?
        <div className="mobile_footer row">
          {/* <div className="optionTab col-9">
            <Link className="option_div" to="/deals">Deal</Link>
            <Link className="option_div" to="/shop">Shop</Link>
            <Link className="option_div" to="/">Gifting</Link>
          </div> */}
          <div className="options_div">
            <div className="footer_options">
              <img src={images['searchIcon.svg']} alt="" />
              <label>Profile</label>
            </div>
            <div className="footer_options">
              <img src={images['searchIcon.svg']} alt="" />
              <label>Offer</label>
            </div>
            <div className="footer_options">
              <img src={images['searchIcon.svg']} alt="" />
              <label>Deals</label>
            </div>
            <div className="footer_options" onClick={openHumburger}>
              <img src={images['searchIcon.svg']} alt="" />
              <label>More</label>
            </div>
          </div>
          {showHumburger &&
            <OutsideClickHandler
              onOutsideClick={outSideClick}
            >
              < div className="menu_content_wrap row">
                <div className="icon_wrap col-3" >
                  <div className="change_store" >
                    <img src={images['searchIcon.svg']} alt="videoSlot" />
                  </div>
                  <p>Test</p>
                </div>
                <div className="icon_wrap col-3" >
                  <div className="change_mobile" >
                    <img src={images['searchIcon.svg']} alt="videoSlot" />
                  </div>
                  <p>Test</p>
                </div>
                <div className="icon_wrap col-3" >
                  <div className="videoSlot" >
                    <img src={images['searchIcon.svg']} alt="videoSlot" />
                  </div>
                  <p>Test</p>
                </div>

              </div>
            </OutsideClickHandler>
          }
        </div>
        :
        <React.Fragment>
          <div className="footer-top">
            <div className={props.screenSize <= 991 ? "shiping-social_box py-4 px-3" : "shiping-social_box py-5 container"}>
              <div className="shiping_wrap">
                <div className="service-box">
                  <img
                    src={images["freeshipping.svg"]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="txt-box">
                    <span>Free Shipping</span>
                    <p>for orders above 1999</p>
                  </div>
                </div>
                <div className="service-box">
                  <img
                    src={images["easyreturns.svg"]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="txt-box">
                    <span>Easy Returns</span>
                    <p>2 day easy returns for most products</p>
                  </div>
                </div>
                {/* <div className="service-box">
                  <img
                    src={images["authenticate.svg"]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="txt-box">
                    <span>Authentic Products</span>
                    <p>15 day easy returns for most products</p>
                  </div>
                </div> */}
                <div className="service-box">
                  <img
                    src={images["delivery_icon.svg"]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="txt-box">
                    <span>Online Delivery</span>
                    <p>Promise meets always</p>
                  </div>
                </div>
                <div className="service-box">
                  <img
                    src={images["brands.svg"]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="txt-box">
                    <span>2400+ Brands</span>
                    <p>Well Curated 10k+ Products</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashed-line"></div>

            <div className={props.screenSize <= 991 ? "ul-li-wrap py-4 px-3" : "ul-li-wrap py-4 container"}>
              <div>
                <h3>Contact Us</h3>
                <p>Email - <Link to="" className="link-style">customercare@trc.in</Link></p>
                <p>Phone - <Link to="" className="link-style">847-000-3279</Link></p>
                <p>Monday - Saturday - 10am to 7pm</p>
              </div>

              <div>
                <h3>Help</h3>
                <p><Link to="/faq" className="link-style">FAQ</Link></p>
                <p><Link to="/cancellationandreturn" className="link-style">Cancellation and Return</Link></p>
                <p><Link to="/termsandconditions" className="link-style">Terms & Condition</Link></p>
                <p><Link to="/aboutus" className="link-style">About Us</Link></p>
              </div>

              {/* <div>
                <h3>Sign up for Newsletter</h3>
                <div className="d-flex gap-2">
                  <input
                    placeholder="Enter your email"
                  />
                  <div className="button-container">
                    <span>Send</span>
                  </div>
                </div>
              </div> */}

              <div>
                <h3> Social media</h3>
                <div className="img-box-social">
                  <Link to="https://www.instagram.com/therewardco_/" target="_blank" rel="noopener noreferrer">
                    <img
                      src={images["instagram_icon.png"]}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="https://www.facebook.com/therewardco/" target="_blank" rel="noopener noreferrer">
                    <img
                      src={images["facebook_icon.png"]}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                  <Link to="https://x.com/therewardco" target="_blank" rel="noopener noreferrer"><img
                    src={images["twitter_icon.png"]}
                    alt="img"
                    className="img-fluid"
                  /></Link>
                   <Link to="https://www.linkedin.com/company/the-reward-company/" target="_blank" rel="noopener noreferrer"><img
                    src={images["linkedIn.svg"]}
                    // style={{height:'20px'}}
                    alt="img"
                    className="img-fluid"
                  /></Link>
                  {/* <Link to="">
                    <img
                      src={images["youtube_icon.png"]}
                      alt="img"
                      className="img-fluid"
                    /></Link> */}
                </div>
              </div>
            </div>
            <div className="footer-bottom px-3 py-2">
              <div className="term-policy-wrap">
                <span>© 2024 TRC. All Rights Reserved.</span>
                {/* <span>Shipping Policy</span>
      <span>Cancellation Policy</span>
      <span>Privacy Policy</span> */}
              </div>
              <div className="icons_wrap">
                <span>Secure Payments</span>
                <img src={images["visa.svg"]} alt="img" className="img-fluid" />
                <img src={images["mastercard.svg"]} alt="img" className="img-fluid" />
                <img src={images["maestro.svg"]} alt="img" className="img-fluid" />
                <img src={images["visa_election.svg"]} alt="img" className="img-fluid" />
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    </div >

  );
}
const mapStateToProps = state => {
  return {
    screenSize: state.screenSize
  }
}

export default connect(mapStateToProps, null)(Footer);

