import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import { commonComponets } from "../../Common/commonComponent";
import Modal from "react-bootstrap/Modal";
import { commonServices } from "../../../Utility/common.services";
import { orderService } from "../../../Utility/order.service";
import { ToastContainer, toast } from "react-toastify";

const ReturnModal = (props) => {
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [cancelReasons, setCancelReasons] = useState([
    { id: '1', name: "Damaged Product Received" },
    { id: '2', name: "Incorrect Product Received" },
    { id: '3', name: "Size Issue" },
    { id: '4', name: "Color Issue" },
    { id: '5', name: "Price Issue" },
    { id: '6', name: "Quality Issue" },
    { id: '7', name: "Did not like the product" },
    { id: '8', name: "Product not working" },
    { id: '9', name: "Product not required" },
    { id: '10', name: "Order placed by mistake" },
  ]);
  const [selectedReason, setSelectedReason] = useState(cancelReasons[0])
  const [comment, setComment] = useState('')
  const { returnOrder, handleReturnModal, orderId, sku } = props


  const returnOrderFn = async () => {
    setLoader(true);
    setLoadingPrefixText("Please wait");
    try {
      let response = await orderService.ReturnAWBOrder(
        orderId,
        sku.sku,
        selectedReason.name,
        comment);
      if (response?.status?.code === 200) {
        handleReturnModal()
        //setTrackingDetails(response?.data);
      }
      else {
        toast.error(response?.status.message);
      }
      setLoader(false);
      setLoadingPrefixText("");
    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
      console.log("Error in getOrder", e);
    }
  };

  return (
    <Modal
      className="trackModal_wrap"
      show={returnOrder}
      centered
      size="md"
    >
      {loader && (
        <div className="loader-overlay flight_loader">
          <div className="boot-strap-loader">
            <div className="spinner-border text-warning" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
          <p>
            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
          </p>
        </div>
      )}

      <div className="trackModal_content_wrap cancelModal-wrap ">
        <div className="exit" onClick={handleReturnModal}>
          <img
            src={images["cross_icon.svg"]}
            alt="cross"
            className="cross"
          />
        </div>
        <h4>Return Item</h4>
        <span>Are you sure you want to return this item?</span>
        <div className="">
          <div className="product-view-track  ">
            <div className="img-size-quant-modal">
              <img
                src={commonServices.imageByDimensions(
                  sku?.stock_item_image?.[0],
                  "10by10"
                )}
                alt="star"
              />

              <div className="details-product">
                <h4>{sku?.brandName}</h4>
                <p>{sku?.stock_item_title}</p>
                <div className="span-wrap">
                  {/* <span>Size:M</span>
                <span>Color:Blue</span> */}
                  <span>Qty: {sku?.quantity}</span>
                </div>
                {commonComponets.PointBox(sku?.points)}
              </div>
            </div>
          </div>
          <div className="return-cancel-container">
          <div className="cancel_reasons">
            {/* {commonComponets.SingleDropDown(
            "Reason",
            cancelReasons,
            "Select a reason to cancel item"
          )} */}
            {commonComponets.SingleDropDownComponent(
              "Select a reason to return item",
              cancelReasons,
              setSelectedReason,
              selectedReason
            )}
          </div>
          <div class="text-area-form-group mt-3 mb-3">
            <textarea id="exampleTextarea" placeholder="Type Here" value={comment} onChange={(e) => setComment(e.target.value)} maxLength={500}></textarea>
            <label for="exampleTextarea">Comment</label>
          </div>
          </div>
          
          <div className="btn-cancel-order">
            <button onClick={handleReturnModal}>Don't Return</button>
            <button className="cancelbtn" onClick={() => returnOrderFn()}>
              Return Item
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  )
}

export default ReturnModal
