import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import 'swiper/scss';
import "swiper/css/bundle";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import ProfileSideView from "./Components/UserDetails";
import Dashboard from "./Components/Dashboard/dashboard";
import Travel from "./Components/Travel/travel";
import "bootstrap/dist/css/bootstrap.min.css";
import Shop from "./Components/Shop/shop";
import Filter from "./Components/Common/filter";
import WomenWear from "./Components/Shop/Category/womenWear";
import ProductDetails from "./Components/Shop/Category/productDetails";
import AddressDetails from "./Components/Shop/Category/addressDetails";
import Deals from "./Components/Deals/deals";
import Utility from "./Components/UtilityTab/utility";
import Login from "./Components/Login/Login";
import DealDetails from "./Components/Deals/DealDetails";
import OrderDetails from "./Components/Common/OrderDetails";
import PaymentPage from "./Components/Common/PaymentPage";
import OrderConfirm from "./Components/Common/OrderConfirm";

import { useSelector, useDispatch } from "react-redux";
import OrderHistory from "./Components/UserDetails/TravelHistory/orderDetails";
import Voucher from "./Components/Voucher/voucher";
import { useEffect } from "react";
import { cartService } from "./Utility/cart.service";
import DeliveryDetails from "./Components/Common/DeliveryDetails";
import VoucherDetails from "./Components/Voucher/VoucherDetails";
import { micrositeService } from "./Utility/microsite.service";
import { ToastContainer } from "react-toastify";
import FAQ from "./Components/Footer/FAQ";
import AboutUs from "./Components/Footer/AboutUs";
import CancellationReturn from "./Components/Footer/CancellationReturn";
import TermsCondition from "./Components/Footer/TermsCondition";
import ErrorPage from "./Components/Common/ErrorPage";
import ErrorBoundary from "./Components/Errorboundary/Errorboundary";

function App() {
  const isShowLoginForm = useSelector((state) => state.isShowLoginForm);

  useEffect(() => {
    // try {
    //   let res = micrositeService.BypassValidateOTP();
    // } catch (e) {
    //   console.log("Error in BypassValidateOTP", e);
    // }
    //GenerateOTP();
    //ValidateOTP();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <ErrorBoundary>
        <Routes>
          <Route path="*" exact element={<Navigate from="*" to="/" />} />
          <Route
            path="/"
            exact
            element={<Navigate from="/" to="/dashboard" />}
          />
          <Route
            path="/profile"
            element={
              <div className="main-container">
                <Header />
                <ProfileSideView />
                <Footer />
              </div>
            }
          />
          <Route
            path="/shop"
            element={
              <div className="main-container">
                <Header />
                <Shop />
                <Footer />
              </div>
            }
          />
          <Route path="/filter" element={<Filter />} />
          <Route path="/womenwear" element={<WomenWear />} />
          <Route
            path="/productdetails"
            element={
              <div className="main-container">
                <Header />
                <ProductDetails />
                <Footer />
              </div>
            }
          />
          <Route
            path="/addressdetails"
            element={
              <div className="main-container">
                <Header />
                <AddressDetails />
                {/* <Footer /> */}
              </div>
            }
          />
          <Route
            path="/deals"
            element={
              <div className="main-container">
                <Header />
                <Deals />
                <Footer />
              </div>
            }
          />
          <Route
            path="/utility"
            element={
              <div className="main-container">
                <Header />
                <Utility />
                <Footer />
              </div>
            }
          />
          <Route
            path="/travel"
            element={
              <div className="main-container">
                <Header />
                <Travel />
                <Footer />
              </div>
            }
          />
          <Route path="/footer" element={<Footer />} />
          <Route
            path="/dashboard"
            exact
            element={
              <div className="main-container">
                <Header />
                <Dashboard />
                <Footer />
              </div>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dealdetails"
            element={
              <div className="main-container">
                <Header />
                <DealDetails />
                <Footer />
              </div>
            }
          />
          <Route
            path="/orderdetails"
            element={
              <div className="main-container">
                <Header />
                <OrderDetails />
              </div>
            }
          />
          <Route
            path="/account"
            element={
              <div className="main-container">
                <Header />
                <OrderHistory />
              </div>
            }
          />
          <Route
            path="/paymentpage"
            element={
              <div className="main-container">
                <Header />
                <PaymentPage />
              </div>
            }
          />
          <Route
            path="/orderconfirm"
            element={
              <div className="main-container">
                <Header />
                <OrderConfirm />
              </div>
            }
          />
          <Route
            path="/voucher"
            element={
              <div className="main-container">
                <Header />
                <Voucher />
                <Footer />
              </div>
            }
          />
          <Route
            path="/deliverydetails"
            element={
              <div className="main-container">
                <Header />
                <DeliveryDetails />
                {/* <Footer /> */}
              </div>
            }
          />
          <Route
            path="/voucherdetails"
            element={
              <div className="main-container">
                <Header />
                <VoucherDetails />
                <Footer />
              </div>
            }
          />
          <Route
            path="/faq"
            element={
              <div className="main-container">
                <Header />
                <FAQ />
                <Footer />
              </div>
            }
          />
          <Route
            path="/aboutus"
            element={
              <div className="main-container">
                <Header />
                <AboutUs />
                <Footer />
              </div>
            }
          />
          <Route
            path="/cancellationandreturn"
            element={
              <div className="main-container">
                <Header />
                <CancellationReturn />
                <Footer />
              </div>
            }
          />
          <Route
            path="/termsandconditions"
            element={
              <div className="main-container">
                <Header />
                <TermsCondition />
                <Footer />
              </div>
            }
          />
          <Route
            path="/errorpage"
            element={
              <div className="main-container">
                <Header />
                <ErrorPage />
              </div>
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
    </div >
  );
}

export default App;
