import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import { commonComponets } from "../../Common/commonComponent";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { commonServices } from "../../../Utility/common.services";
import { orderService } from "../../../Utility/order.service";
import { connect } from "react-redux";
import TrackItemModal from "../Modals/TrackItemModal";
import CancelOrderModal from "../Modals/CancelOrderModal";
import QueryModal from "../Modals/QueryModal";
import ReturnModal from "../Modals/ReturnModal";

function OrderDetails(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [trackModal, setTrackModal] = useState(false);
  const [cancelOrder, setcancelOrder] = useState(false);
  const [queryForProduct, setQueryForProduct] = useState(false)
  const [returnOrder, setReturnOrder] = useState(false)
  const [detailsModal, setDetailsModal] = useState(false)
  const [queryDetails, setQueryDetails] = useState()
  const [orderDetails, setOrderDetails] = useState({});
  const [sku, setSku] = useState('')

  const handleTrackModal = (product) => {
    setTrackModal(!trackModal);
    setSku(product)
  };

  const handleCancelModal = (product) => {
    setcancelOrder(!cancelOrder);
    setSku(product)
  };

  const handleReturnModal = (product) => {
    setReturnOrder(!returnOrder)
    setSku(product)
  }

  const handleQueryModal = (product) => {
    setQueryForProduct(!queryForProduct)
    setSku(product)
  }

  const handleGoingBack = () => {
    navigate("/profile", { state: { activePage: 3 } });
    return
  }


  useEffect(() => {
    if (!props.orderId) {
      handleGoingBack()
    }
    getOrder();

  }, []);

  const getOrder = async () => {
    try {
      let response = await orderService.GetOrder(props.orderId);
      if (response?.status?.code === 200) {
        setOrderDetails(response?.data);
        props.setCategoryForGetOrders(response?.data?.featureId)
      }
    } catch (e) {
      console.log("Error in getOrder", e);
    }
  };

  return (
    <div className="orderDetails_wrap">
      <div className="row">
        <div className="col-md-8">
          <div className="order_details-history content-box">
            <div className="mb-3" onClick={() => handleGoingBack()}>
              <img className="button-style" src={images["back-arrow.svg"]} alt="img" />
            </div>
            <div className="order-tag">
              <h4>Order Details</h4>
              <span>{orderDetails?.orderStatus}</span>
            </div>
            <div className="order-date-id row">
              <div className=" col-sm-4">
                <div className="order-date-id-details">
                  <label>Order ID</label>
                  <span>{orderDetails?.orderId}</span>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="order-date-id-details">
                  <label>Order Date</label>
                  <span>{orderDetails?.createdOn}</span>
                </div>
              </div>
              {/* {
                orderDetails?.featureId !=='DEA015' || orderDetails?.featureId !=="VOU014" ?
                <div className="col-sm-4 ">
                <div className="order-date-id-details">
                  <label>Expected Delivery By</label>
                  <span></span>
                </div>
              </div>
              :
              null
              } */}

            </div>
            {/* <div className="shiping_address">
              <h4>Shipping Address</h4>
              <div className="address-details-ord">
                <div className="name-number">
                  <span>Naveed Kumar</span>
                  <p>+91 98987474773</p>
                </div>
                <div className="address-shipment">
                  <p>
                    3rd Floor, Palm Court,1 MG Road, Sector 14, Gurgaon ,
                    Haryana 122007
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="list-order content-box">
            <h4>Order Details</h4>
            <div className=" tag-list row">
              <div className="col-sm-7">Product</div>
              <div className="col-sm-2 text-center">Price</div>
              <div className="col-sm-3 text-center">Item Status</div>
            </div>
            {orderDetails?.orderDetails?.basket_item?.length > 0
              ? orderDetails?.orderDetails?.basket_item?.map((ele, index) => {
                return (
                  <div className="info-order-box " key={index}>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="img-size-quant">
                          {/* <img src={images["mobile.svg"]} alt="img" /> */}
                          <div className="image-container">
                            <img
                              src={commonServices.imageByDimensions(
                                ele?.stock_item_image[0],
                                "10by10"
                              )}
                              alt="star"
                            />
                          </div>
                          <div className="details-product">
                            <h4>{ele.brandName}</h4>
                            <p>{ele.stock_item_title}</p>
                            <div className="span-wrap">
                              {/* <span>Size:M</span>
                        <span>Color:Blue</span> */}
                              <span>Qty: {ele.quantity}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="price_box">
                          <span>₹ {ele.price}</span>
                        </div>
                      </div>
                      <div className="col-sm-3 ">
                        <div className="tag-view ">
                          <span>{ele.status}</span>
                        </div>
                      </div>
                    </div>
                    <div className="btn-items">
                      <div className="row">
                        {
                          ele.stock_item_type_id === 'DEA015' || ele.stock_item_type_id === 'VOU014' ?
                            <>
                              <div className="col-sm-6">
                                <button
                                  className="cancel-items"
                                  onClick={() => handleQueryModal(ele)}
                                >
                                  Raise A Query
                                </button>
                              </div>
                            </>

                            :
                            <>
                              {
                                ele.status.toLowerCase().trim() === 'delivered' ?
                                  <div className="col-sm-4">
                                    <button
                                      className="cancel-items"
                                      onClick={() => handleReturnModal(ele)}
                                    >
                                      Return Item
                                    </button>
                                  </div>
                                  :
                                  <div className="col-sm-4">
                                    <button onClick={() => handleTrackModal(ele)}>
                                      Track Item
                                    </button>
                                  </div>

                              }
                              {
                                ele.status.toLowerCase().trim() === 'delivered' || ele.status === 'Cancel_Requested'?
                                  null
                                  :
                                  <div className="col-sm-4">
                                    <button
                                      className="cancel-items"
                                      onClick={() => handleCancelModal(ele)}
                                    >
                                      Cancel Item
                                    </button>
                                  </div>
                              }
                              <div className="col-sm-4">
                                <button
                                  className="cancel-items"
                                  onClick={() => handleQueryModal(ele)}
                                >
                                  Raise A Query
                                </button>
                              </div>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
              : null}
          </div>
        </div>

        <div className="col-md-4">
          <div className="travelDetails_wrap">
            <div className="order-summaryTravel content-box">
              <h4>Order Summary</h4>
              <ul>
                <li>
                  Total Products
                  <span>{orderDetails?.orderDetails?.basket_item?.length}</span>
                </li>
                <li>
                  Total Amount
                  <span> ₹ {orderDetails?.orderDetails?.price_summary?.total_price}</span>
                </li>
                <li>
                  Discount
                  <span className="greenspan">N.A.</span>
                </li>
              </ul>
              <ul className="ul_li-box-travel cancelview">
                <li className="boldli">
                  Paid Amt
                  <label> ₹ {orderDetails?.orderDetails?.price_summary?.total_price}</label>
                </li>
                <li>
                  Redeemed
                  <span>
                    {orderDetails?.orderDetails?.price_summary?.required_points ? `${orderDetails?.orderDetails?.price_summary?.required_points} Pts.` : ''}
                  </span>
                </li>
                {/* <li>
                   Earned
                  <span className="greenspan ">N.A.</span>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="order-summary-cancel-btn content-box">
            <span>Your Order is confirmed !</span>
            <button onClick={handleCancelModal}>Cancel Order</button>
          </div> */}
        </div>
      </div>
      {trackModal && <TrackItemModal trackModal={trackModal} setTrackModal={setTrackModal} orderId={props.orderId} sku={sku} />}
      {cancelOrder && <CancelOrderModal cancelOrder={cancelOrder} setcancelOrder={setcancelOrder} orderId={props.orderId} sku={sku} />}
      {queryForProduct
        &&
        <QueryModal
          queryForProduct={queryForProduct}
          handleQueryModal={handleQueryModal}
          orderId={props.orderId}
          setDetailsModal={setDetailsModal}
          setQueryDetails={setQueryDetails}
        />}
      {returnOrder && <ReturnModal returnOrder={returnOrder} handleReturnModal={handleReturnModal} orderId={props.orderId} sku={sku} />}
      {detailsModal &&
        <Modal
          className="trackModal_wrap"
          show={detailsModal}
          centered
          size="md"
        >
          <div className="details-modal">
            <div className="exit" onClick={() => setDetailsModal(false)}>
              <img
                src={images["cross_icon.svg"]}
                alt="cross"
                className="cross"
              />
            </div>
            <div className="content-container">
              <p>Your Query has been raised with TRC.</p>
              <p>TRC team will get back to you.</p>
              <p>Query Number: <span>{queryDetails?.ticketId}</span></p>
              <p>Toll Free Number: <span>847-000-3279</span></p>
              <p>Email ID: <span>customercare@trc.in</span></p>
            </div>
          </div>
        </Modal>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryForGetorders: state.categoryForGetorders,
    duration: state.duration,
    orderId: state.orderId
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDuration: (duration) => {
      dispatch({ type: "DURATION", payload: duration });
    },
    setCategoryForGetOrders: (category) => {
      dispatch({ type: "CATEGORY_FOR_GETORDERS", payload: category });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
