import React, { useEffect, useState } from "react";
import { commonComponets } from "../Common/commonComponent";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { commonServices } from "../../Utility/common.services";
import { voucherService } from "../../Utility/voucher.service";
import AllVouchers from "./AllVouchers";
import { useNavigate, useLocation } from "react-router-dom";
import "./_voucher.scss";
import { homeService } from "../../Utility/home.service";
import { Utility } from "../../Utility/utility.service";

function Voucher(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  const [vouchers, setVouchers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [isShowListiongPage, setIsShowListiongPage] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [size, setSize] = useState(500);
  const [from, setFrom] = useState(0);
  const [pointFrom, setPointFrom] = useState(1);
  const [pointTo, setPointTo] = useState(500000);
  const [sortBy, setSortBy] = useState("");
  const [sortMode, setSortMode] = useState("");
  const [priceToFiltered, setPriceToFiltered] = useState([0, 0]);
  const [categoriesToFiltered, setCategoriesToFiltered] = useState([]);
  const [brandsToFiltered, setBandsToFiltered] = useState(props.selectedBrand ? [props.selectedBrand] : []);
  const [pageSectionData, setPageSectionData] = useState([]);
  const [isShowApply, setIsShowApply] = useState(false);
  const [categoryItemCount, setCategoryItemCount] = useState(8);
  const [categoriesItemsLength, setCategoriesItemsLength] = useState(0);
  const [categoriesSection, setCategoriesSection] = useState([]);

  useEffect(() => {
    props.setSelectedDepartment("");
    props.setSelectedBrand("");
    props.setSelectedCategory([]); 

    //getVouchers();
    getDepartments();
    getPageSections();

    window.addEventListener('popstate', function(event) {
      setIsShowListiongPage(false)
    });
    window.scrollTo(0, 0);
  }, []);

  const getPageSections = async () => {
    try {
      let res = await homeService.GetPageSections("voucher");
      if (res !== null) {
        setCategoriesSection(res.filter((ele) => ele.sectionCode === "Categories")[0]);
        setCategoriesItemsLength(res.filter((ele) => ele.sectionCode === "Categories")[0]?.items?.length || 0)
        setPageSectionData(res);
      } else {
        setPageSectionData([]);
      }
    } catch (e) {
      console.log("Error in getPageSections", e);
    }
  };

  useEffect(() => {
    getVouchers(
      props.selectedDepartment,
      brandsToFiltered?.join(),
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      props.searchValue
    );
  }, [
    props.selectedDepartment,
    brandsToFiltered,
    // pointTo,
    // pointFrom,
    categoriesToFiltered,
    props.sortBy,
    props.sortMode,
    // props.selectedCategory,
    props.searchValue,
    // props.selectedBrand
  ]);

  useEffect(() => {
    getBrands();
    getCategories();
  }, [props.selectedDepartment]);

  useEffect(() => {
    if (props.isShowListingPage) {
      setIsShowListiongPage(true);
      props.setShowListingPage(false);
    }
  }, [props.isShowListingPage]);

  const onChangeBrand = (id) => {
    setBandsToFiltered(id);
    setCategoriesToFiltered(id);
  };                                                                                         

  const getVouchers = async (
    departments,
    brands,
    pointFrom,
    pointTo,
    categories,
    sBy,
    sMode, 
    searchText
  ) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching vouchers");
    try {
      let res = await voucherService.GetVouchers(
        size,
        from,
        departments,
        brands,
        pointFrom,
        pointTo,
        categories,
        sBy,
        sMode,
        searchText
      );
      if (res?.status?.code === 200) {
        setVouchers(res?.data?.result);
      } else {
        setVouchers([]);
      }
      setLoader(false);
      setLoadingPrefixText("");
      setLoadingSuffixText("");
    } catch (e) {
      setLoader(false);
      setVouchers([]);
      console.log("Error in getVouchers", e);
    }
  };

  const getCategories = async () => {
    try {
      let res = await voucherService.GetCategories(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setCategories(res.data);
      } else {
        setCategories([]);
      }
    } catch (e) {
      setCategories([]);
      console.log("Error in getCategories", e);
    }
  };

  const getBrands = async () => {
    try {
      let res = await voucherService.GetBrands(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setBrands(res.data);
      } else {
        setBrands([]);
      }
    } catch (e) {
      setBrands([]);
      console.log("Error in getBrands", e);
    }
  };

  const getDepartments = async () => {
    try {
      let res = await voucherService.GetDepartments();
      if (res?.status?.code === 200) {
        setDepartments(res.data);
      } else {
        setDepartments([]);
      }
    } catch (e) {
      console.log("Error in getDepartments", e);
    }
  };

  const openListingPage = (key, departmentId, id) => {
    if (key === "Evouchers") {
      //navigate("/voucherdetails", { state: id });
      props.setSelectedBrand(id); ///id is category id
      setBandsToFiltered([id]);
    } else if (key === "Categories") {
      props.setSelectedDepartment(id); ///departmentId is category id
    } else if (key === "Brands") {
      props.setSelectedBrand(id);
      setBandsToFiltered([id]);
    } else if (key === "Dining") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand(id);
      setBandsToFiltered([id]);
    } else if (key === "E-Com&Online") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand(id);
      setBandsToFiltered([id]);
    } else if (key === "Healthy&Beauty") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand(id);
      setBandsToFiltered([id]);
    }
    setIsShowListiongPage(true);
  };

  const openVoucherDetails = (ele) => {
    navigate("/voucherdetails", { state: ele.sku });
  };

  const filterSectionHandler = async (event, key, value) => {
    try {
      let catagoriesArr = [];
      let brandsArr = [];
      props.setSearchValue("");

      if (key === "category") {
        let categories = [...categoriesToFiltered];

        if (categories.includes(value)) {
          const updatedCategories = categories.filter(
            (categoryId) => categoryId !== value
          );
          setCategoriesToFiltered(updatedCategories);
        } else {
          catagoriesArr.push(value);
          setCategoriesToFiltered([...categoriesToFiltered, ...catagoriesArr]);
        }
      } else if (key === "brand") {
        let brands = [...brandsToFiltered];

        if (brands.includes(value)) {
          const updatedBrands = brands.filter((brandId) => brandId !== value);
          await setBandsToFiltered(updatedBrands);
        } else {
          brandsArr.push(value);
          await setBandsToFiltered([...brandsToFiltered, ...brandsArr]);
        }
      } else if (key === "price") {
        setPointFrom(value[0]);
        setPointTo(value[1]);
        setIsShowApply(true);
        props.setIsFilterChecked(true);

        // getVouchers(
        //   props.selectedDepartment,
        //   brandsToFiltered.join(),
        //   value[0]?.toString(),
        //   value[1]?.toString(),
        //   categoriesToFiltered,
        //   props.sortBy,
        //   props.sortMode,
        //   ""
        // );
      }
    } catch (error) {
      console.log("error in filterSectionHandler is", error);
    }
  };

  const applyFilter = async () => {
    getVouchers(
      props.selectedDepartment,
      brandsToFiltered?.join(),
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      ""
    );
    setIsShowApply(false);
  };

  const resetFilter = () => {
    setPointFrom(1);
    setPointTo(500000);
    props.setIsFilterChecked(false)
    getVouchers(      
      props.selectedDepartment,
      brandsToFiltered.join(),
      1,
      500000,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      ""
    )
  }

  const redirectTo = (url) =>{
    navigate(url);
  }

  return (
    <>
      <div className="swp-banner">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation
          modules={[Navigation, Scrollbar, A11y]}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {/* <SwiperSlide>
            <img alt="" src={images["dash_banner.png"]} />
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" src={images["dash_banner.png"]} />
          </SwiperSlide> */}
          {pageSectionData?.length > 0 &&
            pageSectionData
              .filter((ele) => ele.sectionCode === "HeroBanner")
              .map((ele) =>
                ele.items?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} onClick={() =>redirectTo(item?.redirectUrl)}>
                      <img alt="" src={item.imageUrl} />
                    </SwiperSlide>
                  );
                })
              )}
        </Swiper>
      </div>
      <div className="container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              {loadingSuffixText ? loadingSuffixText : null}
            </p>
          </div>
        )}
        {!isShowListiongPage ? (
          <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" className="disabled">
                    HOME
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/voucher">VOUCHER</a>
                </li>
              </ol>
            </nav>

            <div className="top-view">
              <span>E-Vouchers</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "E-Vouchers")
                ?.items?.length && (
                <button onClick={() => openListingPage()}>View All</button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "E-Vouchers")
              ?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                    className="evoucher-slider"
                  >
                    

                    {pageSectionData?.length > 0 &&
                  pageSectionData
                    ?.filter((ele) => ele.sectionCode === "E-Vouchers")
                    .map((ele) => Utility.groupByGroupKey(ele.items)?.map((item, index) => {
                      return (
                        <SwiperSlide key={index} className="image-cluster">
                          {/* <div
                              className="productcard_wrap"
                              onClick={() =>
                                openListingPage("Evouchers", "", item.uId)
                              }
                            >
                              <div className="img-container">
                                <img alt="" src={item.imageUrl} />
                              </div>
                              <div className="product_details">
                                <h4>{item?.itemDisplayName}</h4>
                              </div>
                            </div> */}
                          {/* <div className="image-cluster"> */}
                          {item.map((imgItem, id) => {
                            return (
                              <div className="image" key={id}>
                                <img alt='' src={imgItem.imageUrl} onClick={() => openListingPage("Evouchers", "", imgItem?.uId)} />
                              </div>
                            )
                          })}
                          {/* </div> */}
                        </SwiperSlide>
                      );
                    })
                    )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className={`top-view ${vouchers.length ? "mt-5 pt-1" : ""}`}>
              <span>Exclusive Brands</span>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "Exclusive Brands"
              )?.items?.length && (
                <button onClick={() => openListingPage("Brands", "", "")}>
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "Exclusive Brands"
            )?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Exclusive Brands")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage("Brands", "", item.uId)
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className={`top-view ${vouchers.length ? "mt-5 pt-1" : ""}`}>
              <span>Dining</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Dining")
                ?.items?.length && (
                <button
                  onClick={() =>
                    openListingPage(
                      "Dining",
                      pageSectionData?.find(
                        (ele) => ele.sectionCode === "Dining"
                      )?.groupId,
                      ""
                    )
                  }
                >
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Dining")?.items
              ?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Dining")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "Dining",
                                    ele.groupId,
                                    item.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className={`top-view ${vouchers.length ? "mt-5 pt-1" : ""}`}>
              <span>E-Com & Online</span>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "E-Com&Online"
              )?.items?.length && (
                <button
                  onClick={() =>
                    openListingPage(
                      "E-Com&Online",
                      pageSectionData?.find(
                        (ele) => ele.sectionCode === "E-Com&Online"
                      )?.groupId,
                      ""
                    )
                  }
                >
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "E-Com&Online")
              ?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "E-Com&Online")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "E-Com&Online",
                                    ele.groupId,
                                    item.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className={`top-view ${vouchers.length ? "mt-5 pt-1" : ""}`}>
              <span>Health & Beauty</span>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "Healthy&Beauty"
              )?.items?.length && (
                <button
                  onClick={() =>
                    openListingPage(
                      "Healthy&Beauty",
                      pageSectionData?.find(
                        (ele) => ele.sectionCode === "Healthy&Beauty"
                      )?.groupId,
                      ""
                    )
                  }
                >
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "Healthy&Beauty"
            )?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Healthy&Beauty")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "Healthy&Beauty",
                                    ele.groupId,
                                    item.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className={`top-view ${vouchers.length ? "mt-5 pt-1" : ""}`}>
              <span>Categories</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
                ?.items?.length && (
                <button onClick={() => openListingPage("Categories", "", "")}>
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
              ?.items?.length > 0 ? (
              <>
                <div className="row">
                  {pageSectionData?.length > 0 &&
                    pageSectionData
                      .filter((ele) => ele.sectionCode === "Categories")
                      .map((ele) =>
                        ele.items?.slice(0, categoryItemCount)?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="col-md-3 mb-3"
                              onClick={() =>
                                openListingPage("Categories", "", item?.uId)
                              }
                            >
                              <div className="categories">
                                <img alt="" src={item.imageUrl} />
                                <div className="categories-detail">
                                  <h4>{item?.itemDisplayName}</h4>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}

            <div className="d-flex justify-content-center align-items-center mb-5 pt-3 pb-3">
            {categoryItemCount !== categoriesItemsLength && categoriesItemsLength > 8 ? (
            <div className="button-container" onClick={() => setCategoryItemCount(categoriesItemsLength)}>
            <span>Show More</span>
            <img src={images["down_arrow.svg"]} alt="name" />
          </div>
          ) : (
            categoriesItemsLength > 0 &&
            categoriesItemsLength > 8 && (
              <div className="button-container" onClick={() => setCategoryItemCount(8)}>
              <span>Show Less</span>
              <img src={images["down_arrow.svg"]} alt="name" />
            </div>
            )
          )}
            </div>
          </>
        ) : (
          <AllVouchers
            Vouchers={vouchers}
            Categories={categories}
            Brands={brands}
            Departments={departments}
            priceToFiltered={priceToFiltered}
            pointFrom={pointFrom}
            pointTo={pointTo}
            filter={(event, key, value) =>
              filterSectionHandler(event, key, value)
            }
            categoriesToFiltered={categoriesToFiltered}
            brandsToFiltered={brandsToFiltered}
            onChangeBrand={onChangeBrand}
            applyFilter={applyFilter}
            isShowApply={isShowApply}
            resetFilter={resetFilter}
          />
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    selectedBrand: state.selectedBrand,
    selectedDepartment: state.selectedDepartment,
    selectedCategory: state.selectedCategory,
    sortBy: state.sortBy,
    sortMode: state.sortMode,
    isShowListingPage: state.isShowListingPage,
    searchValue: state.searchValue
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setSelectedCategory: (selectedCat) => {
      dispatch({ type: "SELECTED_CATEGORY", payload: selectedCat });
    },
    setSelectedBrand: (selectedBrand) => {
      dispatch({ type: "SELECTED_BRAND", payload: selectedBrand });
    },
    setSelectedDepartment: (selectedDept) => {
      dispatch({ type: "SELECTED_DEPARTMENT", payload: selectedDept });
    },
    setShowListingPage: (isShow) => {
      dispatch({ type: "IS_LISTING_PAGE", payload: isShow });
    },
    setSearchValue: (val) => dispatch({ type: 'SEARCH_VALUE', payload: val }),
    setIsFilterChecked: (val) => dispatch({ type: 'IS_FILTER_CHECK', payload: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
