import React, { useState } from 'react';
import { Pagination } from 'semantic-ui-react';
import "./pagination.scss";

 function PaginatedPages(props) {
    const {paginateNumber,openedPage,postsPerPage,totalPosts}=props;

    const [activePage,setActivePage]=useState(1)

    const paginate = (pageNumber) => {
        setActivePage(pageNumber)
        paginateNumber(pageNumber)
    }
    
        const pageNumbers = [];
        for (let index = 1; index <= Math.ceil(totalPosts /postsPerPage); index++) {
            pageNumbers.push(index);
        }
        return (
            <>
                {
                    pageNumbers.length > 1 ?
                        <Pagination
                            className="paginate"
                            boundaryRange={0}
                            //commenting this - sweti
                            // defaultActivePage={1}
                            ellipsisItem={null}
                            prevItem={pageNumbers.length > 1 && openedPage !== 1 ? "Prev" : null}
                            nextItem={pageNumbers.length > 1 && openedPage !== pageNumbers.length ? "Next" : null}
                            pointing
                            //secondary
                            firstItem={pageNumbers.length >= 10 && openedPage !== 1 ? "First Page" : null}
                            lastItem={pageNumbers.length >= 10 && openedPage !== pageNumbers.length ? "Last Page" : null}
                            totalPages={pageNumbers.length}
                            siblingRange={1}
                            onPageChange={(event, data) => paginate(data.activePage)}
                            activePage={openedPage}
                        />
                        :
                        null
                }
            </>
        );
}

export default PaginatedPages;