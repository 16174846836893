import "./_dashboard.scss";
import React from "react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { commonServices } from "../../Utility/common.services";
import { commonComponets } from "../Common/commonComponent";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { homeService } from "../../Utility/home.service";
import { useNavigate } from "react-router-dom";
import { micrositeService } from "../../Utility/microsite.service";
import AllProducts from "../Shop/AllProducts";
import { shopService } from "../../Utility/shop.service";
import QueryModal from "../UserDetails/Modals/QueryModal";
import { Utility } from "../../Utility/utility.service";
import ReactPlayer from "react-player";
import TRCVideo from '../../Assets/Icons/TRCVideo1.mp4'; // Import the video file


function Dashboard(props) {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [slidePerview, setSlidePerview] = useState(
    window.innerWidth < 540
      ? 1
      : window.innerWidth < 720
      ? 2
      : window.innerWidth < 960
      ? 2
      : 3
  );
  const [pageSectionData, setPageSectionData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [trendingNowData, setTrendingNowData] = useState({});

  useEffect(() => {
    // throw new Error('Oh no');
    getPageSections();
    window.scrollTo(0, 0);
    // let screenSize = window.innerWidth;
    // setSlidePerview(
    //   screenSize < 540 ? 1 : screenSize < 720 ? 2 : screenSize < 960 ? 2 : 3
    // );
  }, []);

  const getPageSections = async () => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching");
    setLoadingSuffixText("Home page for you...");
    try {
      let res = await homeService.GetPageSections("home");
      if (res !== null) {
        setPageSectionData(res);
        setTrendingNowData(
          res.filter((ele) => ele.sectionCode === "TrendingNow")?.[0]
        );
      } else {
        setPageSectionData([]);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setPageSectionData([]);
      console.log("Error in getPageSections", e);
    }
  };

  const openListingPage = (key, departmentId, id, itemType) => {
    if (key === "ShopByCategory") {
      if (id !== "" && id) {
        props.setShowListingPage(true);
        props.setSelectedDepartment(id); 
        navigate("/shop");
      } else {
        navigate("/shop");
      }
    } else if (key === "TopDeals") {
      if (id !== "" && id) {
        props.setShowListingPage(true);
        props.setSelectedCategory([id]); 
        navigate("/deals");
      } else {
        navigate("/deals");
      }
    } else if (key === "Evouchers") {
      if (id !== "" && id) {
        props.setShowListingPage(true);
        // props.setSelectedCategory([id]); 
         props.setSelectedBrand([id]);
        navigate("/voucher");
      } else {
        navigate("/voucher");
      }
    } else if (key === "trending") {
      if (itemType === "Brand") {
        if (id !== "" && id) {
          props.setShowListingPage(true);
          props.setSelectedBrand([id]);
          props.setSelectedCategory([]);
          navigate("/deals");
        }
      } else if (itemType === "Category") {
        if (id !== "" && id) {
          props.setShowListingPage(true);
          props.setSelectedBrand([]);
          props.setSelectedCategory([id]); 
          navigate("/shop");
        }
      } else {
        navigate("/shop");
      }
    } else if (key === "BestSeller") {
      if (id && id !== "") {
        navigate("/productdetails", { state: id });
      }
      else{
        navigate("/shop")
      }
    } else if (key === "FeaturedProducts") {
      navigate("/productdetails", { state: id });
    }
  };

  const redirectTo = (url) =>{
    navigate(url);
  }

  return (
    <div className="main-div">
      <div className="dashboard_div">
        <div className="swp-banner">
          <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {pageSectionData?.length > 0 &&
              pageSectionData?.filter((ele) => ele.sectionCode === "HeroBanner")?.map((ele) =>
                  ele?.items?.map((item, index) => {
                    return (
                      <SwiperSlide key={index} onClick={() =>redirectTo(item?.redirectUrl)}>
                        <img alt="" src={item.imageUrl} />
                      </SwiperSlide>
                    );
                  })
                )}
          </Swiper>
        </div>
        <div
          className={
            props.screenSize > 1020 || props.screenSize < 535
              ? "dasboard_sliders_div container"
              : "dasboard_sliders_div px-5"
          }
        >
          {loader && (
            <div className="loader-overlay flight_loader">
              <div className="boot-strap-loader">
                <div className="spinner-border text-warning" role="status">
                  {/* <span className="sr-only">Loading...</span> */}
                </div>
              </div>
              <p>
                {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
                {loadingSuffixText ? loadingSuffixText : null}
              </p>
            </div>
          )}
          <div className="slider_with_label">
            <div className="slider_header">
              <h4>Shop by Categories</h4>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "ShopByCategory"
              )?.items?.length && (
                <label
                  onClick={() => openListingPage("ShopByCategory", "", "")}
                >
                  View All
                </label>
              )}
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "ShopByCategory"
            )?.items?.length > 0 ? (
              <Swiper
                spaceBetween={22}
                slidesPerView={slidePerview}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
                //scrollbar={{ draggable: true }}
              >
                {pageSectionData?.length > 0 &&
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "ShopByCategory")
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <SwiperSlide
                            key={index}
                            onClick={() =>
                              openListingPage("ShopByCategory", "", item?.uId)
                            }
                          >
                            <div className="dashboard-container">
                              <div className="image-container">
                                <img alt="image" src={item.imageUrl} />
                              </div>
                              <div className="overlay-text">
                                <h1>{item.overlayText1}</h1>
                                <h4>{item.overlayText2}</h4>
                              </div>
                              {/* <div className="overlay-text1">
                                {item.overlayText1}
                              </div>
                              <div className="overlay-text2">
                                {item.overlayText2}
                              </div> */}
                            </div>
                          </SwiperSlide>
                        );
                      })
                    )}
              </Swiper>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}
          </div>

          <div className="slider_with_label">
            <div className="slider_header">
              <h4>Top Deals</h4>
              {pageSectionData?.find((ele) => ele.sectionCode === "TopDeals")
                ?.items?.length && (
                <label onClick={() => openListingPage("TopDeals", "", "")}>
                  View All
                </label>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "TopDeals")
              ?.items?.length > 0 ? (
              <Swiper
                spaceBetween={22}
                slidesPerView={slidePerview}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
                //scrollbar={{ draggable: true }}
              >
                {pageSectionData?.length > 0 &&
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "TopDeals")
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className="dashboard-container"
                              onClick={() =>
                                openListingPage("TopDeals", "", item.uId)
                              }
                            >
                              <div className="image-container">
                                <img alt="image" src={item.imageUrl} />
                              </div>
                              <div className="overlay-text">
                                <h1>{item.overlayText1}</h1>
                                <h4>{item.overlayText2}</h4>
                              </div>
                              {/* <div className="overlay-text1">
                                {item.overlayText1}
                              </div>
                              <div className="overlay-text2">
                                {item.overlayText2}
                              </div> */}
                            </div>
                          </SwiperSlide>
                        );
                      })
                    )}
              </Swiper>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Deals Available</p>
              </div>
            )}
          </div>

          <div className="slider_with_label">
            <div className="slider_header">
              <h4>Trending Now</h4>
              {pageSectionData?.find((ele) => ele.sectionCode === "TrendingNow")
                ?.items?.length && (
                <label onClick={() => openListingPage("trending", "", "")}>
                  View All
                </label>
              )}
            </div>
            {trendingNowData && Object.keys(trendingNowData)?.length > 0 ? (
              <div className="row trending-now-container">
                <div className="col-6 vertical-container">
                  <div
                    className="trending-now"
                    onClick={() =>
                      openListingPage(
                        "trending",
                        "",
                        trendingNowData?.items?.[3]?.uId,
                        trendingNowData?.items?.[3]?.itemType
                      )
                    }
                  >
                    <div className="img-container">
                      <img alt="" src={trendingNowData?.items?.[3]?.imageUrl} />
                    </div>
                    <div className="product_details">
                      <h4>{trendingNowData?.items?.[3]?.itemDisplayName}</h4>
                    </div>
                  </div>

                  <div
                    className="trending-now"
                    onClick={() =>
                      openListingPage(
                        "trending",
                        "",
                        trendingNowData?.items?.[1]?.uId,
                        trendingNowData?.items?.[1]?.itemType
                      )
                    }
                  >
                    <div className="img-container">
                      <img alt="" src={trendingNowData?.items?.[1]?.imageUrl} />
                    </div>
                    <div className="product_details">
                      <h4>{trendingNowData?.items?.[1]?.itemDisplayName}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-6 vertical-container">
                  <div
                    className="trending-now"
                    onClick={() =>
                      openListingPage(
                        "trending",
                        "",
                        trendingNowData?.items?.[2]?.uId,
                        trendingNowData?.items?.[2]?.itemType
                      )
                    }
                  >
                    <div className="img-container">
                      <img alt="" src={trendingNowData?.items?.[2].imageUrl} />
                    </div>
                    <div className="product_details">
                      <h4>{trendingNowData?.items?.[2]?.itemDisplayName}</h4>
                    </div>
                  </div>

                  <div
                    className="trending-now"
                    onClick={() =>
                      openListingPage(
                        "trending",
                        "",
                        trendingNowData?.items?.[0]?.uId,
                        trendingNowData?.items?.[0]?.itemType
                      )
                    }
                  >
                    <div className="img-container">
                      <img alt="" src={trendingNowData?.items?.[0]?.imageUrl} />
                    </div>
                    <div className="product_details">
                      <h4>{trendingNowData?.items?.[0]?.itemDisplayName}</h4>
                    </div>
                  </div>
                </div>
              </div>
            )
            : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Trending Details Available</p>
              </div>
            )}
          </div>

          <div className="featured_product_div">
            <div className="slider_header">
              <h4>Featured Products</h4>
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "FeaturedProducts"
            )?.items?.length > 0 ? (
              <>
                {pageSectionData?.length > 0 &&
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "FeaturedProducts")
                    .slice(0, 1)
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <div
                            className="featured-image-container"
                            key={index}
                            onClick={() =>
                              openListingPage("FeaturedProducts", "", item?.uId)
                            }
                          >
                            <img alt="featured-image" src={item.imageUrl} />
                          </div>
                        );
                      })
                    )}
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}
          </div>

          <div className="trc_description_div">
            <div className="slider_header">
              <h4>How TRC Works?</h4>
            </div>
            <div className="image-container">
              <ReactPlayer url={TRCVideo} width="100%" height={'100%'} controls />
            </div>
          </div>

          <div className="slider_with_label">
            <div className="slider_header">
              <h4>BestSeller</h4>
              {pageSectionData?.find((ele) => ele.sectionCode === "BestSeller")
                ?.items?.length && (
                <label onClick={() => openListingPage("BestSeller", "", "")}>
                  View All
                </label>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "BestSeller")
              ?.items?.length > 0 ? (
              <Swiper
                spaceBetween={22}
                slidesPerView={slidePerview}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
              >
                {pageSectionData?.length > 0 &&
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "BestSeller")
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <SwiperSlide
                            key={index}
                            onClick={() =>
                              openListingPage("BestSeller", "", item?.uId)
                            }
                          >
                            <div className="bestseller-container">
                              <div className="img-container">
                                <img
                                  alt=""
                                  src={commonServices.imageByDimensions(
                                    item.skuDetails?.imageUrl,
                                    "20by20"
                                  )}
                                />
                              </div>
                              <div className="product_details">
                                <h6>{item?.skuDetails?.brandName}</h6>
                                <h5>{item?.skuDetails?.title}</h5>
                                <span className="with_discount">
                                  {item?.skuDetails?.mrp !== item?.skuDetails?.price ?
                                  <React.Fragment>
                                     <span className="without_discount">
                                    ₹{item?.skuDetails?.mrp}&nbsp;
                                  </span>
                                  <span className="connecter">
                                    &nbsp;{"-"}&nbsp;
                                  </span>
                                  ₹ {item?.skuDetails?.price}&nbsp;
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <span className="without_discount only-price">
                                    ₹{item?.skuDetails?.mrp}&nbsp;
                                    </span>
                                  </React.Fragment>
                                  }
                                  <span> / {item?.skuDetails?.points}Pts.</span>
                                </span>
                                {/* -<span className="without_discount"> ₹ 24,99</span> */}
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })
                    )}
              </Swiper>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}
          </div>

          <div className="slider_with_label">
            <div className="slider_header">
              <h4>E-Vouchers</h4>
              {pageSectionData?.find((ele) => ele.sectionCode === "Evouchers")
                ?.items?.length && (
                <label onClick={() => openListingPage("Evouchers", "", "")}>
                  View All
                </label>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Evouchers")
              ?.items?.length > 0 ? (
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
                className="evoucher-slider"
              >
                {pageSectionData?.length > 0 &&
                  pageSectionData
                    ?.filter((ele) => ele.sectionCode === "Evouchers")
                    .map((ele) =>
                      Utility.groupByGroupKey(ele.items)?.map((item, index) => {
                        return (
                          <SwiperSlide key={index} className="image-cluster">
                            {/* <div
                              className="productcard_wrap"
                              onClick={() =>
                                openListingPage("Evouchers", "", item.uId)
                              }
                            >
                              <div className="img-container">
                                <img alt="" src={item.imageUrl} />
                              </div>
                              <div className="product_details">
                                <h4>{item?.itemDisplayName}</h4>
                              </div>
                            </div> */}
                            {/* <div className="image-cluster"> */}
                            {item?.map((imgItem, id) => {
                              return (
                                <div className="image image-1" key={id}>
                                  <img
                                    alt=""
                                    src={imgItem.imageUrl}
                                    onClick={() =>
                                      openListingPage(
                                        "Evouchers",
                                        "",
                                        imgItem?.uId
                                      )
                                    }
                                  />
                                </div>
                              );
                            })}
                            {/* </div> */}
                          </SwiperSlide>
                        );
                      })
                    )}
              </Swiper>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Voucher Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowListingPage: state.isShowListingPage,
    selectedBrand: state.selectedBrand,
    selectedDepartment: state.selectedDepartment,
    selectedCategory: state.selectedCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setSelectedCategory: (selectedCat) => {
      dispatch({ type: "SELECTED_CATEGORY", payload: selectedCat });
    },
    setSelectedBrand: (selectedBrand) => {
      dispatch({ type: "SELECTED_BRAND", payload: selectedBrand });
    },
    setSelectedDepartment: (selectedDept) => {
      dispatch({ type: "SELECTED_DEPARTMENT", payload: selectedDept });
    },
    setShowListingPage: (isShow) => {
      dispatch({ type: "IS_LISTING_PAGE", payload: isShow });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
