import { commonServices } from './common.services';
import { config } from './config';

export const dealService = {
    GetDeals,
    GetBrands,
    GetDepartments,
    GetFeatureSections,
    GetSimilarDeals,
    GetDealDetails,
    GetCategories
}

async function GetDeals(size, from, departmentId, brandId, pointFrom, pointTo, sortBy, sortMode, categories) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            //'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({  
        "size": size,
        "from": from,
        "departmentId": departmentId ? departmentId : "",
        "brandId": brandId,
        "pointFrom": parseInt(pointFrom),
        "pointTo": parseInt(pointTo),
        "sortBy": sortBy,
        "sortMode": sortMode,
        "categoriesId": categories 
        })
    }

    return await fetch(config.apiUrl + 'Deals/GetDeals', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetBrands(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({'departmentId': departmentId ? departmentId : ""})

    }

    return await fetch(config.apiUrl + 'Deals/GetBrands', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetDepartments() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        }
    }

    return await fetch(config.apiUrl + 'Deals/GetDepartments', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetFeatureSections() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        }
    }

    return await fetch(config.apiUrl + 'Deals/GetFeatureSections', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetSimilarDeals(departmentId, skuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"departmentId": departmentId, 'skuId': skuId})

    }

    return await fetch(config.apiUrl + 'Deals/GetSimilarDeals', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetDealDetails(skuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"skuId": skuId})

    }

    return await fetch(config.apiUrl + 'Deals/GetDealDetails', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetCategories(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({'departmentId': departmentId ? departmentId : ""})
    }

    return await fetch(config.apiUrl + 'Deals/GetCategories', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}