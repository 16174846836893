import { commonServices } from './common.services';
import { config } from './config';

export const shopService = {
    GetRecommendedProducts,
    GetProducts,
    GetBrands,
    GetDepartments,
    GetFeatureSections,
    GetProductDetails,
    GetCategories,
    AddToWishList,
    GetWishLists,
    RemoveFromWishList
}

async function GetProducts(size, from, departmentId, brandId, pointFrom, pointTo, sortBy, sortMode, categories, searchText) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            //'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({  
            "size": size,
            "from": from,
            "departmentId": [
              departmentId
            ],
            "brandId": brandId,
            "pointFrom": parseInt(pointFrom),
            "pointTo": parseInt(pointTo),
            "sortBy": sortBy,
            "sortMode": sortMode,
            "categoriesId": categories,
            "searchText": searchText
        })
    }

    return await fetch(config.apiUrl + 'Product/GetProducts', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetBrands(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({'departmentId': departmentId})

    }

    return await fetch(config.apiUrl + 'Product/GetBrands', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetDepartments() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        }
    }

    return await fetch(config.apiUrl + 'Product/GetDepartments', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetFeatureSections() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        }
    }

    return await fetch(config.apiUrl + 'Product/GetFeatureSections', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetProductDetails(skuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"skuId": skuId})

    }

    return await fetch(config.apiUrl + 'Product/GetProductDetails', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetCategories(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({'departmentId': departmentId ? departmentId : ""})
    }

    return await fetch(config.apiUrl + 'Product/GetCategories', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetRecommendedProducts() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        }
    }

    return await fetch(config.apiUrl + 'Product/GetRecommendedProducts', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function AddToWishList(sku) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({"sku": sku})
    }

    return await fetch(config.apiUrl + 'Product/AddToWishList', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetWishLists() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        }
    }

    return await fetch(config.apiUrl + 'Product/GetWishLists', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function RemoveFromWishList(sku) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"sku": sku})
    }

    return await fetch(config.apiUrl + 'Product/RemoveFromWishList', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}