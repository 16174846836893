import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import './headerSearch.scss';
import React, { useState, useCallback, useRef, useEffect } from "react";
import { commonServices } from "../../Utility/common.services";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function HeaderSearch(props) {
    const navigate = useNavigate();
    const modalRef = useRef(null); 

    const images = commonServices.importImage(
        require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
    );
    const [searchValue, setSearchValue] = useState('');
    const [faetureId, setFeatureId] = useState('Product');

    const getFeaturesID = (event) => {
        setFeatureId(event.target.value);
        props.setFeatureValue(event.target.value);
    };

    const searchItems = async(e,enableFn=false) => {
        console.log(e?.keyCode)
        if(e?.keyCode === 13){
            searchFn()
        }
        else if (enableFn){
            searchFn()
        }
    };

    const searchFn = () =>{
        if(searchValue?.length > 0){
            if (faetureId === "Voucher") {
                props.setSearchValue(searchValue);
                props.setShowListingPage(true);
                props.setHeaderModalShow(false);
                navigate("/voucher");
            }
            else if (faetureId === "Product"){
                props.setSearchValue(searchValue);
                props.setShowListingPage(true);
                props.setHeaderModalShow(false);
                navigate("/shop");
            }
        }
    }

    const debouncedSearch = useCallback(
        debounce((searchQuery) => {
            searchItems(searchQuery);
        }, 3000),
        [faetureId] 
    );

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, wait);
        };
    }

    const searchProductHandler = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        // debouncedSearch(value);
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            if (props.headerModalShow) {
                props.setHeaderModalShow(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <Modal
                show={props.headerModalShow}
                onHide={() => props.setHeaderModalShow(!props.headerModalShow)}
                aria-labelledby="example-custom-modal-styling-title"
                className="header_search_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="search-list-modal-title">
                        <select
                            className="serach-list-product form-select"
                            name="featuresName"
                            id="featuresID"
                            onChange={getFeaturesID}
                        >
                            <option value="Product">Product</option>
                            <option value="Voucher">Voucher</option>
                        </select>
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className={"input-area"}
                                    id="search"
                                    placeholder="Search by Name/Product"
                                    onChange={searchProductHandler}
                                    onKeyUp={(e)=>searchItems(e)} 
                                />
                                <img src={images["search_icon.svg"]} alt="search" onClick={() => searchItems('',true)}/>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
            <ToastContainer />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ModuleHeaderDetails: state.showModuleDetailsInHeader,
        searchValue: state.searchValue,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchValue: (val) => dispatch({ type: 'SEARCH_VALUE', payload: val }),
        setFeatureValue: (val) => dispatch({ type: 'FEATURE_VALUE', payload: val }),
        setPreviousHeaderName: (name) => dispatch({ type: 'PREVIOUS_NAME', payload: name }),
        setShowListingPage: (isShow) => {dispatch({ type: "IS_LISTING_PAGE", payload: isShow });
    },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
