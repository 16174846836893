import "../Dashboard/_dashboard.scss";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../../Utility/common.services";
import termsAndConditionData from './footerTermsNCondition.json'
import Accordion from 'react-bootstrap/Accordion';

function TermsCondition(props) {
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);  
  }, []);

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="main-div">
        <div className="swp-banner">
          <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <img alt="" src={images["FAQs.png"]} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="cancellationReturn_container container">
            <h4>Terms and Conditions</h4>
            <Accordion>
              {termsAndConditionData.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <ul className="heading-bullet-style">
                      <li>{item.heading}</li>
                    </ul>
                    </Accordion.Header>
                  <Accordion.Body>
                  <ul key={index.toString()}>
                    {item.subDescription && item.subDescription.map((points,key)=>{
                      return(
                       <li key={key}>{points}</li>
                      )
                    })}
                     </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
        </div>
    </div>
  );
}


export default TermsCondition;
