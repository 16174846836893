import React from "react";
import './_travel.scss';

function Holidays(pros) {
    return(
        <div className="holidays_div">
            HOLIDAYS
        </div>
    )
}

export default Holidays;