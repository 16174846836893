import { commonServices } from './common.services';
import { config } from './config';

export const cartService = {
    GetCart,
    SaveCartItem,
    UpdateCart,
    DeleteCart,
}

async function GetCart() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'Cart/GetCart', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function SaveCartItem(stockItemTypeId, stockItemId, quantity) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({ 
            "stock_item_type_id": stockItemTypeId,
            "stock_item_id": stockItemId,
            "quantity": quantity,
            "personalised_message": "",
            "isGiftWrapping": true,
            "productAttributes": ""
         })
    }

    return await fetch(config.apiUrl + 'Cart/SaveCartItem', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function UpdateCart(stockItemTypeId, stockItemId, quantity) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({ 
            "stock_item_type_id": stockItemTypeId,
            "stock_item_id": stockItemId,
            "quantity": quantity
        })
    }

    return await fetch(config.apiUrl + 'Cart/UpdateCart', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function DeleteCart(stockItemId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({ 
            'stock_item_id': stockItemId
        })
    }

    return await fetch(config.apiUrl + 'Cart/DeleteCart', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
