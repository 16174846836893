import { commonServices } from './common.services';
import { config } from './config';

export const orderService = {
    GetOrder,
    GetOrdersList,
    CancelAWBOrder,
    ReturnAWBOrder,
    TrackAWB
}

async function GetOrder(orderId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "order_id": orderId
         })
    }

    return await fetch(config.apiUrl + 'Order/GetOrder', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetOrdersList(featureIds, userId, status, searchText, startDate, endDate, pageNumber, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
             "listOfFeatureCode": featureIds,
              "userId": userId,
              "status": status,
              "searchText": searchText,
              "startDate": startDate,
              "endDate": endDate,
              "pageRequest": {
                "pageNumber": pageNumber,
                "pageSize": pageSize
             }        
        })
    }

    return await fetch(config.apiUrl + 'Order/GetOrdersList', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function CancelAWBOrder(orderId, sku, cancelReason, comment, code) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "order_id": orderId,
            "sku": sku,
            "cancellation_Reason": cancelReason,
            "comment": comment,
            "code": code
        })
    }

    return await fetch(config.apiUrl + 'Order/CancelAWBOrder', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function ReturnAWBOrder(orderId, sku, cancelReason, comment) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "order_id": orderId,
            "sku": sku,
            "cancellation_Reason": cancelReason,
            "comment": comment
        })
    }

    return await fetch(config.apiUrl + 'Order/ReturnAWBOrder', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function TrackAWB(orderId, sku) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "order_id": orderId,
            "sku": sku
         })
    }

    return await fetch(config.apiUrl + 'Order/TrackAWB', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
