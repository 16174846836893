import React, { useState, useEffect } from "react";
import { commonServices } from "../../../Utility/common.services";
import { commonComponets } from "../../Common/commonComponent";
import { useNavigate } from "react-router-dom";
import { orderService } from "../../../Utility/order.service";
import moment from "moment";
import { connect } from "react-redux";
import OrderDetails from "../TravelHistory/orderDetails";
import PaginatedPages from "../../Common/PaginatedPages";
import './orderHistory.scss'
import { ToastContainer, toast } from "react-toastify";

function OrderHistory(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  
  const [filteredData, setFilteredData] = useState([]);
  const [brandName, setBrandName] = useState('')
  const [durationOptions, setDurationOptions] = useState([
    { id: 1, name: "1 Months" },
    { id: 2, name: "2 Months" },
    { id: 3, name: "3 Months" },
  ]);
  const [statusOptionsDropdown, setStatusOptionsDropdown] = useState([
    { id: 1, name: "Success" },
    { id: 2, name: "InProgress" },
    { id: 3, name: "Failed" },
  ]);

  //const [statusOptions, setOptionStatus] = useState([]);

  const [postperPage, setPostPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState("");
  // const [selectedDuration, setSelectedDuration] = useState(props.duration);
  const [isOpenOrderDetailsPage, setIsOpenOrderDetailsPage] = useState(false);
  const [status,setStatus]=useState({id:1,name:'Success'})
  const [selectOptionDuration, setSelectOptionDuration] = useState(durationOptions[0]);
  const [debouncedBrandName, setDebouncedBrandName] = useState(brandName);
  const [selectOptionStatus, setSelectOptionStatus] = useState({id:1,name:'Success'});
  const [count, setCount] = useState(0)

  const openOrderDetail = (orderId) => {
    props.setOrderId(orderId)
    //setIsOpenOrderDetailsPage(true);
    navigate("/profile", { state: { activePage: 7 } });
  };
  
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedBrandName(brandName);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [brandName]);

  console.log(debouncedBrandName,"ss")

  // useEffect(() => {
  //   // const distinctStatuses = Array.from(new Set(filteredData?.map((item) => item.status)));
  //   // const formattedStatuses = distinctStatuses.map((status,index) => ({ id: index+1, name: status }));
  //   // setOptionStatus(formattedStatuses)
  //   setFilterByStatus(filteredData.filter((item) => item.status === selectOptionStatus.name))
  // }, [filteredData,selectOptionStatus]);

  useEffect(() => {
    getOrdersList();
    // props.setDuration(selectOptionDuration.name);
    // props.setCategoryForGetOrders(selectOptionCategory?.id)
  }, [selectOptionDuration,debouncedBrandName,postperPage,currentPage,props.categoryForGetorders,status]);


  const postPerPageUpdate = (event) => {
    let value = parseInt(event.target.value);
    setPostPerPage(value)
    setCurrentPage(1)
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    // this.setState({
    //     currentPage: pageNumber,
    //     from: from
    // }, () => this.getDonations())
}

  const getOrdersList = async () => {
    const today = new Date();
    const lastDate = new Date(today);
    lastDate.setMonth(today.getMonth() - selectOptionDuration.id);
    setLoader(true);
    setLoadingPrefixText("Getting your order details!");
    try {
      let startDate = today.toISOString();
      let endDate = lastDate.toISOString();

      let response = await orderService.GetOrdersList(
        [props.categoryForGetorders],
        "",
        status.name,
        debouncedBrandName,
        endDate,
        startDate,
        currentPage,
        postperPage
      );

      if (response?.status?.code === 200) {
        setFilteredData(response?.data?.result);
        setCount(response?.data?.count)
        setLoader(false);
        return
      }
      else if (response === 401){
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        setFilteredData([]);
        setLoader(false);
        navigate("/dashboard");
      }
      else{
      setFilteredData([])
      setLoader(false);
      toast.error(response?.status?.message);
      }
    } catch (e) {
      setLoader(false);
      console.log("Error in getOrder", e);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  return (
    // <>
    //   {isOpenOrderDetailsPage ? (
    //     <OrderDetails/>
    //   ) : (
    <>
    <ToastContainer/>
      <div className="table-orders content-box">
      {loader && (
        <div className="loader-overlay flight_loader">
          <div className="boot-strap-loader">
            <div className="spinner-border text-warning" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
          <p>
            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
          </p>
        </div>
      )}

        <h4>Order History</h4>
        <div className="search_dropdowns mb-4">
          <div className="searchBox col-5">
            <img src={images["search_icon.svg"]} alt="img" />
            <input
              type="text"
              placeholder="Search by Order ID"
              className="inputForm"
              onChange={(e) => setBrandName(e.target.value)}
            />
          </div>
          <div className="col-3">
            {commonComponets.SingleDropDownComponent(
              "Duration",
              durationOptions,
              setSelectOptionDuration,
              selectOptionDuration
            )}
          </div>
          <div className="col-3">
            {commonComponets.SingleDropDownComponent(
              "Status",
              statusOptionsDropdown,
              setStatus,
              status
            )}
          </div>
        </div>
        <div className="table-context">
          <table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Order ID</th>
                <th>Order Date</th>
                <th>Category</th>
                <th>Amount</th>
                <th>Cash</th>
                <th>Points</th>
                <th>Order Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.length > 0 ?
                filteredData?.map((ele, index) => {
                  return (
                    <tr key={index}>
                       <td>{index + 1}</td>
                       <td>{ele.orderId}</td>
                       <td>{ele.createdOn}</td>
                       <td>{ele.featureId === "PRO013" ? "Product" : ele.featureId === "DEA015" ? "Deal" : "Voucher"}</td>
                       <td>₹{ele.totalAmount}</td>
                       <td>₹{ele.cash}</td>
                       <td>+{ele.points}</td>
                       <td>
                        <span className="status">
                        {ele.status}
                        </span>
                        </td>
                       <td onClick={() => openOrderDetail(ele.orderId)} className="clickable"><img src={images["eye.svg"]} alt="images" /></td>
                      {/* <td>{ele.orderId}</td>
                      <td>{ele.createdOn}</td>
                      <td>{ele.activity}</td>
                      <td>
                        <img
                          alt=""
                          src={commonServices.imageByDimensions(
                            ele?.orderDetails?.basket_item[0]
                              ?.stock_item_image,
                            "20by20"
                          )}
                          style={{ width: "20px" }}
                        />
                      </td>
                      <td>{ele.orderStatus}</td>
                      <td>{ele.totalAmount}</td>
                      <td>{ele.points}</td> */}
                    </tr>
                  );
                })
                :
                <td colSpan="12">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <img src={images["nodata.jpeg"]} />
                      <p>No Orders Found</p>
                    </div>

                  </div>
                </td>
              }
            </tbody>
          </table>
          <div className="custom-pagination row mt-3">
            {count > postperPage ?
                  <div className='col-lg-6 visible-desktop'>
                    <div className="page-items">
                      <select id="inputState" className="form-control" value={postperPage} onChange={postPerPageUpdate}>
                        <option defaultValue>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                      </select>
                      <span>Items per page</span>
                    </div>
                  </div>
                  : null
                }
                <div className='col-lg-6 p-xs-0'>
                  <div className='pagination-div'>
                    <PaginatedPages
                      postsPerPage={postperPage}
                      totalPosts={count}
                      //totalPosts={currentPosts !== null ? currentPosts.length : 0}
                      paginateNumber={paginate}
                      openedPage={currentPage}
                    />
                  </div>
                </div>
              </div>
        </div>
      </div>
    </>
    //   )}
    // </>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryForGetorders: state.categoryForGetorders,
    duration: state.duration,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDuration: (duration) => {
      dispatch({ type: "DURATION", payload: duration });
    },
    setCategoryForGetOrders: (category) => {
      dispatch({ type: "CATEGORY_FOR_GETORDERS", payload: category });
    },
    setOrderId: (isShow) => {
      dispatch({ type: "ORDER_ID", payload: isShow });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
