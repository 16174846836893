import React,{useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { addressService } from "../../Utility/address.service";
import { ToastContainer, toast } from "react-toastify";
import { commonServices } from "../../Utility/common.services";

const DeleteModal = (props) => {
    const {showDeleteModal,setShowDeleteModal,setAddressId,handleAddressDelete}=props
    const images = commonServices.importImage(
        require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
      );

    

    const handleCancel =()=>{
        setShowDeleteModal(false)
        setAddressId('')
    }
      
  return (
    <Modal
        show={showDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="trackModal_wrap"
      >
        <div className="trackModal_content_wrap cancelModal-wrap ">
          <div onClick={handleCancel} className="exit">
          <img
            src={images["cross_icon.svg"]}
            alt="cross"
            className="cross"
          />
          </div>
            <h4>Are you sure you want to delete?</h4>
          {/* <div className="otp_capture">
            <button
              className="verify_otp"
              id="Payment_OTPVerification"
              onClick={(e) => handleAddressRemove(addressId)}
            >
              Confirm
            </button>
          </div> */}
          <div className="btn-cancel-order">
            <button onClick={handleCancel}>Cancel</button>
            <button className="cancelbtn" onClick={handleAddressDelete}>
              Confirm
            </button>
          </div>
        </div>
        <ToastContainer/>
      </Modal>
  )
}

export default DeleteModal
