import React, { useEffect, useState } from "react";
import { commonComponets } from "../Common/commonComponent";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { commonServices } from "../../Utility/common.services";
import "../Deals/_deals.scss";
import { useNavigate } from "react-router-dom";
import Filter from "../Common/filter";

function AllProducts(props) {
  const {
    Products,
    Brands,
    Categories,
    Departments,
    pointFrom,
    pointTo,
    priceToFiltered,
    categoriesToFiltered,
    brandsToFiltered,
  } = props;
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [sortingOptions, setSortingOptions] = useState([
    { name: "Sort by: a to z", by: "name", mode: "aesc" },
    { name: "Sort by: z to a", by: "name", mode: "desc" },
    { name: "Sort by: Price (low to high)", by: "price", mode: "aesc" },
    { name: "Sort by: Price (high to low)", by: "price", mode: "desc" },
  ]);
  const [DepartmentItemCount, setDepartmentCount] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [showulbbox, setshowulbbox] = useState(false);
  const [selectOption, setSelectOption] = useState("Sorting By");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleulBox = () => {
    setshowulbbox(!showulbbox);
  };

  const handleSelect = (ele, key, val) => {
    setSelectOption(ele.name);
    setshowulbbox(false);
    props.setSortBy(ele.by);
    props.setSortMode(ele.mode);
  };

  const openProductDetails = (ele) => {
    navigate("/productdetails", { state: ele.sku });
  };

  const setDepartment = (ele) => {
    props.setSearchValue("");

    if (ele === "All") {
      setSelectedDepartment(ele);
      props.setSelectedDepartment("");
      props.onChangeBrand();
    } else {
      setSelectedDepartment(ele.name);
      props.setSelectedDepartment(ele.id);
    }
    props.onChangeBrand([]);
  };

  useEffect(() => {
    let dept = Departments?.find((ele) => ele.id === props.selectedDepartment)?.name
    setSelectedDepartment(dept ? dept : "All")
  }, [props.selectedDepartment]);

  return (
    <>
      <div className="container dealTabs mb-5 mt-5">
        <div
          className={
            selectedDepartment === "All"
              ? "tab-block selected_category"
              : "tab-block"
          }
          onClick={() => setDepartment("All")}
        >
          {/* <img alt="" src={images["checkList.svg"]} /> */}
          <span>All Products</span>
        </div>
        {Departments?.length > 0 &&
          Departments?.slice(DepartmentItemCount, DepartmentItemCount + 8)?.map(
            (ele, index) => {
              return (
                <div
                  className={
                    selectedDepartment === ele.name
                      ? "selected_category tab-block"
                      : "tab-block"
                  }
                  key={index}
                  onClick={() => setDepartment(ele)}
                >
                  <span>{ele.name}</span>
                </div>
              );
            }
          )}
        {DepartmentItemCount === 0 && Departments?.length > 7 ? (
          <div className="tab-block" onClick={() => setDepartmentCount(7)}>
            <span>Show More</span>
          </div>
        ) : (
          Departments?.length > 7 && (
            <div className="tab-block" onClick={() => setDepartmentCount(0)}>
              <span>Show Less</span>
            </div>
          )
        )}
      </div>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">HOME</li>
            <li className="breadcrumb-item">
              <a href="/deals" className="disabled">
                SHOP
              </a>
            </li>
          </ol>
        </nav>
        <div className="row mb-5">
          <div className="col-3">
            <Filter
              Categories={Categories}
              Brands={Brands}
              priceToFiltered={priceToFiltered}
              pointFrom={pointFrom}
              pointTo={pointTo}
              filter={(event, key, value) => props.filter(event, key, value)}
              applyFilter={() => props.applyFilter()}
              categoriesToFiltered={categoriesToFiltered}
              brandsToFiltered={brandsToFiltered}
              isShowApply={props.isShowApply}
              resetFilter={props.resetFilter}
            />
          </div>
          <div className="col-9">
            <div className="w-25 mb-4 sort_dropdown">
              <div className="dropdownBox">
                <label>{selectOption}</label>
                <div className="span-img" onClick={handleulBox}>
                  <span className="input-box">{selectOption}</span>
                  <img alt="" src={images["downArrow.svg"]} />
                </div>
                {showulbbox ? (
                  <div className={"ul-li-drp"}>
                    <ul>
                      {sortingOptions?.length > 0 &&
                        sortingOptions?.map((ele, index) => {
                          return (
                            <li key={index} onClick={() => handleSelect(ele)}>
                              {ele.name}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            {Products?.length > 0 ? (
              <>
                <div className="details-container">
                  {Products?.map((ele, index) => {
                    return (
                      <div
                        key={index}
                        className="dealCard_wrap"
                        onClick={() => openProductDetails(ele)}
                      >
                        <div className="image-container">
                          <img
                            src={commonServices.imageByDimensions(
                              ele?.stockItemImage,
                              "20by20"
                            )}
                            alt="images"
                          />
                          {/* <div className="whislist-icon">
                              <img alt="" src={images["wishList.svg"]} />
                        </div> */}
                        </div>

                        <div className="deal-details">
                          <h6>{ele?.brandName}</h6>
                          <h4>{ele?.stockItemTitle}</h4>
                          <span className="price-description">
                            {ele?.mrp !== ele?.price ?
                              <React.Fragment>
                                <span className="without_discount">
                                  ₹{ele?.mrp}&nbsp;
                                </span>
                                <span className="connecter">
                                  &nbsp;{"-"}&nbsp;
                                </span>
                                ₹ {ele?.price}&nbsp;
                              </React.Fragment>
                              :
                              <React.Fragment>
                                <span className="without_discount only-price">
                                  ₹{ele?.mrp}
                                </span>
                              </React.Fragment>
                            }
                            <span className="points"> / {ele.points} Pts.</span>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    selectedDepartment: state.selectedDepartment
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setSelectedDepartment: (selectedDept) => {
      dispatch({ type: "SELECTED_DEPARTMENT", payload: selectedDept });
    },
    setSortBy: (sortBy) => {
      dispatch({ type: "SORT_BY", payload: sortBy });
    },
    setSortMode: (sortMode) => {
      dispatch({ type: "SORT_MODE", payload: sortMode });
    },
    setSelectedBrand: (selectedBrand) => {
      dispatch({ type: "SELECTED_BRAND", payload: selectedBrand });
    },
    setSearchValue: (val) => dispatch({ type: 'SEARCH_VALUE', payload: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts);
