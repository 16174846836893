import React, { useEffect, useState } from "react";
import "./_userDetails.scss";
import ProfileDashboard from "./Profile/profile";
import CouponsOffers from "./Coupons&Offers/couponOffer";
import TravelHistory from "./TravelHistory/travelHistory";
import ReferEarn from "./Refer&Earn/referEarn";
import PointsBalance from "./PointsBalance/pointsbalance";
import RewardCash from "./RewardCash/rewardCash";
import { useLocation } from 'react-router-dom';
import OrderHistory from "./Orders/OrderHistory";
import { connect } from "react-redux";

function ProfileSideView(props) {
  const location = useLocation();
  const [stateactive, setStateActive] = useState(null);
  const [statesubactive, setStatesubActive] = useState(1);

  const handleProfile = (e) => {
    setStateActive(e);
  };
  const handlesubmenu = (e) => {
    if (e === 1){
      props.setCategoryForGetOrders("DEA015")
    }
    if (e === 6){
      props.setCategoryForGetOrders("VOU014")
    }
    if (e === 2){
      props.setCategoryForGetOrders("PRO013")
    }
    setStatesubActive(e);
    setStateActive(3)
  };

  //remove after working
  useEffect (()=>{
    setStateActive(location.state.activePage)
    setStatesubActive(location.statesubactive)
  }, [location])

  console.log(props.categoryForGetorders)
  useEffect (()=>{
    if(props.categoryForGetorders==='DEA015')
      setStatesubActive(1)
    else if(props.categoryForGetorders==='PRO013')
      setStatesubActive(2)
    else if(props.categoryForGetorders==='VOU014')
      setStatesubActive(6)
    else
      setStatesubActive(null)
  }, [props.categoryForGetorders])

  return (
    <div className="main_container ">
      <div className="sidebarContent">
        <ul className="side_wrap">
          <li
            onClick={() => handleProfile(1)}
            className={stateactive === 1 ? "li-active" : "li-inactive"}
          >
            Profile
          </li>
          {/* <li
            onClick={() => handleProfile(2)}
            className={stateactive === 2 ? "li-active" : "li-inactive"}
          >
            Coupons & Offers
          </li> */}
          <li
            onClick={() => handleProfile(3)}
            className={stateactive === 3  || stateactive === 7 ? "li-active" : "li-inactive"}
          >
            Order History
          </li>
          {stateactive === 3 || stateactive ===  7 ? (
            <ul className="sub-menu">
              <li
                onClick={() => handlesubmenu(1)}
                className={
                  statesubactive === 1 ? "li-active-sub" : "li-inactive-sub"
                }
              >
                Deal
              </li>
              <li
                onClick={() => handlesubmenu(2)}
                className={
                  statesubactive === 2 ? "li-active-sub" : "li-inactive-sub"
                }
              >
                Shop
              </li>
              {/* <li
                onClick={() => handlesubmenu(3)}
                className={
                  statesubactive === 3 ?"li-active-sub" : "li-inactive-sub"
                }
              >
                -Gifting
              </li>
              <li
                onClick={() => handlesubmenu(4)}
                className={
                  statesubactive === 4 ? "li-active-sub" : "li-inactive-sub"
                }
              >
                -Utility
              </li>
              <li
                onClick={() => handlesubmenu(5)}
                className={
                  statesubactive === 5 ? "li-active-sub" : "li-inactive-sub"
                }
              >
                -Travel
              </li> */}
              <li
                onClick={() => handlesubmenu(6)}
                className={
                  statesubactive === 6 ? "li-active-sub" : "li-inactive-sub"
                }
              >
                Voucher
              </li>
            </ul>
          ) : null}
          {/* <li
            onClick={() => handleProfile(4)}
            className={stateactive === 4 ? "li-active" : "li-inactive"}
          >
            Refer & Earn
          </li> */}
          <li
            onClick={() => handleProfile(5)}
            className={stateactive === 5 ? "li-active" : "li-inactive"}
          >
            Point Balance
          </li>
          {/* <li
            onClick={() => handleProfile(6)}
            className={stateactive === 6 ? "li-active" : "li-inactive"}
          >
            Reward Cash
          </li> */}
        </ul>
        {/* <div> */}
          {stateactive === 1 ? <ProfileDashboard /> : null}
          {stateactive === 2 ? <CouponsOffers /> : null}
          {stateactive === 3 ? <OrderHistory /> : null}
          {stateactive === 4 ? <ReferEarn /> : null}
          {stateactive === 5 ? <PointsBalance /> : null}
          {stateactive === 6 ? <RewardCash /> : null}
          {stateactive === 7 ? <TravelHistory /> : null}

        {/* </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryForGetorders: state.categoryForGetorders,
    duration: state.duration
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDuration: (duration) => {
      dispatch({ type: "DURATION", payload: duration });
    },
    setCategoryForGetOrders: (category) => {
      dispatch({ type: "CATEGORY_FOR_GETORDERS", payload: category });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSideView);

