import React, { useState, useEffect } from "react";
import { commonServices } from "../../../Utility/common.services";
import { shopService } from "../../../Utility/shop.service";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CardDetails from "../../Common/commonSwiper";
import Modal from "react-bootstrap/Modal";
import { commonComponets } from "../../Common/commonComponent";
import { connect } from "react-redux";
import ShoppingCart from "../../Common/ShoppingCart";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { cartService } from "../../../Utility/cart.service";
import { ToastContainer, toast } from "react-toastify";
import Login from "../../Login/Login";
import TermsConditionModal from "../../Common/TermsConditionModal";

function ProductDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  const [count, setCount] = useState(1);
  const [openTermsAndConditionModal, setOpenTermsAndConditionModal] =
    useState(false);
  const [dropdown, setDropDown] = useState(false);
  const [similarProducts, setSimilarProducts] = useState(false);
  const [selectedImage, setSelectedImage] = useState(commonServices.imageByDimensions(productDetails.stockItemImage, '40by40'))

  const handleTermsAndConditions = () => {
    setOpenTermsAndConditionModal(!openTermsAndConditionModal);
  };

  const imageSelectionFn = (srcType) => {
    console.log(commonServices.imageByDimensions(productDetails.stockItemImage, srcType))
    setSelectedImage(commonServices.imageByDimensions(productDetails.stockItemImage, srcType))
  }

  const handleIncrease = () => {
    const updatedProductDetails = { ...productDetails };

    if (Number(updatedProductDetails.minimumQuantity) < 10) {
      updatedProductDetails.minimumQuantity =
        Number(updatedProductDetails.minimumQuantity) + 1;

      setProductDetails(updatedProductDetails);
    }
    else {
      toast.error("You can add up to 10 quantities!")
    }
  };

  const handleDecrease = () => {
    const updatedProductDetails = { ...productDetails };

    if (updatedProductDetails.minimumQuantity > 1) {
      updatedProductDetails.minimumQuantity =
        Number(updatedProductDetails.minimumQuantity) - 1;

      setProductDetails(updatedProductDetails);
    }
  };

  useEffect(() => {
    getProductDetails(location.state);
  }, []);

  const getProductDetails = async (skuId) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching your product details");
    try {
      let res = await shopService.GetProductDetails(skuId);
      if (res?.status?.code === 200) {
        getRecommendedProducts();
        setProductDetails(res.data);
        setSelectedImage(commonServices.imageByDimensions(res.data.stockItemImage, '40by40'))
        document.getElementById('description').innerHTML = res?.data?.stockItemDescription

        if (skuId !== location.state) {
          navigate(location.pathname, { state: skuId });
        }
        window.scrollTo(0, 0);
      } else {
        setProductDetails([]);
      }
      setLoader(false);
      setLoadingPrefixText('')
    } catch (e) {
      setLoader(false);
      setProductDetails([]);
      console.log("Error in getProductDetails", e);
    }
  };

  const getRecommendedProducts = async () => {
    try {
      let res = await shopService.GetRecommendedProducts();
      if (res?.status?.code === 200) {
        setSimilarProducts(res.data);
      } else {
        setSimilarProducts([]);
      }
    } catch (e) {
      setSimilarProducts([]);
      console.log("Error in getRecommendedProducts", e);
    }
  };

  const addToCart = async () => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are adding it to your cart");
    try {
      let res = await cartService.SaveCartItem(
        productDetails.stockItemTypeId,
        productDetails.stockItemId,
        productDetails.minimumQuantity
      );
      if (res?.status?.code === 200) {
        props.setShowShoppingCart(true);
      }
      else if (res === 401) {
        props.setCurrentPage(window.location.pathname)
        props.setShowLoginForm(true);
        props.setAvailablePoints(0);
        props.setCustomerDetails({});
        props.setCartDetailsData([]);
      }
      else {
        toast.error(res?.status?.message);
      }
      setLoader(false);
      setLoadingPrefixText('')
    } catch (e) {
      setLoader(false);
      console.log("Error in SaveCartItem", e);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container_grey">
        <div className="container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard" className="disabled">
                  HOME
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/shop" className="disabled">
                  SHOP
                </a>
              </li>
            </ol>
          </nav>
          <div className="product-preview_wrap">
            <div className="row">
              <div className="col-5">
                <div className="preview_box">
                  <div className="vertical_swpr">
                    <Swiper
                      spaceBetween={22}
                      slidesPerView={4}
                      direction={"vertical"}
                      // pagination={({ dynamicBullets: true }, { clickable: true })}
                      navigation={true}
                      modules={[Navigation, Scrollbar, A11y]}
                      pagination={{ clickable: true }}
                      scrollbar={{ draggable: true }}
                    >
                      {/* {productDetails.stockItemImage.map((_, index) => ( */}
                      <SwiperSlide>
                        <div className={`sml-img
                             ${selectedImage === commonServices.imageByDimensions(
                          productDetails.stockItemImage,
                          "40by40"
                        ) ? 'active' : ''}`} onClick={() => { imageSelectionFn("40by40") }}>
                          <img
                            alt=""
                            src={commonServices.imageByDimensions(
                              productDetails.stockItemImage,
                              "40by40"
                            )}
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className={`sml-img
                             ${selectedImage === commonServices.imageByDimensions(
                          productDetails.stockItemImage,
                          "30by30"
                        ) ? 'active' : ''}`} onClick={() => { imageSelectionFn("30by30") }}>
                          <img
                            alt=""
                            src={commonServices.imageByDimensions(
                              productDetails.stockItemImage,
                              "30by30"
                            )}
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className={`sml-img
                             ${selectedImage === commonServices.imageByDimensions(
                          productDetails.stockItemImage,
                          "20by20"
                        ) ? 'active' : ''}`} onClick={() => imageSelectionFn("20by20")}>
                          <img
                            alt=""
                            src={commonServices.imageByDimensions(
                              productDetails.stockItemImage,
                              "20by20"
                            )}
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className={`sml-img
                             ${selectedImage === commonServices.imageByDimensions(
                          productDetails.stockItemImage,
                          "10by10"
                        ) ? 'active' : ''}`} onClick={() => { imageSelectionFn("10by10") }}>
                          <img
                            alt=""
                            src={commonServices.imageByDimensions(
                              productDetails.stockItemImage,
                              "10by10"
                            )}
                          />
                        </div>
                      </SwiperSlide>
                      {/* ))} */}
                    </Swiper>
                  </div>
                  <div className="imageBox">
                    {/* <div className="whislist-icon">
                              <img alt="" src={images["wishList.svg"]} />
                        </div> */}
                    <img
                      alt=""
                      src={selectedImage}
                    />
                  </div>
                </div>
              </div>

              <div className="col-7">
                <div className="productDetails_wrap shop-container-size">
                  <div>
                    <h3>{productDetails?.brandName}</h3>
                  </div>
                  <span>{productDetails?.stockItemTitle}</span>
                  {/* <div className="price-offer_box">
                    <h3>Rs. 1,799</h3>
                    <span>(10% off)</span>
                  {commonComponets.PointBox()}
                  </div> */}
                  <h4>
                    {productDetails?.mrp !== productDetails?.price ?
                      <React.Fragment>
                        <span className="without_discount">
                          ₹{productDetails?.mrp}&nbsp;
                        </span>
                        <span className="connecter">
                          &nbsp;{"-"}&nbsp;
                        </span>
                        ₹ {productDetails?.price}&nbsp;
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <span className="without_discount only-price">
                          ₹{productDetails?.mrp}
                        </span>
                      </React.Fragment>
                    }
                    <span className="points">
                      {" "}
                      / {productDetails?.points} Pts.
                    </span>
                  </h4>
                  {/* <div className="mrp_box">
                    MRP :<span> Rs.2,299</span>
                    inclusive of all taxes
                  </div> */}

                  {/* <div className="available_colors">
                    <span>Available Colours</span>
                    <div className=""></div>
                  </div> */}

                  {/* <div className="select-size-box">
                    <div className="name-size">
                      <h3>Select Size</h3>
                      <span>Size Guide</span>
                    </div>
                    <div className="size_box">
                      <div className="circle-div">
                        <span>XS</span>
                      </div>
                      <div className="circle-div">
                        <span>S</span>
                      </div>
                      <div className="circle-div active-bg">
                        <span>M</span>
                      </div>
                      <div className="circle-div">
                        <span>L</span>
                      </div>
                      <div className="circle-div">
                        <span>XL</span>
                      </div>
                      <div className="circle-div">
                        <span>XXL</span>
                      </div>
                      <div className="circle-div">
                        <span>3XL</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="select-quantity">
                    <span>Select Quantity</span>
                    <div className="inc-dec-box">
                      <div
                        className="btn-inc-dec"
                        onClick={() => handleDecrease()}
                      >
                        <img alt="" src={images["minusIcon.png"]} />
                      </div>
                      <div className="count-display">{productDetails?.minimumQuantity}</div>
                      <div
                        className="btn-inc-dec inc"
                        onClick={() => handleIncrease()}
                      >
                        <img alt="" src={images["addIcon.png"]} />
                      </div>
                    </div>
                  </div>
                  <div className="btn-cart-buy">
                    <button className="addcart" onClick={() => addToCart()}>
                      Add to Cart
                    </button>
                    {/* <button className="buynow">Buy Now</button> */}
                  </div>
                  {/* <div className="available_box">
                    <span>Available offers</span>
                    <div className="offerDetails">
                      <div className="img-offer-dtl">
                        <img alt="" src={images["tag.png"]} />
                        <span>
                          What is Lorem Ipsum Lorem Ipsum is simply 10% dummy
                          text of the printing
                        </span>
                      </div>
                      <div className="img-offer-dtl">
                        <img alt="" src={images["tag.png"]} />
                        <span>What is Lorem Ipsum is simply 10% dummy</span>
                      </div>
                      <div className="img-offer-dtl">
                        <img alt="" src={images["tag.png"]} />
                        <span>
                          What is Lorem Lorem Ipsum is simply 10% dummy of the
                          printing
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-4">
                    <p className="description-heading">Product Description</p>
                    <p id="description">
                      {/* {productDetails?.stockItemDescription} */}
                    </p>
                  </div>
                  {productDetails?.tnc && (
                    <p
                      className="terms-conditions"
                      onClick={handleTermsAndConditions}
                    >
                      Terms & Conditions
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {similarProducts?.length > 0 && (
          <div className="similar_product mt-5">
            <div className="dashed-line"></div>
            <div className="container">
              <div className="top-view mt-5">
                <span>Similar Product</span>
              </div>
              <div className="swp mb-5 mt-3">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={4}
                  navigation
                  modules={[Navigation, Scrollbar, A11y]}
                  pagination={{ clickable: true }}
                >
                  {similarProducts?.map((ele, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className="dealCard_wrap"
                          onClick={() => getProductDetails(ele.sku)}
                        >
                          <div className="image-container">
                            <img
                              src={commonServices.imageByDimensions(
                                ele.stockItemImage,
                                "20by20"
                              )}
                              alt="images"
                            />
                          </div>

                          <div className="deal-details">
                            <h6>{ele?.brandName}</h6>
                            <h4>{ele?.stockItemTitle}</h4>
                            <span className="price-description">
                              {ele?.mrp !== ele?.price ?
                                <React.Fragment>
                                  <span className="without_discount">
                                    ₹{ele?.mrp}&nbsp;
                                  </span>
                                  <span className="connecter">
                                    &nbsp;{"-"}&nbsp;
                                  </span>
                                  ₹ {ele?.price}&nbsp;
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span className="without_discount only-price">
                                    ₹{ele?.mrp}
                                  </span>
                                </React.Fragment>
                              }
                              <span className="points">
                                {" "}
                                / {ele.points} Pts.
                              </span>
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        )}
      </div>
      {props.isShowShoppingCart && <ShoppingCart />}
      {props.isShowLoginForm && <Login />}
      <TermsConditionModal
        openTermsAndConditionModal={openTermsAndConditionModal}
        setOpenTermsAndConditionModal={setOpenTermsAndConditionModal}
        tnc={productDetails.tnc}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowShoppingCart: state.isShowShoppingCart,
    isShowLoginForm: state.isShowLoginForm,
    currentPage: state.currentPage
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
    setCurrentPage: (page) => {
      dispatch({ type: "CURRENT_PAGE", payload: page });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
