import React, { useState, useEffect } from "react";
import { commonServices } from "../../Utility/common.services";
import "./OrderDetails.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { orderService } from "../../Utility/order.service";
import { micrositeService } from "../../Utility/microsite.service";
import { ToastContainer, toast } from "react-toastify";
import { cartService } from "../../Utility/cart.service";
import ErrorPage from "./ErrorPage";

function OrderConfirm(props) {
  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");

  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("OrderId");
    let id = props.paymentMode === "points" ? props.orderId : orderId;
    getOrder(id);
    getAvailablePoints();
    getCart();
  }, []);

  const getOrder = async (id) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching order details");
    try {
      let response = await orderService.GetOrder(id);
      if (response?.status?.code === 200) {
        if (
          response?.data?.status === "Payment_Cancelled" ||
          response?.data?.status === "Failure"
        ) {
          navigate("/errorpage");
        } 
        else {
          setOrderDetails(response?.data);
        }
      }
      else if (response === 401) {
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setShowLoginForm(true);
      } 
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in getOrder", e);
    }
  };

  const getAvailablePoints = async () => {
    setLoader(true);
    try {
      let res = await micrositeService.GetCustomerAvailablePoints();
      if (res?.status?.code === 200) {
        props.setAvailablePoints(res?.data?.availablePoints);
      } else {
        props.setAvailablePoints(0);
        toast.error(res?.status?.message);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in redeemCode", e);
    }
  };

  const getCart = async () => {
    try {
      let res = await cartService.GetCart();
      if (res?.status?.code === 200) {
        props.setCartDetailsData(res?.data);
      } else {
        props.setCartDetailsData([]);
      }
    } catch (e) {
      props.setCartDetailsData([]);
      console.log("Error in getCart", e);
    }
  };

  const handleShopMore = () => {
    if (orderDetails?.featureId === "DEA015") {
      navigate("/deals");
    } else if (orderDetails?.featureId === "PRO013") {
      navigate("/shop");
    } else {
      navigate("/voucher");
    }
  };

  const handleGoToAccount = () => {
    navigate("/profile", {
      state: {
        activePage: 3,
        statesubactive: orderDetails?.featureId === "DEA015" ? 1 : 6,
      },
    });
  };
  console.log(orderDetails?.orderPaymentDetails);

  return (
    <>
      <div className="m-5 order_details_container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
        <div className="row order_details">
          <div className="product_details col-8">
            <div className="confirm_msg">
              <img alt="" src={images["confirm_logo.svg"]} />
              <div className="confirm_info">
                <label>Your Order has been confirmed!</label>
                <span>
                  The required details has been sent to your registered email id
                </span>
              </div>
            </div>
            <div className="orderid">
              <span>Order ID : {orderDetails?.orderId}</span>
              <span>Date : {orderDetails?.createdOn}</span>
            </div>
            {orderDetails?.orderDetails?.basket_item?.length > 0 &&
              orderDetails?.orderDetails?.basket_item?.map((ele, index) => {
                return (
                  <div className="voucher_details mb-5" key={index}>
                    <h4>
                      {orderDetails?.featureId === "PRO013"
                        ? "Products"
                        : orderDetails?.featureId === "DEA015"
                        ? "Deals"
                        : "Vouchers"}{" "}
                      Details
                    </h4>
                    <div className="details_div">
                      <img
                        alt=""
                        src={commonServices.imageByDimensions(
                          ele?.stock_item_image,
                          "20by20"
                        )}
                      />
                      <div className="voucher_title">
                        <h4 className="bold_brand">{ele.brandName}</h4>
                        <h4>{ele.stock_item_title}</h4>
                        {/* <div className="points">
                      <label>{ele.price}</label>
                      <span>{ele.points}</span>
                    </div> */}
                        <label>Quantity: {ele.quantity}</label>
                      </div>
                    </div>
                  </div>
                );
              })}
            <p>Want to Shop other Products?</p>
            <div className="btns">
              <button className="shop_more" onClick={() => handleShopMore()}>
                Shop More
              </button>
              <button
                className="goto_myaccont"
                onClick={() => handleGoToAccount()}
              >
                Go To My Account
              </button>
            </div>
          </div>
          <div className="product_summary col-3">
            <h6>Order Summary</h6>
            <div className="summary_details">
              <ul>
                <li>
                  Products
                  <span>{orderDetails?.orderDetails?.basket_item?.length}</span>
                </li>
                <li>
                  Amount
                  <span>
                    ₹ {orderDetails?.orderDetails?.price_summary?.base_price}
                  </span>
                </li>
                <li>
                  Shipping Charges
                  <span>
                    ₹{" "}
                    {orderDetails.orderDetails?.price_summary?.shipping_charges}
                  </span>
                </li>
                <li>
                  Total Amount
                  <span>
                    ₹ {orderDetails.orderDetails?.price_summary?.total_price}
                  </span>
                </li>
                {orderDetails?.orderPaymentDetails?.length > 0 && (
                  <>
                    {orderDetails?.orderPaymentDetails?.map((ele, i) => {
                      return ele?.storePaymentTypeId === 1 ? (
                        <li style={{ borderTop: "1px solid #f2f2f2" }} key={i}>
                          Redeemed Points
                          <span className="green_color mt-3">
                            {ele?.totalPointsRedeemed} pts
                          </span>
                        </li>
                      ) : (
                        <li key={i}>
                          Paid By Other Mode
                          <span className="green_color mt-3">
                            ₹ {ele?.paidAmountExVAT}
                          </span>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowOTPPage: state.isShowOTPPage,
    cardDetailsData: state.cardDetailsData,
    orderId: state.orderId,
    customerDetails: state.customerDetails,
    paymentMode: state.paymentMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowOTPPage: (isShowOTPPage) => {
      dispatch({ type: "IS_SHOW_OTP_PAGE", payload: isShowOTPPage });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirm);
