import { IS_SHOW_LOGIN_FORM } from './action';

const iState = {
    selectedCardData: [],
    screenSize:window.innerWidth,
    isShowLoginForm: false,
    isShowOTPPage: false,
    cardDetailsData: [],
    selectedCategory: [],
    selectedBrand: "",
    selectedDepartment: "",
    customerDetails: {},
    orderId: "",
    sortBy: "",
    sortMode: "",
    categoryForGetorders:"",
    duration:"",
    isShowListingPage: false,
    currentPage:"",
    isShowShoppingCart: false,
    availablePoints: 0,
    searchValue: "",
    isFilterChecked: false,
    paymentMode: ""
}

const Reducers = (state = iState, action) => {
    switch (action.type) {
        case 'TEST_DATA':
            return {
                ...state,
                selectedCardData: action.payload
            }
        case 'SCREEN_SIZE':
            return{
                ...state,
                screenSize:action.payload
            }
        case IS_SHOW_LOGIN_FORM:
            return{
                ...state,
                isShowLoginForm: !state.isShowLoginForm
        }
        case 'REGISTRATION_DETAILS':
            return{
                ...state,
                registrationDetail: action.payload
        }
        case "IS_SHOW_SHOPPING_CART":
            return{
                ...state,
                isShowShoppingCart: !state.isShowShoppingCart
        }
        case "IS_SHOW_OTP_PAGE":
            return{
                ...state,
                isShowOTPPage: !state.isShowOTPPage
        }
        case "LOGIN_THROUGH_CODE":
            return{
                ...state,
                loginThroughCode: !state.loginThroughCode
        }
        case "CARD_DETAILS":
            return{
                ...state,
               cardDetailsData : action.payload
        }
        case "SELECTED_CATEGORY":
            return{
                ...state,
                selectedCategory : action.payload
        }
        case "SELECTED_BRAND":
            return{
                ...state,
                selectedBrand : action.payload
        }
        case "SELECTED_DEPARTMENT":
            return{
                ...state,
                selectedDepartment : action.payload
        }
        case "CUSTOMER_DETAILS":
            return{
                ...state,
                customerDetails : action.payload
        }
        case "ORDER_ID":
            return{
                ...state,
                orderId : action.payload
        }
        case "SORT_BY":
            return{
                ...state,
                sortBy : action.payload
        }
        case "SORT_MODE":
            return{
                ...state,
                sortMode : action.payload
        }
        case "CATEGORY_FOR_GETORDERS":
            return{
                ...state,
                categoryForGetorders : action.payload
        }
        case "DURATION":
            return{
                ...state,
                duration : action.payload
        }
        case "IS_LISTING_PAGE":
            return{
                ...state,
                isShowListingPage : action.payload
        }
        case "CURRENT_PAGE":
            return{
                ...state,
                currentPage : action.payload
        }
        case "AVAILABLE_POINTS":
            return{
                ...state,
                availablePoints : action.payload
        }
        case "SEARCH_VALUE":
            return{
                ...state,
                searchValue : action.payload
        }
        case "IS_FILTER_CHECK":
            return{
                ...state,
                isFilterChecked : action.payload
        }
        case "PAYMENT_MODE":
            return{
                ...state,
                paymentMode : action.payload
        }
        default:
            return state
    }
}

export default Reducers;