import React, { useEffect, useState } from 'react'
import { commonServices } from "../../../Utility/common.services";
import { commonComponets } from "../../Common/commonComponent";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { addressService } from '../../../Utility/address.service';
import { Utility } from "../../../Utility/utility.service";
import "../../Shop/_shop.scss";
import { redisService } from '../../../Utility/redis.service';

const AddressModal = (props) => {
  const { addeditModal, handleAddressEditModal, saveAddress, particularAddress, editAddress, setStates, states, customerAddresDetails, setCustomerAddressDetails } = props
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );
  console.log(particularAddress, editAddress)
  const [cities, setCities] = useState([]);
  const [selectOption, setSelectOption] = useState()


  console.log(customerAddresDetails, "24")
  const [showulbbox, setshowulbbox] = useState(false);
  const [showulbboxcity, setshowulbboxcity] = useState(false);

  const handleulBox = () => {
    setshowulbbox(!showulbbox);
  };

  const getShopCities = async (stateId) => {
    setLoader(true);
    setLoadingPrefixText("Fetching Cities");
    try {
      let response = await redisService.GetShopCities(stateId);
      if (response?.status?.code === 200) {
        setCities(response?.data);
      } else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  // const fetchAddressesByUser = async () => {
  //   setLoader(true);
  //   setLoadingPrefixText("Fetching your address details!");
  //   try {
  //     let response = await addressService.FetchAddressesByUser();
  //     if (response?.status?.code === 200) {
  //       // if (response?.data !== null) {
  //       //   setAddressDetails(response.data);
  //       //   setAddNewAddress(false);
  //       // }
  //       // else {
  //       //   setAddNewAddress(true);
  //       //   setAddressDetails([]);
  //       // }
  //     }
  //     else if (response === 401) {
  //       // props.setShowLoginForm(true);
  //       // props.setCurrentPage("/addressdetails")
  //     }
  //     else {
  //       // setAddNewAddress(true);
  //       // setAddressDetails([]);
  //       // toast.error(response?.status?.message);
  //     }
  //     setLoader(false);
  //   } catch (ex) {
  //     //   setAddNewAddress(true);
  //     //   setAddressDetails([]);
  //     setLoader(false);
  //     toast.error("Something went wrong !! Please try again !!");
  //   }
  // };


  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };



  const fetchCustomerAddress = (e, key) => {
    try {
      const value = e.target.value;

      setCustomerAddressDetails((prevDetails) => {
        let updatedDetails = { ...prevDetails };

        switch (key) {
          case "addressType":
            updatedDetails.AddressType = value;
            break;

          case "firstname":
            const firstNameLimit = 20;
            let firstNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
            let firstName = value.slice(0, firstNameLimit);
            if (!firstName || firstName === "") {
              toast.error("Please enter your first name.");
            }  
              updatedDetails.firstName = firstName.match(firstNameRe)
                ? Utility.htmlEncode(firstName)
                : "";
            
            break;

          case "lastname":
            const lastNameLimit = 20;
            let lastNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
            let lastName = value.slice(0, lastNameLimit);
            if (!lastName || lastName === "") {
              toast.error("Please enter your last name.");
              
            }  
              updatedDetails.lastName = lastName.match(lastNameRe)
                ? Utility.htmlEncode(lastName)
                : "";
            
            break;

          case "email":
            const emailLimit = 50;
            let email = value.slice(0, emailLimit).replace(/\s+/g, "");
            if (
              !email ||
              email === ""
            ) {
              toast.error(
                email === "" ||
                  !email
                  ? "Please enter your email id."
                  : "Invalid e-mail address"
              );
              
            } 
              updatedDetails.emailID = Utility.htmlEncode(email);
            
            break;

          case "mobile":
            const mobileLimit = 10;
            let mobileRe = /^\d*$/;
            let mobile = value.slice(0, mobileLimit);
            if (
              !mobile ||
              mobile === ""
            ) {
              toast.error("Please enter your mobile number.");
            }
            updatedDetails.mobileNo = mobile.match(mobileRe)
            ? Utility.htmlEncode(mobile)
            : ""; 
            break;

          case "address":
            const addressLimit = 100;
            let address = value.slice(0, addressLimit);
            if (!address || address === "") {
              toast.error("Please enter your address.");
              
            }  
              updatedDetails.Address = Utility.htmlEncode(address);
            
            break;

          case "pinCode":
            const pinCodeLimit = 6;
            let pinCodeRe = /^\d*$/;
            let pinCode = value.slice(0, pinCodeLimit);
            if (
              !pinCode ||
              pinCode === ""
            ) {
              toast.error("Please enter your pincode.");
              
            }  
              updatedDetails.pinCode = pinCode.match(pinCodeRe)
              ? Utility.htmlEncode(pinCode)
              : "";
            
            break;

          case "locality":
            const localityLimit = 20;
            let locality = value.slice(0, localityLimit);
            updatedDetails.locality = Utility.htmlEncode(locality);
            break;

          case "city":
            if (value !== "" && value !== "Select City") {
              updatedDetails.city = value;
              setshowulbboxcity(false)
            }
            else{
              toast.error("Please select your city.");
            }
            break;

          case "state":
            // let stateName = value.split("_")[0];
            // let stateId = value.split("_")[1];
            if (value !== "" && value !== "Select State") {
              updatedDetails.state = value;
              setshowulbbox(false);
              updatedDetails.city = ""; // Clear city when state changes
              setshowulbboxcity(false)
              getShopCities(value?.split("_")[1]);
            }
            else{
              toast.error("Please select your state.");
            }
            break;

          default:
            break;
        }

        return updatedDetails;
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  console.log(customerAddresDetails?.state,customerAddresDetails?.city, 'customerAddresDetails')



  const handleSubmit = (e) => {
    e.preventDefault()
    // checkCustomerProductAddress()
    if( customerAddresDetails?.mobileNo?.length < 10){
      toast.error("Invalid mobile number")
      return
    }
    else if(customerAddresDetails?.pinCode?.length !== 6){
      toast.error("Invalid  pincode")
      return
    }
    else if(!validateEmail(customerAddresDetails?.emailID)){
      toast.error("Invalid  email address")
      return
    }
    // console.log(checkCustomerProductAddress())
    // if (!customerAddresDetails.invalidFname && !customerAddresDetails.invalidLname && !customerAddresDetails.invalidEmail && !customerAddresDetails.invalidMobile &&
    //   !customerAddresDetails.invalidAddress && !customerAddresDetails.invalidCity && !customerAddresDetails.invalidState && !customerAddresDetails.invalidPincode
    // ) {
      if (Object?.keys(particularAddress).length > 0) {
        editAddress(customerAddresDetails)
        return
      }
      saveAddress(customerAddresDetails)
    // }
  }


  return (
    <>
      <Modal
        className="editModal_wrap"
        show={addeditModal}
        onHide={handleAddressEditModal}
        centered
      >
        <div className='d-flex justify-content-end'>
        <div onClick={handleAddressEditModal} className="exit">
          <img
            src={images["cross_icon.svg"]}
            alt="cross"
            className="cross"
          />
          </div>
        </div>
        
        <Modal.Body>
          <div className="edit_wrap ">
            <h4>Add Address Details :</h4>
            <div className="row ">
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    //className="form__input"
                    autocomplete="off"
                    placeholder=" "
                    value={customerAddresDetails?.firstName}
                    className={
                      customerAddresDetails?.invalidFname
                        ? "form__input invalid"
                        : "form__input"
                    }
                    onChange={(e) => fetchCustomerAddress(e, "firstname")}
                  />
                  <label className="form__label">First Name<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    //className="form__input"
                    className={
                      customerAddresDetails?.invalidLname
                        ? "form__input invalid"
                        : "form__input"
                    }
                    autocomplete="off"
                    placeholder=" "
                    value={customerAddresDetails?.lastName}
                    onChange={(e) => fetchCustomerAddress(e, "lastname")}
                  />
                  <label className="form__label">Last Name<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>


              <div className="col mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className={
                      customerAddresDetails?.invalidMobile
                        ? "form__input invalid"
                        : "form__input"
                    }
                    autocomplete="off"
                    placeholder=" "
                    maxLength={"10"}
                    value={customerAddresDetails?.mobileNo}
                    onChange={(e) => fetchCustomerAddress(e, "mobile")}
                  />
                  <label className="form__label">Mobile Number<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className={
                      customerAddresDetails?.invalidPincode
                        ? "form__input invalid"
                        : "form__input"
                    }
                    autocomplete="off"
                    placeholder=" "
                    maxLength={6}
                    onChange={(e) => fetchCustomerAddress(e, "pinCode")}
                    value={customerAddresDetails?.pinCode}
                  />
                  <label className="form__label">Pincode<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="dropdownBox">
                  {/* <label>State</label> */}
                  <div
                    //className="span-img" 
                    className={
                      customerAddresDetails?.invalidState
                        ? "span-img invalid"
                        : "span-img"
                    }
                    onClick={handleulBox}>
                    <span
                      className="input-box"
                    >{customerAddresDetails?.state ? customerAddresDetails?.state?.split("_")[0] : 'Select State*'}</span>
                    <img alt="" src={images["downArrow.svg"]} />
                  </div>
                  {showulbbox ? (
                    <div
                      className={"ul-li-drp show_scroll"}
                    >
                      <ul>
                        {/* {options?.map((ele, index) => {
                            return (
                              <li key={index} value={ele?.id} onClick={() => handleSelect(ele)}>
                                {ele.name}
                              </li>
                            );
                          })} */}
                        {states?.length > 0 ? (
                          states?.sort(function (a, b) {
                            if (
                              a.stateName.toLowerCase() <
                              b.stateName.toLowerCase()
                            )
                              return -1;
                            if (
                              a.stateName.toLowerCase() >
                              b.stateName.toLowerCase()
                            )
                              return 1;
                            return 0;
                          })?.map((ele, i) => {
                            return (
                              <>
                                <option
                                  key={i}
                                  value={ele.stateName + "_" + ele.stateId}
                                  onClick={(e) => fetchCustomerAddress(e, "state")}
                                >
                                  {ele.stateName}
                                </option>
                              </>
                            );
                          })
                        ) : (
                          <option>No States Found</option>
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>
                {/* <select
                    className={
                      customerAddresDetails.invalidState
                        ? "form-select invalid"
                        : "form-select"
                    }
                    required
                    id="State2"
                    onChange={(e) => fetchCustomerAddress(e, "state")}
                    value={customerAddresDetails.state}
                  >
                    <option value="">Select State</option>
                    {states.length > 0 ? (
                      states
                        .sort(function (a, b) {
                          if (
                            a.stateName.toLowerCase() <
                            b.stateName.toLowerCase()
                          )
                            return -1;
                          if (
                            a.stateName.toLowerCase() >
                            b.stateName.toLowerCase()
                          )
                            return 1;
                          return 0;
                        })
                        .map((ele, i) => {
                          return (
                            <>
                              <option
                                key={i}
                                value={ele.stateName + "_" + ele.stateId}
                              >
                                {ele.stateName}
                              </option>
                            </>
                          );
                        })
                    ) : (
                      <option>No data Found</option>
                    )}
                  </select> */}
                {/* <label htmlFor="State2" className="label">
                    State
                  </label> */}
              </div>

              <div className="col-sm-6 mt-3">
                <div className="dropdownBox">
                  {/* <label>State</label> */}
                  <div
                    //className="span-img" 
                    className={
                      customerAddresDetails?.invalidCity
                        ? "span-img invalid"
                        : "span-img"
                    }
                    onClick={() => setshowulbboxcity(!showulbboxcity)}>
                    <span className="input-box">{customerAddresDetails?.city ? customerAddresDetails?.city : 'Select City*'}</span>
                    <img alt="" src={images["downArrow.svg"]} />
                  </div>
                  {showulbboxcity ? (
                    <div
                      className={"ul-li-drp show_scroll"}
                    >
                      <ul>
                        {/* {options?.map((ele, index) => {
                            return (
                              <li key={index} value={ele?.id} onClick={() => handleSelect(ele)}>
                                {ele.name}
                              </li>
                            );
                          })} */}

                        {cities?.length > 0 ? (
                          cities?.sort(function (a, b) {
                            if (
                              a.cityName.toLowerCase() <
                              b.cityName.toLowerCase()
                            )
                              return -1;
                            if (
                              a.cityName.toLowerCase() >
                              b.cityName.toLowerCase()
                            )
                              return 1;
                            return 0;
                          })?.map((ele, i) => {
                            return (
                              <>
                                <option key={i} value={ele.cityName}
                                  onClick={(e) => fetchCustomerAddress(e, "city")}>{ele.cityName}</option>
                              </>
                            );
                          })
                        ) : (
                          <option>
                            {!customerAddresDetails?.city
                              ? "Please Select State First"
                              : customerAddresDetails?.city}
                          </option>
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>
                {/* <label htmlFor="State2" className="label">
                    City/District
                  </label> */}

                {/* <div className="form-floating">
                  <select
                    className={
                      customerAddresDetails.invalidCity
                        ? "form-select invalid"
                        : "form-select"
                    }
                    id="city"
                    value={customerAddresDetails.city}
                    onChange={(e) => fetchCustomerAddress(e, "city")}
                    placeholder="City / District"
                  >
                    <option value="">Select City</option>
                    {cities.length > 0 ? (
                      cities
                        .sort(function (a, b) {
                          if (
                            a.cityName.toLowerCase() <
                            b.cityName.toLowerCase()
                          )
                            return -1;
                          if (
                            a.cityName.toLowerCase() >
                            b.cityName.toLowerCase()
                          )
                            return 1;
                          return 0;
                        })
                        .map((ele, i) => {
                          return (
                            <>
                              <option key={i}>{ele.cityName}</option>
                            </>
                          );
                        })
                    ) : (
                      <option>
                        {customerAddresDetails.city === ""
                          ? "Please Select State First"
                          : customerAddresDetails.city}
                      </option>
                    )}
                  </select>
                  <label htmlFor="city" className="label">
                    City/District
                  </label>
                </div> */}
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-12 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    //className="form__input"
                    className={
                      customerAddresDetails?.invalidEmail
                        ? "form__input invalid"
                        : "form__input"
                    }
                    autocomplete="off"
                    placeholder=" "
                    value={customerAddresDetails?.emailID}
                    onChange={(e) => fetchCustomerAddress(e, "email")}
                  />
                  <label className="form__label">Email Address<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-12 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    //className="form__input"
                    className={
                      customerAddresDetails?.invalidAddress
                        ? "form__input invalid"
                        : "form__input"
                    }
                    autocomplete="off"
                    placeholder=" "
                    onChange={(e) => fetchCustomerAddress(e, "address")}
                    value={customerAddresDetails?.Address}
                  />
                  <label className="form__label">House no./ Building/ Apartment<span style={{ color: "red" }}> *</span></label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-12 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    // className={
                    //   customerAddresDetails.invalidAddress
                    //     ? "form__input invalid"
                    //     : "form__input"
                    // }
                    autocomplete="off"
                    placeholder=" "
                    onChange={(e) => fetchCustomerAddress(e, "locality")}
                    value={customerAddresDetails?.locality}
                  />
                  <label className="form__label">Area, Sector, Street, Village</label>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-6 mt-3">{commonComponets.SingleDropDown("", [])}</div> */}
            {/* <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Landmark</label>
                  <div className="optionalSpan">
                    <span>(Optional)</span>
                  </div>
                </div>
              </div> */}
            {/* <div className="col-sm-6 mt-3 mb-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Alternate Phone(Optional)</label>
                </div>
              </div>
            </div> */}

            {/* <div className="select-address mb-4">
              <span>Type Of Address :</span>
              <div className="inp_lable">
                <input type="radio" name="address" />
                <label>Home</label>
              </div>
              <div className="inp_lable">
                <input type="radio" name="address" />
                <label>Office</label>
              </div>
              <div className="inp_lable">
                <input type="radio" name="address" />
                <label>Other</label>
              </div>
            </div> */}
            {/* <div className="checked-box_address mb-4">
              <input name="checkme" id="checkme" type="checkbox" />
              <label htmlFor="checkme" >Make this as my default address</label>
            </div> */}
            {/* <button className="mb-4" onClick={() => saveAddress()}>Add</button> */}
            <div className="btn-cancel-order mt-5">
              <button onClick={handleAddressEditModal}>Cancel</button>
              <button type='submit' className={`cancelbtn ${ !customerAddresDetails?.firstName || !customerAddresDetails?.lastName || !customerAddresDetails?.state || !customerAddresDetails?.city ||
                !customerAddresDetails?.emailID || !customerAddresDetails?.mobileNo || !customerAddresDetails?.pinCode || !customerAddresDetails?.Address  ? 'disabled':''}`} onClick={handleSubmit}>
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
    </>
  )
}

export default AddressModal
