import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./_common.scss";
import { connect } from "react-redux";
import { commonServices } from "../../Utility/common.services";
import { useNavigate } from "react-router-dom";
import { paymentService } from "../../Utility/payment.service";
import { ToastContainer, toast } from "react-toastify";
import { micrositeService } from "../../Utility/microsite.service";

function RedeemCodeModal(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [code, setCode] = useState("");

  const closeModel = () => {
    props.setCloseModal(!props.closeModal);
  };

  const handleRedeemCode = (e) => {
    setCode(e.target.value)
  }

  const redeemCode = async (e) => {
    e.preventDefault()
    if (code?.length > 0){
    setLoader(true);
    try {
      let res = await paymentService.RedeemCode(props.customerDetails?.mobile, code);
      if (res?.status?.code === 200) {
        getAvailablePoints(res?.data?.dealCodeValue);
        props.setCloseModal(false);
      } else {
        toast.error(res?.status?.message)
        //toast.error('Please enter valid redeem code!!')
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in redeemCode", e);
    }
  }
  else{
    toast.error('Please enter valid redeem code!!')
  }
  }

  const getAvailablePoints = async (addedPoints) => {
    try {
      let res = await micrositeService.GetCustomerAvailablePoints();
      if (res?.status?.code === 200) {
        toast.success(`${addedPoints} " points added to your account total points available " ${res?.data?.availablePoints}`);
        props.setAvailablePoints(res?.data?.availablePoints)
      } else {
        props.setAvailablePoints(0);
        toast.error(res?.status?.message)
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in redeemCode", e);
    }
  }

  return (
    <>
      {false ? (
        <div className="container p-5">
          <h4>Payment Page</h4>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <img alt="" src={images["loading_logo.svg"]} />
        </div>
      ) : (
        <Modal
          show={props.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="redeem_points_modal"
        >
          <ToastContainer />
          {loader && (
            <div className="loader-overlay flight_loader">
              <div className="boot-strap-loader">
                <div className="spinner-border text-warning" role="status">
                  {/* <span className="sr-only">Loading...</span> */}
                </div>
              </div>
              <p>
                {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              </p>
            </div>
          )}
          <div className="redeem-points-container">
          <div onClick={() => closeModel()} className="exit">
            <img
              src={images["cross_icon.svg"]}
              alt="cross"
            />
          </div>
            <h3>Enter Your Redeem Points</h3>
            <div className="input-group">
              <input
                autoComplete="off"
                type="text"
                // placeholder="7765678989"
                className={"input-area first-input"}
                required
                id="mobilenumber"
                maxLength={20}
                disabled
                value={props.customerDetails?.mobile}
              />
              <label htmlFor="mobilenumber" className="label disabled">
                Enter Your Mobile Number
              </label>
              <img className="start-add-on" src={images["mobilePhone.svg"]} />
              <img className="end-add-on" src={images["greentick_icon.svg"]} />
            </div>
            <div className="input-group mt-4">
              <input
                autoComplete="off"
                type="text"
                // placeholder="7765678989"
                className={"input-area second-input"}
                required
                id="couponcode"
                maxLength={20}
                value={code}
                onChange={(e) => handleRedeemCode(e)}
              />
              <label htmlFor="couponecode" className="label">
                Enter Your Coupon Code<span>*</span>
              </label>
            </div>
            <div className="otp_capture">
              <button
                className="verify_otp"
                onClick={(e) => redeemCode(e)}
              >
                Proceed
              </button>
            </div>
          </div>
        </Modal>
      )}

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowOTPPage: state.isShowOTPPage,
    cardDetailsData: state.cardDetailsData,
    orderId: state.orderId,
    customerDetails: state.customerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowOTPPage: (isShowOTPPage) => {
      dispatch({ type: "IS_SHOW_OTP_PAGE", payload: isShowOTPPage });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemCodeModal);
