import React, { useState, useEffect } from "react";
import { commonServices } from "../../Utility/common.services";
import "./OrderDetails.scss";
import { useNavigate } from "react-router-dom";
import ConfirmOTP from "./ConfirmOTP";
import { connect } from "react-redux";
import { paymentService } from "../../Utility/payment.service";
import { micrositeService } from "../../Utility/microsite.service";
import { cartService } from "../../Utility/cart.service";
import RedeemCodeModal from "./RedeemCodeModal";
import { ToastContainer, toast } from "react-toastify";

function DeliveryDetails(props) {
  const navigate = useNavigate();
  const [redeemPoints, setRedeemPoints] = useState(0);
  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  const [paymentMode, setPaymentMode] = useState("points");
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [openRedeemCodeModal, setOpenRedeemCodeModal] = useState(false);
  const [form, setForm] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);
  const [isPaymentProcessAgain, setIsPaymentProcessAgain] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState("");

  const editRedeemPoints = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    const requiredPoints = parseInt(
      props.cardDetailsData?.price_summary?.required_points,
      10
    );
    const availablePoints = parseInt(props.availablePoints, 10);

    if (isNaN(inputValue) || inputValue < 0) {
      setRedeemPoints();
      return;
    }

    if (inputValue > availablePoints) {
      setRedeemPoints(inputValue);
      // toast.error("You don't have enough points.");
      return;
    }

    if (inputValue <= requiredPoints) {
      setRedeemPoints(inputValue);
    } else {
      toast.error(`You cannot redeem more than ${requiredPoints} points.`);
    }
  };

  useEffect(() => {
    getCart();

    if (sessionStorage.getItem("HasReturn") === "true"){
      sessionStorage.setItem("HasReturn", false);
      navigate("/errorpage");
    }
  }, []);

  const getCart = async () => {
    setLoader(true);
    try {
      let res = await cartService.GetCart();
      if (res?.status?.code === 200) {
        props.setCartDetailsData(res?.data);
        setRedeemPoints(res?.data?.price_summary?.required_points);
      } else {
        props.setCartDetailsData([]);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      props.setCartDetailsData([]);
      console.log("Error in getCart", e);
    }
  };

  const createMarkup = (value) => {
    return { __html: value };
  };

  const getAvailablePoints = async () =>{
    setLoader(true);
    try {
      let res = await micrositeService.GetCustomerAvailablePoints();
      if (res?.status?.code === 200) {
        props.setAvailablePoints(res?.data?.availablePoints)
      } else {
        props.setAvailablePoints(0);
        toast.error(res?.status?.message)
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in redeemCode", e);
    }
  }

  const handlePaymentContinue = async () => {
    try {
      setLoader(true);
      setLoadingPrefixText("Hold on!! We are fetching details");
      if ((paymentMode === "points" && redeemPoints > 0) || paymentMode === "cash") {
        let points = paymentMode === "cash" ? "" : redeemPoints;
        // if(isPaymentProcessAgain){
          sessionStorage.setItem("HasReturn", true);
        // }
        let res = await paymentService.PaymentProcess(
          points?.toString(),
          paymentMode,
          isPaymentProcessAgain ? props.orderId : ""
        );
        if (res?.status?.code === 200) {
          getAvailablePoints();
          if (res.data.isOTPNeeded) {
            //sendOTP(res?.data?.orderId);
            props.setShowOTPPage(true);
          } else if (res?.data?.isPaymentComplete) {
            sessionStorage.setItem("HasReturn", false);
            navigate("/orderconfirm");
          } else if (res?.data?.isRedirect) {
            document.write(res?.data?.form);
            // setForm(res?.data?.form);
            // setIsRedirect(res?.data?.isRedirect);
          }
          else if (parseInt(res?.data?.remainingAmount) > 0) {
            handlePaymentMode("cash", res?.data?.remainingAmount);
            toast.success(`${res?.status?.message} & remaining amount is ₹ ${res?.data?.remainingAmount}`);
            setLoader(false);
          } else {
            toast.error(res?.status?.message);
            setLoader(false);
          }
          props.setOrderId(res?.data?.orderId);
          props.setPaymentMode(paymentMode);
        } else {
          toast.error(res?.status?.message);
        }
      }
      else{
        toast.error("Please enter points");
      }
      setLoader(false);
      setLoadingPrefixText("");
    } catch (e) {
      setLoader(false);
      console.log("Error in handlePaymentContinue", e);
    }
  };

  const handlePaymentOption = (e, type) => {
    setPaymentMode(e.target.value);
    if (type === "redeem") {
      setOpenRedeemCodeModal(!openRedeemCodeModal);
    }
  };

  const handlePaymentMode = (mode, remainAmount) =>{
    setPaymentMode(mode);
    setIsPaymentProcessAgain(true);
    setRemainingAmount(remainAmount);
  }

  return (
    <>
      <ToastContainer />
      <div className="m-5 order_details_container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
        {/* {showPaymentOptions ? ( */}
        <h4>Choose Payment Method</h4>
        {/* ) : (
          <h4>Choose Gift Option</h4>
        )} */}
        <div className="row order_details">
          {/* {showPaymentOptions ? ( */}
          <div className="product_details">
            <div className="check-box-div">
              <div className="chck-wrap border_bottom">
                <div className="input_label">
                  <input
                    name="select"
                    type="radio"
                    id="radio"
                    value="points"
                    checked={paymentMode === "points"}
                    onChange={(e) => handlePaymentOption(e)}
                    disabled={isPaymentProcessAgain}
                    className={isPaymentProcessAgain ? "disabled_btn" : ""}
                  />
                  <div className="headings">
                    <label htmlFor="radio" className="activecls">
                      Redeem Points
                    </label>
                    <span className="balance">
                      Balance : {props.availablePoints} Points
                    </span>
                  </div>
                </div>
                <div className="edit_redeem_points">
                  <label>Edit Your Point</label>

                  <div className="input-group">
                    <input
                      type="text"
                      className="input-area"
                      required
                      id="Name"
                      // value={props.cardDetailsData?.price_summary?.required_points}
                      value={redeemPoints}
                      onChange={(e) => editRedeemPoints(e)}
                      disabled={isPaymentProcessAgain}
                    />
                    <img className="edit-img" src={images["edit.svg"]} />
                  </div>
                  {/* <span>
                        Equivalent to ₹{" "}
                        {props.cardDetailsData?.price_summary?.total_price}
                      </span> */}
                  {/* <span>You can redeem 50 Points minimum</span> */}
                </div>
              </div>
              <div className="chck-wrap border_bottom">
                <div className="input_label">
                  <input
                    name="select"
                    type="radio"
                    id="radio_cash"
                    value="cash"
                    checked={paymentMode === "cash"}
                    onChange={(e) => handlePaymentOption(e)}
                  />
                  <label htmlFor="radio_cash">Pay By Other Mode</label>
                </div>
              </div>
              <div className="chck-wrap border_bottom">
                <div className="input_label">
                  <input
                    name="select"
                    type="radio"
                    id="radio_redeem"
                    value="redeem code"
                    checked={paymentMode === "redeem code"}
                    onChange={(e) => handlePaymentOption(e, "redeem")}
                  />
                  <label htmlFor="radio_redeem">Redeem Code</label>
                </div>
              </div>
            </div>
          </div>

          <div className="product_summary">
            <h6>Order Summary</h6>
            <div className="summary_details">
              <ul>
                <li>
                  Products
                  <span>
                    {
                      props.cardDetailsData?.price_summary?.price_breakup[0]
                        ?.Quantity
                    }
                  </span>
                </li>
                <li>
                  Amount
                  <span>
                    ₹ {props.cardDetailsData?.price_summary?.base_price}
                  </span>
                </li>
                {/* <li>
                  Tax Charges
                  <span>Rs.No</span>
                </li> */}
                <li>
                  Shipping Charges
                  <span>
                    ₹ {props.cardDetailsData?.price_summary?.shipping_charges}
                  </span>
                </li>
                <li className="total_amount">
                  Total Amount
                  <span>
                    ₹ {props.cardDetailsData?.price_summary?.total_price}
                  </span>
                </li>
                <li className="orange_color">
                  Required Points
                  <span className="orange_color">
                    {props.cardDetailsData?.price_summary?.required_points} pts
                  </span>
                </li>
                <li>
                  Available Points
                  <span>{props.availablePoints} pts</span>
                </li>
                {isPaymentProcessAgain ?
                <>
                <li>
                  Paid Amount
                  <span>₹ {(parseInt(props.cardDetailsData?.price_summary?.total_price) - remainingAmount)} </span>
                </li>
                <li>
                  Remaining Amount
                  <span>₹ {remainingAmount}</span>
                </li>
                </>
                : null}
              </ul>
            </div>
            <div className={paymentMode === "redeem code" || parseInt(redeemPoints) > parseInt(props.availablePoints) ? "summary_btn_wrap disabled_btn" : "summary_btn_wrap"}>
              <button disabled={paymentMode === "redeem code" || parseInt(redeemPoints) > parseInt(props.availablePoints) ? true : false}
                onClick={
                  // showPaymentOptions
                  //   ?
                  () => handlePaymentContinue()
                  // : () => handleContinue()
                }
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        {/* {isRedirect ? 
          <div dangerouslySetInnerHTML={{ __html: form }} />
        : null} */}

        {props.isShowOTPPage && <ConfirmOTP handlePaymentMode={handlePaymentMode} />}
        {openRedeemCodeModal && (
          <RedeemCodeModal
            closeModal={openRedeemCodeModal}
            setCloseModal={setOpenRedeemCodeModal}
          />
        )}
        {/* {showPaymentOptions && <RedeemCodeModal />} */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowOTPPage: state.isShowOTPPage,
    cardDetailsData: state.cardDetailsData,
    customerDetails: state.customerDetails,
    availablePoints: state.availablePoints,
    orderId: state.orderId
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowOTPPage: (isShow) => {
      dispatch({ type: "IS_SHOW_OTP_PAGE", payload: isShow });
    },
    setOrderId: (isShow) => {
      dispatch({ type: "ORDER_ID", payload: isShow });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setPaymentMode: (mode) => {
      dispatch({ type: "PAYMENT_MODE", payload: mode });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);
