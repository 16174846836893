import React, { useEffect, useState } from "react";
import { commonComponets } from "../Common/commonComponent";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { commonServices } from "../../Utility/common.services";
import "./_deals.scss";
import { dealService } from "../../Utility/deal.service";
import AllDeals from "./AllDeals";
import { homeService } from "../../Utility/home.service";
import { useNavigate } from "react-router-dom";

function Deals(props) {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [deals, setDeals] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isShowListiongPage, setIsShowListiongPage] = useState(false);
  const [size, setSize] = useState(500);
  const [from, setFrom] = useState(0);
  const [pointFrom, setPointFrom] = useState(1);
  const [pointTo, setPointTo] = useState(500000);
  const [sortBy, setSortBy] = useState("");
  const [sortMode, setSortMode] = useState("");
  const [priceToFiltered, setPriceToFiltered] = useState([0, 0]);
  const [categoriesToFiltered, setCategoriesToFiltered] = useState([]);
  const [brandsToFiltered, setBandsToFiltered] = useState([]);
  const [hotSellingDeals, setHotSellingDeals] = useState([
    { pointFrom: 1, pointTo: 249 },
    { pointFrom: 250, pointTo: 500 },
    { pointFrom: 500, pointTo: 750 },
  ]);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [pageSectionData, setPageSectionData] = useState([]);
  const [isShowApply, setIsShowApply] = useState(false);
  const [categoryItemCount, setCategoryItemCount] = useState(8);
  const [categoriesItemsLength, setCategoriesItemsLength] = useState(0);
  
  useEffect(() => {
    props.setSelectedDepartment("");
    props.setSelectedBrand("");
    props.setSelectedCategory([]); 

    getDepartments();
    getFeatures();
    getPageSections();
    
    window.scrollTo(0, 0);
    window.addEventListener('popstate', function(event) {
      setIsShowListiongPage(false)
    });
  }, []);

  const getPageSections = async () => {
    try {
      let res = await homeService.GetPageSections("Deal");
      if (res !== null) {
        setCategoriesItemsLength(res.filter((ele) => ele.sectionCode === "Categories")[0]?.items?.length || 0)
        setPageSectionData(res);
      } else {
        setPageSectionData([]);
      }
    } catch (e) {
      console.log("Error in getCategories", e);
    }
  };

  useEffect(() => {
    getDeals(
      props.selectedDepartment,
      brandsToFiltered.join(),
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode
    );
  }, [
    props.selectedDepartment,
    brandsToFiltered,
    // pointTo,
    // pointFrom,
    categoriesToFiltered,
    props.sortBy,
    props.sortMode,
    props.selectedCategory,
  ]);

  const onChangeBrand = (id) => {
    setBandsToFiltered(id);
    setCategoriesToFiltered(id);
  };

  useEffect(() => {
    getBrands();
    getCategories();
  }, [props.selectedDepartment]);

  useEffect(() => {
    if (props.isShowListingPage) {
      setCategoriesToFiltered(
        props.selectedCategory ? props.selectedCategory : []
      );
      setBandsToFiltered(props.selectedBrand ? props.selectedBrand : []);

      props.setShowListingPage(false);
      setIsShowListiongPage(true);
    }
  }, [props.isShowListingPage]);

  const getDeals = async (
    departments,
    brands,
    pointFrom,
    pointTo,
    categories,
    sBy,
    sMode
  ) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching deals");
    // setLoadingSuffixText("Deals page for you...");
    try {
      let res = await dealService.GetDeals(
        size,
        from,
        departments,
        brands,
        pointFrom,
        pointTo,
        sBy,
        sMode,
        categories
      );
      if (res?.status?.code === 200) {
        setDeals(res?.data?.result);
      } else {
        setDeals([]);
      }
      setLoader(false);
      setLoadingPrefixText("");
      setLoadingSuffixText("");
    } catch (e) {
      setDeals([]);
      console.log("Error in getDeals", e);
    }
  };

  const getBrands = async () => {
    try {
      let res = await dealService.GetBrands(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setBrands(res.data);
      } else {
        setBrands([]);
      }
    } catch (e) {
      setBrands([]);
      console.log("Error in getBrands", e);
    }
  };

  const getCategories = async () => {
    try {
      let res = await dealService.GetCategories(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setCategories(res.data);
      } else {
        setCategories([]);
      }
    } catch (e) {
      setCategories([]);
      console.log("Error in getCategories", e);
    }
  };

  const getDepartments = async () => {
    try {
      let res = await dealService.GetDepartments();
      if (res?.status?.code === 200) {
        setDepartments(res.data);
      } else {
        setDepartments([]);
      }
    } catch (e) {
      console.log("Error in getDepartments", e);
    }
  };

  const getFeatures = async () => {
    try {
      let res = await dealService.GetFeatureSections();
      if (res?.status?.code === 200) {
        setFeatures(res.data);
      } else {
        setFeatures([]);
      }
    } catch (e) {
      setFeatures([]);
      console.log("Error in getFeatures", e);
    }
  };

  const openListingPage = (key, departmentId, brandId) => {
    if (key === "Brands") {
      props.setSelectedBrand(brandId);
      setBandsToFiltered([brandId]);
    } else if (key === "Categories") {
      props.setSelectedDepartment(brandId); 
    } 
    else if (key === "hot_deals") {
      setPointFrom(departmentId?.pointFrom);
      setPointTo(departmentId?.pointTo);
    } 
    // else if (key === "hot_deals") {
    //   setPointFrom(departmentId);
    //   setPointTo(departmentId);
    // } 
    else if (key === "BeautyAndCosmetics") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand(brandId);
      setBandsToFiltered([brandId]);
    } else if (key === "Travel") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand(brandId);
      setBandsToFiltered([brandId]);
    }
    setIsShowListiongPage(true);
  };

  const filterSectionHandler = async (event, key, value) => {
    try {
      let catagoriesArr = [];
      let brandsArr = [];
      props.setSearchValue("");

      if (key === "category") {
        let categories = [...categoriesToFiltered];

        if (categories.includes(value)) {
          const updatedCategories = categories.filter(
            (categoryId) => categoryId !== value
          );
          setCategoriesToFiltered(updatedCategories);
        } else {
          catagoriesArr.push(value);
          setCategoriesToFiltered([...categoriesToFiltered, ...catagoriesArr]);
        }
      } else if (key === "brand") {
        let brands = [...brandsToFiltered];

        if (brands.includes(value)) {
          const updatedBrands = brands.filter((brandId) => brandId !== value);
          await setBandsToFiltered(updatedBrands);
        } else {
          brandsArr.push(value);
          await setBandsToFiltered([...brandsToFiltered, ...brandsArr]);
        }
      } else if (key === "price") {
        setPointFrom(value[0]);
        setPointTo(value[1]);
        setIsShowApply(true);
        props.setIsFilterChecked(true);
        // getDeals(
        //   props.selectedDepartment,
        //   brandsToFiltered.join(),
        //   value[0]?.toString(),
        //   value[1]?.toString(),
        //   props.selectedCategory,
        //   props.sortBy,
        //   props.sortMode
        // );
      }
    } catch (error) {
      console.log("error in filterSectionHandler is", error);
    }
  };

  const applyFilter = async () => {
    getDeals(
      props.selectedDepartment,
      brandsToFiltered?.join(),
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode
    );
    setIsShowApply(false);
  };

  const resetFilter = () => {
    setPointFrom(1);
    setPointTo(500000);
    props.setIsFilterChecked(false)
    getDeals(      
      props.selectedDepartment,
      brandsToFiltered.join(),
      1,
      500000,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode
    )
  }

  const redirectTo = (url) =>{
    navigate(url);
  }

  return (
    <>
      {/* <div className="container dealTabs mb-5 ">
        <div className="tab-block">
          <img alt="" src={images["checkList.svg"]} />
          <span>All</span>
        </div>
        <div className="tab-block">
          <img alt="" src={images["dealShop.svg"]} />
          <span>Shopping</span>
        </div>

        <div className="tab-block">
          <img alt="" src={images["health.svg"]} />
          <span>Health & Beauty</span>
        </div>
        <div className="tab-block">
          <img alt="" src={images["trolley.svg"]} />
          <span>Travel</span>
        </div>

        <div className="tab-block">
          <img alt="" src={images["bowl.svg"]} />
          <span>Dining</span>
        </div>
        <div className="tab-block">
          <img alt="" src={images["mobile.svg"]} />
          <span>Mobile & Electronics</span>
        </div>
        <div className="tab-block">
          <img alt="" src={images["popBucket.svg"]} />
          <span>Entertainment</span>
        </div>

        <div className="tab-block">
          <img alt="" src={images["lamp.svg"]} />
          <span>Home & Lifestyle</span>
        </div>
        <div className="tab-block">
          <img alt="" src={images["dealGift.svg"]} />
          <span>Gifting</span>
        </div>
      </div> */}

      <div className="swp-banner">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation
          modules={[Navigation, Scrollbar, A11y]}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {pageSectionData?.length > 0 &&
            pageSectionData
              .filter((ele) => ele.sectionCode === "HeroBanner")
              .map((ele) =>
                ele.items?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} onClick={() =>redirectTo(item?.redirectUrl)}>
                      <img alt="" src={item.imageUrl} />
                    </SwiperSlide>
                  );
                })
              )}
        </Swiper>
      </div>
      <div className="container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              {loadingSuffixText ? loadingSuffixText : null}
            </p>
          </div>
        )}
        {!isShowListiongPage ? (
          <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" className="disabled">
                    HOME
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/deals">DEALS</a>
                </li>
              </ol>
            </nav>
            <div className="top-view">
              <span>Hot Selling Deals</span>
              <button onClick={() => openListingPage()}>View All</button>
            </div>
            <div className="swp pb-3">
              <Swiper
                spaceBetween={22}
                slidesPerView={3}
                navigation
                modules={[Navigation, A11y]}
                pagination={{ clickable: true }}
              >
                {hotSellingDeals?.length > 0 &&
                  hotSellingDeals?.map((ele, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className="hot_selling_deals"
                          onClick={() => openListingPage("hot_deals", ele)}
                        >
                          <div className="deal-details">
                            <h6>Deals Under Rs.{ele.pointTo}</h6>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}

                {/* {pageSectionData?.length > 0 &&
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "HotSellingDeals")
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <SwiperSlide
                            key={index}
                            onClick={() =>
                              openListingPage("hot_deals", item?.uId)
                            }
                          >
                            <div className="dashboard-container">
                              <div className="image-container">
                                <img alt="image" src={item.imageUrl} />
                              </div>
                              <div className="overlay-text">
                                <h1>{item.itemDisplayName}</h1>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })
                    )} */}
              </Swiper>
            </div>

            <div className="top-view mt-5 pt-1">
              <span>Brands</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Brands")
                ?.items?.length && (
                <button onClick={() => openListingPage("Brands")}>
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Brands")?.items
              ?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Brands")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage("Brands", "", item.uId)
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Deals Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Travel</span>
              {/* <button onClick={() => openListingPage("brand")}>
                  View All
                </button> */}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Travel")?.items
              ?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Travel")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "Travel",
                                    ele.groupId,
                                    item.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Deals Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Beauty & Cosmetics</span>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "BeautyAndCosmetics"
              )?.items?.length && (
                <button
                  onClick={() =>
                    openListingPage(
                      "BeautyAndCosmetics",
                      pageSectionData?.find(
                        (ele) => ele.sectionCode === "BeautyAndCosmetics"
                      )?.groupId
                    )
                  }
                >
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "BeautyAndCosmetics"
            )?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={3}
                    navigation
                    modules={[Navigation, A11y]}
                    pagination={{ clickable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter(
                          (ele) => ele.sectionCode === "BeautyAndCosmetics"
                        )
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "BeautyAndCosmetics",
                                    ele.groupId,
                                    item.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item.itemDisplayName}</h4>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Deals Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Categories</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
                ?.items?.length && (
                <button onClick={() => openListingPage("Categories")}>
                  View All
                </button>
              )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
              ?.items?.length > 0 ? (
              <>
                <div className="row">
                  {pageSectionData?.length > 0 &&
                    pageSectionData
                      .filter((ele) => ele.sectionCode === "Categories")
                      .map((ele) =>
                        ele.items?.slice(0, categoryItemCount)?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="col-md-3 mb-3"
                              onClick={() =>
                                openListingPage(
                                  "Categories",
                                  ele?.groupId,
                                  item?.uId
                                )
                              }
                            >
                              <div className="categories">
                                <img alt="" src={item.imageUrl} />
                                <div className="categories-detail">
                                  <h4>{item?.itemDisplayName}</h4>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Deals Available</p>
              </div>
            )}
            <div
              className="d-flex justify-content-center align-items-center mb-5 pt-3 pb-3"
            >
                  {categoryItemCount !== categoriesItemsLength && categoriesItemsLength > 8 ? (
            <div className="button-container" onClick={() => setCategoryItemCount(categoriesItemsLength)}>
            <span>Show More</span>
            <img src={images["down_arrow.svg"]} alt="name" />
          </div>
          ) : (
            categoriesItemsLength > 0 &&
            categoriesItemsLength > 8 && (
              <div className="button-container" onClick={() => setCategoryItemCount(8)}>
              <span>Show Less</span>
              <img src={images["down_arrow.svg"]} alt="name" />
            </div>
            )
          )}
            </div>
          </>
        ) : (
          <AllDeals
            Deals={deals}
            Categories={categories}
            Brands={brands}
            Departments={departments}
            priceToFiltered={priceToFiltered}
            pointFrom={pointFrom}
            pointTo={pointTo}
            filter={(event, key, value) =>
              filterSectionHandler(event, key, value)
            }
            applyFilter={() => applyFilter()}
            categoriesToFiltered={categoriesToFiltered}
            brandsToFiltered={brandsToFiltered}
            onChangeBrand={onChangeBrand}
            isShowApply={isShowApply}
            resetFilter={resetFilter}
          />
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    selectedCategory: state.selectedCategory,
    selectedBrand: state.selectedBrand,
    selectedDepartment: state.selectedDepartment,
    sortBy: state.sortBy,
    sortMode: state.sortMode,
    isShowListingPage: state.isShowListingPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setSelectedCategory: (selectedCat) => {
      dispatch({ type: "SELECTED_CATEGORY", payload: selectedCat });
    },
    setSelectedBrand: (selectedBrand) => {
      dispatch({ type: "SELECTED_BRAND", payload: selectedBrand });
    },
    setSelectedDepartment: (selectedDept) => {
      dispatch({ type: "SELECTED_DEPARTMENT", payload: selectedDept });
    },
    setShowListingPage: (isShow) => {
      dispatch({ type: "IS_LISTING_PAGE", payload: isShow });
    },
    setSearchValue: (val) => dispatch({ type: 'SEARCH_VALUE', payload: val }),
    setIsFilterChecked: (val) => dispatch({ type: 'IS_FILTER_CHECK', payload: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
