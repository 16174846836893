import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setShowLoginForm } from "../../Reducers/action";
import "./Login.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { micrositeService } from "../../Utility/microsite.service";
import { commonServices } from "../../Utility/common.services";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { cartService } from "../../Utility/cart.service";

function Login(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [mobileNumber, setMobileNumber] = useState(props?.registrationDetail?.mobile ?? '');
  const [organisation, setOrganisation] = useState(props?.registrationDetail?.orgranization ?? '');
  const [signUpOrLogin, setSignUpOrLogin] = useState(Object?.keys(props?.registrationDetail ?? {})?.length);
  const [email, setEmail] = useState(props?.registrationDetail?.email ?? '');
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(60);

  const [requestId, setRequestId] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [invalidOtp, setInvalidOtp] = useState("");
  const [isShowValidate, setIsShowValidate] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const isShowLoginForm = useSelector((state) => state.isShowLoginForm);
  const dispatch = useDispatch();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  const handleClose = () => {
    dispatch(setShowLoginForm());
  };

  const handleMobNo = (e) => {
    if (mobileNumber !== "") {
      setDisabled(false);
    }
    setMobileNumber(e.target.value);
  };
 const handleOtpSender = async() =>{

  setLoader(true);
  setLoadingPrefixText("Please wait");
  // setLoadingSuffixText("Shop page for you");
  try {
    let res = await micrositeService.GenerateOTP(mobileNumber, email);
    if (res?.status?.code === 200) {
      setRequestId(res.data);
      setIsShowValidate(true);
      startTimer();
    } else {
      toast.error(res?.status.message);
    }
    setLoader(false);
    setLoadingPrefixText("");
    setLoadingSuffixText("");
  } catch (e) {
    console.log("Error in generateOTP", e);
  }
 }
  const generateOTP = async (e,enableFn=false) => {
    // e?.preventDefault()
    console.log(e?.keyCode)
    if(e?.keyCode === 13){
      handleOtpSender()
    }
    else if (enableFn){
      handleOtpSender()
    }
  };

  const startTimer = () => {
    setCanResend(false);
    setTimer(60); // Reset timer to 30 seconds
    const countdown = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOtp = () => {
    if (canResend) {
      setOtp1("");
      setOtp2("");
      setOtp3("");
      setOtp4("");
      setOtp5("");
      setOtp6("");
      generateOTP('',true);
    } else {
      toast.info(`Please wait ${timer} seconds before resending OTP.`);
    }
  };

  const handleChange = (e, key) => {
    let otp = e.target.value.substr(0, 1);
    let isExactly1Digits = /\b\d{1}\b/g.test(otp);
    if (isExactly1Digits) {
      if (key === 1) {
        setOtp1(otp);
      } else if (key === 2) {
        setOtp2(otp);
      } else if (key === 3) {
        setOtp3(otp);
      } else if (key === 4) {
        setOtp4(otp);
      } else if (key === 5) {
        setOtp5(otp);
      } else if (key === 6) {
        setOtp6(otp);
      }
    } else {
      if (key === 1) {
        setOtp1("");
      } else if (key === 2) {
        setOtp2("");
      } else if (key === 3) {
        setOtp3("");
      } else if (key === 4) {
        setOtp4("");
      } else if (key === 5) {
        setOtp5("");
      } else if (key === 6) {
        setOtp6("");
      }
    }
    setInvalidOtp(false);
  };

  const onKeyPressUpOtp = (e) => {
    debugger
    //  var container = document.getElementsByClassName("otp_input")[0];
    let otp = e.target.value;
    let isExactly1Digits = /\b\d{1}\b/g.test(otp);
    if (isExactly1Digits || e.keyCode === 8) {
      var target = e.srcElement || e.target;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var next = target;
        // eslint-disable-next-line
        while ((next = next.nextElementSibling)) {
          if (next == null) break;
          if (next.tagName.toLowerCase() === "input") {
            next.focus();
            break;
          }
        }
      }
      // Move to previous field if empty (user pressed backspace)
      else if (myLength === 0) {
        var previous = target;
        // eslint-disable-next-line
        while ((previous = previous.previousElementSibling)) {
          if (previous == null) break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
          }
        }
      }
    }
  };

  const handlePaste = (e,key) => {
    console.log(e.clipboardData.getData('Text'))
    console.log(e.clipboardData.getData('Text').slice(0, 6))
    const pastedData=e.clipboardData.getData('Text').slice(0, 6)
    let dummyKey=key
    pastedData.split('').map((digit,index)=>{
      if(index<6){
        if (dummyKey === 1) {
          setOtp1(digit);
        } else if (dummyKey === 2) {
          setOtp2(digit);
        } else if (dummyKey === 3) {
          setOtp3(digit);
        } else if (dummyKey === 4) {
          setOtp4(digit);
        } else if (dummyKey === 5) {
          setOtp5(digit);
        } else if (dummyKey === 6) {
          setOtp6(digit);
        }
        dummyKey++;
      }
      else{
        return
      }
    })
    const inputs = e.target.parentElement.children;
    const lastInput = inputs[Math.min(pastedData.length + key - 2, 5)];
    if (lastInput) {
      lastInput.focus();
    }
    e.preventDefault();
  };

  const validateOTP = async () => {
    try {
      let enteredOtp = [otp1, otp2, otp3, otp4, otp5, otp6];
      let otpLength = enteredOtp.join("");
      if (otpLength?.length >= 6) {
        setLoader(true);
        setLoadingPrefixText("Hold on!! We are validating you");
        let res = await micrositeService.ValidateOTP(
          mobileNumber,
          requestId,
          otpLength
        );
        if (res?.status?.code === 200) {
          let response = await micrositeService.SearchCustomer();
          const handleResponse = async (response) => {
            if (response?.status?.code === 200) {
              props.setCustomerDetails(response.data);
              props.setAvailablePoints(response?.data?.availablePoints)
              dispatch(setShowLoginForm(false));
              getCart();
              if (props.currentPage) {
                navigate(props.currentPage);
              } else {
                navigate("/dashboard");
              }
            } else if (response?.status?.code === 253) {
              let registeredUserResponse =
                await micrositeService.RegisterCustomer(mobileNumber);
              if (registeredUserResponse?.status?.code === 200) {
                // Re-run the SearchCustomer API
                let newResponse = await micrositeService.SearchCustomer();
                await handleResponse(newResponse);
              }
            } else if (response === 401) {
              props.setCustomerDetails({});
              props.setAvailablePoints(0)
              props.setShowLoginForm(true);
            } else {
              toast.error("Something went wrong !! Please try again !!");
            }
          };

          await handleResponse(response);
        } else {
          toast.error(res?.status?.message);
        }
        setLoader(false);
        setLoadingPrefixText("");
        setLoadingSuffixText("");
      } else {
        toast.error("Please enter valid OTP");
      }
    } catch (e) {
      console.log("Error in validateOTP", e);
    }
  };

  const getCart = async () => {
    try {
      let response = await cartService.GetCart();
      if (response?.status?.code === 200) {
        props.setCartDetailsData(response?.data);
      } else if (response === 401) {
        props.setCartDetailsData({});
        props.setShowLoginForm(true);
      } else {
        props.setCartDetailsData({});
      }
    } catch (e) {
      props.setCartDetailsData({});
      console.log("Error in getCart", e);
    }
  };

  return (
    <>
      <Modal
        show={isShowLoginForm}
        aria-labelledby="example-custom-modal-styling-title"
        centered
        // onHide={() => handleClose()}
        className="login_modal"
      >
        {/* <Modal.Header>
      <button type="submit" className="close_button" onClick={() => handleClose()}>X</button>
      </Modal.Header> */}
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              {loadingSuffixText ? loadingSuffixText : null}
            </p>
          </div>
        )}
        <div className="login-container">
          <div className="exit" onClick={() => handleClose()}>
            <img
              src={images["cross_icon.svg"]}
              alt="cross"
              className="cross"
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <img
              src={images["rewardcompany_logo.svg"]}
              alt="img"
              className="logo_div"
            />
          </div>
          {/* <div className="col-5 leftside_container">
            <h4>Welcome!</h4>
          </div> */}
          <div className="inner-container">
            <h2>Start Your Journey With TRC</h2>

            {/* <h4>{isLoginOrRegister ? "Login" : "Register"}</h4> */}
            {/* <div className="form-group mb-3">
              <div className="form-floating">
                <input
                  autoComplete="off"
                  type="email"
                  placeholder="email"
                  className={"form-control"}
                  required
                  id="email12"
                  maxLength={50}
                />
                <label htmlFor="email12" className="label">
                  Email ID<span style={{ color: "red" }}> *</span>
                </label>
              </div>
            </div> */}
            {signUpOrLogin ? (
              <>
                <p>
                  Join the Rewards Revolution, Start Curating Experiences Today!
                </p>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <input
                      autoComplete="off"
                      type="text"
                      className={"input-area"}
                      required
                      id="organisation"
                      value={organisation}
                      onChange={(e) => setOrganisation(e.target.value)}
                      readOnly={props?.registrationDetail?.orgranization ? true : false}
                    />
                    <label htmlFor="organisation" className="label disable">
                      Select Your Organisation
                    </label>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group">
                    <input
                      autoComplete="off"
                      type="text"
                      className={"input-area"}
                      required
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly={props?.registrationDetail?.email ? true : false}
                    />
                    <label htmlFor="email" className={`label ${props?.registrationDetail?.email ? 'disable' : ''}`}>
                      Enter Your Email Id<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <div className="input-group">
                    <input
                      autoComplete="off"
                      type="text"
                      className={"input-area"}
                      required
                      id="loginMobilenumber"
                      maxLength={"10"}
                      value={mobileNumber}
                      onChange={(e) => handleMobNo(e)}
                      readOnly={props?.registrationDetail?.mobile ? true : false}
                    />
                    <label htmlFor="loginMobilenumber" className={`label ${props?.registrationDetail?.mobile ? 'disable' : ''}`}>
                      Enter Your Mobile Number<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
                <div className="sign-up-login">
                  <p>Already Registered User? <a onClick={() => setSignUpOrLogin(!signUpOrLogin)}>Click here</a> to login</p>
                </div>
              </>
            ) : (
              <>
                <p>
                  Get Set To Access Your Personalized Rewards Hub
                </p>
                <div className="form-group mb-3 mt-4">
                  <div className="input-group">
                    <input
                      autoComplete="off"
                      type="text"
                      className={"input-area"}
                      required
                      id="registerMobilenumber"
                      maxLength={"10"}
                      value={mobileNumber}
                      onChange={(e) => handleMobNo(e)}
                      readOnly={isShowValidate}
                      onKeyUp={(e)=>generateOTP(e)}
                    />
                    <label htmlFor="registerMobilenumber" className={`label ${isShowValidate ? 'disable' : ''}`}>
                      Enter Your Mobile Number<span style={{ color: "red" }}> *</span>
                    </label>
                    <img src={images["mobilePhone.svg"]} />
                  </div>
                </div>
                {props.loginThroughCode ?
                  <div className="sign-up-login">
                    <p>Not Registered Yet? <a onClick={() => setSignUpOrLogin(!signUpOrLogin)}>Click here</a> to SignUp</p>
                  </div>
                  : null}

              </>
            )}
            {/* {!isLoginOrRegister && (
              <div className="form-group mb-3">
                <div className="form-floating">
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="mobile"
                    className={"form-control"}
                    required
                    id="mobile22"
                    maxLength={"10"}
                    value={mobileNumber}
                    onChange={(e) => handleMobNo(e)}
                  />
                  <label htmlFor="mobile22" className="label">
                    Mobile Number<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            )} */}
            {/* <p className="mb-3">
              By continuing, you agree to The 'Reward Company's{" "}
              <Link className="no_underline">Terms of Use</Link> and{" "}
              <Link className="no_underline">Privacy Policy.</Link>
            </p> */}
            {isShowValidate && (
              <>
                {props.loginThroughCode ?
                  <p className="otp-text">Enter OTP Sent to registered Email ID and Mobile Number.</p>
                  :
                  <p className="otp-text">Enter OTP Sent to registered Mobile Number.</p>
                }
                <div className="resend-otp-container">
                  <span onClick={handleResendOtp} disabled={!canResend} className="resend-otp">
                    Resend OTP</span> <p className="timer">{canResend ? '' : `(${timer}s)`}</p>
                </div>
                <div className="otp_input mb-4">
                  <input
                    onPaste={(e)=>handlePaste(e,1)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-1"
                    value={otp1}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => handleChange(e, 1)}
                    maxLength="1"
                  />
                  <input
                    onPaste={(e)=>handlePaste(e,2)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-2"
                    value={otp2}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => {
                      handleChange(e, 2);
                    }}
                    maxLength="1"
                  />
                  <input
                    onPaste={(e)=>handlePaste(e,3)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-3"
                    value={otp3}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => {
                      handleChange(e, 3);
                    }}
                    maxLength="1"
                  />
                  <input
                    onPaste={(e)=>handlePaste(e,4)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-4"
                    value={otp4}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => {
                      handleChange(e, 4);
                    }}
                    maxLength="1"
                  />
                  <input
                    onPaste={(e)=>handlePaste(e,5)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-5"
                    value={otp5}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => {
                      handleChange(e, 5);
                    }}
                    maxLength="1"
                  />
                  <input
                    onPaste={(e)=>handlePaste(e,6)}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autoComplete="Chrome-off"
                    className="input_box"
                    onWheel={(e) => e.target.blur()}
                    style={invalidOtp ? { border: "1px solid #E92953" } : null}
                    pattern="/\b\d{1}\b/g"
                    name="field-6"
                    value={otp6}
                    onKeyUp={(e) => onKeyPressUpOtp(e)}
                    onChange={(e) => {
                      handleChange(e, 6);
                    }}
                    maxLength="1"
                  />
                </div>
              </>
            )}
            {isShowValidate ? (
              <button
                className={`otp-button mb-2 ${otp1 && otp2 && otp3 && otp4 && otp5 && otp6 ? "" : "disabled"
                  }`}
                onClick={() => validateOTP()}
              >
                Continue
              </button>
            ) : (
              <button
                className={`otp-button mb-2 ${mobileNumber.length !== 10 ? "disabled" : props.loginThroughCode && !email ? "disabled" : ""
                  }`}
                onClick={() => generateOTP('',true)}
              >
                Send OTP
              </button>
            )}
            {/* <div className="divider-container mt-3">
              <div className="divider"></div>
              <div className="or-text">OR</div>
              <div className="divider"></div>
            </div>
            <div className="others mt-3 mb-3">
              <button>Register With Google</button>
              <button className="ml-3" style={{marginLeft:'10px'}}>Register With Facebook</button>
            </div> */}
            {/* {isLoginOrRegister ? (
              <p className="p_msg">
                Don't have an account?{" "}
                <Link
                  className="no_underline"
                  onClick={() => handleIsLoginOrRegister()}
                >
                  {" "}
                  Register
                </Link>
              </p>
            ) : (
              <p className="p_msg">
                Already have an account?{" "}
                <Link
                  className="no_underline"
                  onClick={() => handleIsLoginOrRegister()}
                >
                  {" "}
                  Login
                </Link>
              </p>
            )} */}
          </div>
        </div>
        <ToastContainer />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowLoginForm: state.isShowLoginForm,
    currentPage: state.currentPage,
    registrationDetail: state.registrationDetail,
    loginThroughCode: state.loginThroughCode
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
