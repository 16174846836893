import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import { commonComponets } from "../../Common/commonComponent";
import Modal from "react-bootstrap/Modal";
import { commonServices } from "../../../Utility/common.services";
import { orderService } from "../../../Utility/order.service";


const TrackItemModal =(props)=>{
    const images = commonServices.importImage(
        require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
      );
      const [loader, setLoader] = useState(false);
      const [loadingPrefixText, setLoadingPrefixText] = useState("");
      const [trackingDetails,setTrackingDetails]=useState({})

      useEffect(()=>{
        getTrackingDetails()
      },[])

      const getTrackingDetails = async () => {
        setLoader(true);
        setLoadingPrefixText("Please wait");
        try {
          let response = await orderService.TrackAWB(props.orderId,props.sku.sku);
          if (response?.status?.code === 200) {
            setTrackingDetails(response?.data);
          }
          setLoader(false);
          setLoadingPrefixText("");
        } catch (e) {
          setLoader(false);
          console.log("Error in getOrder", e);
        }
      };
      
return(
    <Modal
        className="trackModal_wrap"
        show={props.trackModal}
        size="xl"
        aria-labelledby="track-item-modal"
        centered
      >
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
          <div className="trackModal_content_wrap">
          <div className="exit" onClick={()=>props.setTrackModal(!props.trackModal)}>
            <img
              src={images["cross_icon.svg"]}
              alt="cross"
              className="cross"
            />
          </div>
            <h4>Track Item</h4>
            <div className="delivery-details">
             
              
                  <div className="lable-span">
                    <label>Courier Service :</label> <span>{trackingDetails?.courierName}</span>
                  </div>
              
               
                  <div className="lable-span">
                    <label>Estimated Delivery Date :</label>{" "}
                    <span>{trackingDetails?.tracking_data?.shipment_track?.[0]?.courier_agent_details}</span>
                  </div>
              
               
                  <div className="lable-span">
                    <label>Tracking ID :</label> <span>{trackingDetails?.awbNo}</span>
                  </div>
              
              
            </div>
            <div className="product-view-track">
              <div className="img-size-quant-modal">
                <div className="image-container">
                <img
                              src={commonServices.imageByDimensions(
                                props?.sku?.stock_item_image?.[0],
                                "10by10"
                              )}
                              alt="star"
                            />
                </div>
                <div className="details-product">
                  <h4>{props?.sku?.brandName}</h4>
                  <p>{props?.sku?.stock_item_title}</p>
                  <div className="span-wrap">
                    {/* <span>Size:M</span>
                    <span>Color:Blue</span> */}
                    <span>Qty: {props?.sku?.quantity}</span>
                  </div>
                  {commonComponets.PointBox()}
                </div>
              </div>
            </div>
            <div className="order-track-view row">
              {trackingDetails?.tracking_data &&
              trackingDetails?.tracking_data?.map((shipment)=>{
                return(
                  <div className="col-3 p-0">
                <div className="view-box-track">
                  <div className="radio-dashed">
                    {/* <input type="radio" /> */}
                    <div className={`outer-circle ${shipment.activity==='delivered' ? 'active-outr' : ''}`}>
                      <div className={`inner-circle ${shipment.activity==='delivered' ? 'active-bg' : ''}`}></div>
                    </div>
                    <div className={`dashed ${shipment.activity==='delivered' ? 'brd' : ''}`}></div>
                  </div>
                  <span>{shipment.activity}</span>
                  <p>{shipment.date}</p>
                </div>
              </div>
                )
              })}
              
              {/* <div className="col-3 p-0">
                <div className="view-box-track">
                  <div className="radio-dashed">
                    <div className="outer-circle">
                      <div className="inner-circle"></div>
                    </div>
                    <div className="dashed"></div>
                  </div>
                  <span>Order Place</span>
                  <p>Tuesday, 30-May-2022</p>
                </div>
              </div>
              <div className="col-3 p-0">
                <div className="view-box-track">
                  <div className="radio-dashed">
                    <div className="outer-circle">
                      <div className="inner-circle"></div>
                    </div>
                    <div className="dashed"></div>
                  </div>
                  <span>Order Place</span>
                  <p>Tuesday, 30-May-2022</p>
                </div>
              </div>
              <div className="col-3 p-0">
                <div className="view-box-track">
                  <div className="radio-dashed">
                    <div className="outer-circle ">
                      <div className="inner-circle "></div>
                    </div>
                    <div className="dashed brd"></div>
                  </div>
                  <span className="active">Order Place</span>
                  <p className="active">Tuesday, 30-May-2022</p>
                </div>
              </div> */}
            </div>
          </div>
      </Modal>
)
}

export default TrackItemModal;