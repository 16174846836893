import { useState } from "react";
import { commonServices } from "../../Utility/common.services";
import "./_common.scss";
import { useNavigate } from "react-router-dom";

export const commonComponets = {
  DealCard,
  CatergoryCard,
  SetOposite,
  SetTrueFalse,
  PointBox,
  SingleDropDown,
  WomenCard,
  ProductCard,
  HotDeals,
  SingleDropDownComponent
};
function DealCard(deal) {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  const openDealsDetails = () => {
    navigate("/dealdetails");
  };

  return (
    // <div className="dealCard_wrap" onClick={() => openDealsDetails()}>
    //   <img src={images["topD.svg"]} alt="images" />

    //   <div className="deal-details">
    //     <h6>{deal?.stockItemTitle}</h6>
    //     <span className="">Get Rs.125 off on a minimum order of Rs.249 .</span>
    //   </div>
    // </div>

    <div className="productcard_wrap" onClick={() => openDealsDetails()}>
      <div className="img-container">
        <img alt="" src={images["topD_01.png"]} />
      </div>
      <div className="product_details">
        <h4>Get Rs.125 off on a minimum order of Rs.249 .</h4>
      </div>
    </div>
  );
}
function CatergoryCard() {
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <div className="productcard_wrap">
      <div className="img-container">
        <img alt="" src={images["electronics.png"]} />
      </div>
      <div className="product_details">
        <h4>Radisson</h4>
      </div>
    </div>
    // <div className="categoryCard_wrap">
    //   <img alt="" src={images["electronics.png"]} />
    //   <div className="category-details">
    //     <span>Health & Beauty</span>
    //     <div className="viewOffer">
    //       <img alt="" src={images[""]} />
    //       <span>View Offer</span>
    //     </div>
    //   </div>
    // </div>
  );
}
function PointBox(points) {
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <div className="point_box_common">
      <img src={images["star.svg"]} alt="star" />
      <div className="span_nm">{points} Pts</div>
    </div>
  );
}

function SingleDropDown(name, options, selectText) {
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [showulbbox, setshowulbbox] = useState(false);
  const [selectOption, setSelectOption] = useState(selectText);

  const handleulBox = () => {
    setshowulbbox(!showulbbox);
  };

  const handleSelect = (ele, key, val) => {
    setSelectOption(ele.name);
    setshowulbbox(false);
  };

  return (
    <div className="dropdownBox">
      <label>{name}</label>
      <div className="span-img" onClick={handleulBox}>
        <span className="input-box">{selectOption}</span>
        <img alt="" src={images["downArrow.svg"]} />
      </div>
      {showulbbox ? (
        <div
          className={name === "Reason" ? "ul-li-drp show_scroll" : "ul-li-drp"}
        >
          <ul>
            {options?.map((ele, index) => {
              return (
                <li key={index} onClick={() => handleSelect(ele)}>
                  {ele.name}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}

function SingleDropDownComponent(name, options, setSelectOption,selectOption) {
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [showulbbox, setshowulbbox] = useState(false);

  const handleulBox = () => {
    setshowulbbox(!showulbbox);
  };

  const handleSelect = (ele, key, val) => {
    setSelectOption(ele);
    setshowulbbox(false);
  };

  return (
    <div className="dropdownBox">
      <label>{name}</label>
      <div className="span-img" onClick={handleulBox}>
        <span className="input-box">{selectOption?.name ?? 'Select Query'}</span>
        <img alt="" src={images["downArrow.svg"]} />
      </div>
      {showulbbox ? (
        <div
          className={name === true ? "ul-li-drp show_scroll" : "ul-li-drp"}
        >
          <ul>
            {options?.map((ele, index) => {
              return (
                <li key={index} value={ele?.id} onClick={() => handleSelect(ele)}>
                  {ele.name}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}

function SetOposite(Val) {
  return !Val;
}
function SetTrueFalse(Val) {
  return Val;
}

function WomenCard() {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  const openWomenCard = () => {
    navigate("/womenwear");
  };

  return (
    // <div className="dealCard_wrap" onClick={() => openWomenCard()}></div>
    <div className="productcard_wrap" onClick={() => openWomenCard()}>
      <div className="img-container">
        <img alt="" src={images["womenwear.svg"]} />
      </div>
      <div className="product_details">
        <h4>H&M</h4>
      </div>
    </div>
  );
}

function ProductCard() {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  const openProductDetails = () => {
    navigate("/productdetails");
  };

  return (
    <div className="top-swiper-container" onClick={() => openProductDetails()}>
      <div className="img-container"><img src={images["topD_01.png"]} alt="images" /></div>
      <div className="product_details">
        <h6>
          Twenty Dresses
          {/* <span className="discount">-10% Discount</span> */}
        </h6>
        <h5>Blue Floral</h5>
        <span className="with_discount">
          ₹ 1,799 <span>/ 1799 pts.</span>
        </span>

        {/* -<span className="without_discount"> ₹ 24,99</span> */}
      </div>
    </div>
  );
}

function HotDeals() {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [showIsDeals, setShowIsDeals] = useState(false);

  const showDeals = () => {
    //setShowIsDeals(!showIsDeals);
    navigate("/alldeals");
  };

  return (
    <div className="hot_selling_deals" onClick={() => showDeals()}>
      <div className="deal-details">
        <h6>Deals Under Rs.999</h6>
      </div>
      <div className="deal-description">
        <span>Levi's, Woodland, Flying Machine</span>
      </div>
    </div>
  );
}
