import { commonServices } from './common.services';
import { config } from './config';

export const homeService = {
    GetPageSections,
    GetRegistrationDetail,
    SendJiraTicketRequest,
    TrackJiraTicket,
    CreateAndSendJiraTicket
}

async function GetPageSections(pagename) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode
        },

    }

    return await fetch(config.apiUrl + 'Home/GetPageSections?pagename='+pagename+ "&program=" + config.programCode, requestOptions)
        .then((res) => {
            return res.json().then(res);
            //return commonServices.ApiResponseHandler(res);
        });
}

async function GetRegistrationDetail(code) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "code": code
          })
    }

    return await fetch(config.apiUrl + 'Home/GetRegistrationDetail', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function SendJiraTicketRequest(name,email,oderid,reason,comment) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "name": name,
            "email": email,
            "message": "",
            "summery": reason,
            "description": comment,
            "orderid": oderid
          })
    }

    return await fetch(config.apiUrl + 'Home/SendJiraTicketRequest', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function TrackJiraTicket(id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "ticketId": id
          })
    }

    return await fetch(config.apiUrl + 'Home/TrackJiraTicket', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function CreateAndSendJiraTicket() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "name": "string",
            "email": "string",
            "message": "string",
            "subject": "string"
          })
    }

    return await fetch(config.apiUrl + 'Home/CreateAndSendJiraTicket', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}