import "../Dashboard/_dashboard.scss";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../../Utility/common.services";

function AboutUs(props) {
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);  
  }, []);
  
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="main-div">
      <div className="swp-banner">
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <img alt="" src={images["FAQs.png"]} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="cancellationReturn_container container">
        <h4>About The Reward Company</h4>
        <p>
          At <strong>The Reward Company (TRC)</strong>, we believe in more than just rewards—we believe in the power of meaningful recognition. We empower businesses to motivate, inspire, and engage their employees, channel partners, and customers by offering a wide array of personalized rewards that make a lasting impact.
          With TRC, businesses can seamlessly deliver rewards that truly resonate, ensuring every act of recognition turns into a moment that fosters growth and loyalty.
        </p>
        <h4>Our Vision & Mission</h4>
        <h3>Vision:</h3>
        <p>
        To be the ultimate destination for businesses that aim to elevate their reward offerings, transforming them into impactful experiences that build stronger relationships and drive performance. 
        </p>
        <h3>Mission: </h3>
        <p>
        We strive to offer businesses a platform that simplifies the reward experience, providing endless options that cater to the unique needs of every individual. From everyday essentials to luxury experiences, TheRewardCo makes reward redemption exciting, effortless, and deeply personal. 
        </p>
        <h3>Our Legacy </h3>
        <p>
        TheRewardCo is a proud product of Easyrewardz, a leader in loyalty and reward solutions with over a decade of experience across industries such as BFSI, Retail, B2B, Healthcare, Hospitality and more. Our deep understanding of loyalty management has enabled us to craft The Reward Company as a versatile platform that meets the evolving needs of today’s dynamic business environments. 
        </p>
        <p>
        With a proven track record of success and trusted by top-tier clients, we are committed to helping businesses unlock the true potential of rewards. 
        </p>
      </div>
    </div>
  );
}


export default AboutUs;
