import React,{useState} from 'react'
import { commonServices } from "../../../Utility/common.services";
import { commonComponets } from "../../Common/commonComponent";
import { Utility } from "../../../Utility/utility.service";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";


const EditProfileModal = (props) => {
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
    const {editModal,handleEditModal,handleEditProfileDetails}=props
    const [customerProfileData,setCustomerProfileData]=useState(props.customerDetails)

    const handleSubmit = (e) =>{
        e.preventDefault();
         if(!validateEmail(customerProfileData?.email)){
          toast.error("Invalid  email address")
          return
        }
        handleEditProfileDetails(customerProfileData)
    }

    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    const changedProfileDetails = (e, key) => {
      try {
        const value = e.target.value;
  
        setCustomerProfileData((prevDetails) => {
          let updatedDetails = { ...prevDetails };
  
          switch (key) {
  
            case "firstname":
              const firstNameLimit = 20;
              let firstNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
              let firstName = value.slice(0, firstNameLimit);
              if (!firstName || firstName === "") {
                toast.error("Please enter your first name.");
                
              }  
                updatedDetails.firstName = firstName.match(firstNameRe)
                  ? Utility.htmlEncode(firstName)
                  : "";
              
              break;
  
            case "lastname":
              const lastNameLimit = 20;
              let lastNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
              let lastName = value.slice(0, lastNameLimit);
              if (!lastName || lastName === "") {
                toast.error("Please enter your last name.");
                
              }  
                updatedDetails.lastName = lastName.match(lastNameRe)
                  ? Utility.htmlEncode(lastName)
                  : "";
              
              break;
  
            case "email":
              const emailLimit = 50;
              let email = value.slice(0, emailLimit).replace(/\s+/g, "");
          
              if (
                !email ||
                email === ""
              ) {
                toast.error("Please enter your email id.");
                
              }  
                updatedDetails.email = Utility.htmlEncode(email);
              
              break;
  
            case "mobile":
              const mobileLimit = 10;
              let mobileRe = /^\d*$/;
              let mobile = value.slice(0, mobileLimit);
              updatedDetails.mobileNo = mobile.match(mobileRe)
                ? Utility.htmlEncode(mobile)
                : "";
              break;
  
            default:
              break;
          }
  
          return updatedDetails;
        });
      } catch (ex) {
        console.log(ex);
      }
    };

  return (
    <div>
     <Modal
        className="editModal_wrap"
        show={editModal}
        onHide={handleEditModal}
        centered
      >
        <div className='d-flex justify-content-end'>
        <div onClick={handleEditModal} className="exit">
          <img
            src={images["cross_icon.svg"]}
            alt="cross"
            className="cross"
          />
          </div>
        </div>
        <Modal.Body>
          <div className="edit_wrap">
            <h4>Profile Details :</h4>
            <div className="row ">
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                    onChange={(e) => changedProfileDetails(e, "firstname")}
                    value={customerProfileData.firstName}
                  />
                  <label className="form__label">First Name</label>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                    onChange={(e) => changedProfileDetails(e, "lastname")}
                    value={customerProfileData.lastName}
                  />
                  <label className="form__label">Last Name</label>
                </div>
              </div>
              {/* <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Phone Number</label>
                </div>
              </div> */}
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                    onChange={(e) => changedProfileDetails(e, "email")}
                    value={customerProfileData.email}
                  />
                  <label className="form__label">Email Address</label>
                  {/* <img src={images[""] } alt="name"/> */}
                </div>
              </div>
              {/* <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Gender</label>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Birthday</label>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Marital status</label>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="with_label_input">
                  <input
                    id="txt"
                    type="text"
                    name="text_test?"
                    className="form__input"
                    autocomplete="off"
                    placeholder=" "
                  />
                  <label className="form__label">Anniversary</label>
                </div>
              </div> */}
            </div>

            <div className="btn-cancel-order mt-5">
                            <button onClick={handleEditModal}>Cancel</button>
                            <button className={`cancelbtn ${ !customerProfileData?.firstName || !customerProfileData?.lastName ||
                !customerProfileData?.email  ? 'disabled':''}`} onClick={handleSubmit}>
                                Add
                            </button>
                        </div>
          </div>
          <ToastContainer/>
        </Modal.Body>
      </Modal> 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customerDetails: state.customerDetails,
  };
};

export default connect(mapStateToProps)(EditProfileModal);