const programCodeMappings = {
    //prod
    "thereward.co": "trc",
    "easyrewardz.thereward.co": "er-trc",

    //staging
    "erreportingdemo-trc.erstaging.com": "erreportingdemo"
};

const programConfig = {
    trc: "https://thereward.co/api/",
    "er-trc": "https://easyrewardz.thereward.co/api/",
    erreportingdemo: "https://erreportingdemo-trc.erstaging.com/api/"
};

function getProgramCode(url) {
    const domain = url?.split("//")[1]?.split("/")[0];
    return programCodeMappings[domain] || "erreportingdemo"; // Default program code
}

function getApiUrl(program) {
    return programConfig[program] || programConfig["erreportingdemo"]; // Default API URL
}

const programCode = getProgramCode(window.location.origin);
// const programCode = getProgramCode("https://erreportingdemo-trc.erstaging.com");
const apiUrl = getApiUrl(programCode);

export const config = {
    programCode: programCode,
    apiUrl: apiUrl
};

