import { commonServices } from "../../Utility/common.services";

function PaymentPage(){
    const images = commonServices.importImage(
        require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
      );

    return(
        <div className="container p-5">
            <h4>Payment Page</h4>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout.</p>
                <img alt="" src={images["loading_logo.svg"]} />
        </div>
    )
}

export default PaymentPage;