import React, { useEffect, useState } from "react";
import { commonServices } from "../../../Utility/common.services";
import "../_shop.scss";
import { Utility } from "../../../Utility/utility.service";
import { addressService } from "../../../Utility/address.service";
import { Icons, ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { redisService } from "../../../Utility/redis.service";
import { cartService } from "../../../Utility/cart.service";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Login from "../../Login/Login";
import DeleteModal from "../../Common/DeleteModal";

function AddressDetails(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [customerAddresDetails, setCustomerAddressDetails] = useState({
    AddressType: "Home",
    termCondition: false,
  });
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [cartDetailsData, setCartDetailsData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [addressDetails, setAddressDetails] = useState([]);
  const [showAddress, setShowAddressPage] = useState(false);

  useEffect(() => {
    getShopStates();
    //getShopCities();
    fetchAddressesByUser();
    getCart();
  }, []);

  const getShopStates = async () => {
    setLoader(true);
    try {
      let response = await redisService.GetShopStates();
      if (response?.status?.code === 200) {
        setStates(response?.data);
      } else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const getShopCities = async (stateId) => {
    setLoader(true);
    setLoadingPrefixText("Fetching Cities");
    try {
      let response = await redisService.GetShopCities(stateId);
      if (response?.status?.code === 200) {
        setCities(response?.data);
      } else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const fetchAddressesByUser = async () => {
    setLoader(true);
    //setLoadingPrefixText("Fetching your address details!");
    try {
      let response = await addressService.FetchAddressesByUser();
      if (response?.status?.code === 200) {
        if (response?.data?.length > 0) {
          setAddressDetails(response.data);
          setShowAddressPage(false);
          setAddNewAddress(false);
        } else {
          setShowAddressPage(true);
          setAddNewAddress(true);
          setAddressDetails([]);
        }
      } else if (response === 401) {
        props.setCurrentPage(window.location.pathname)
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setShowLoginForm(true);
      } else {
        setShowAddressPage(true);
        setAddressDetails([]);
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setShowAddressPage(true);
      setAddressDetails([]);
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const getCart = async () => {
    setLoader(true);
    try {
      let res = await cartService.GetCart();
      if (res?.status?.code === 200) {
        setCartDetailsData(res?.data);
        props.setCartDetailsData(res?.data);
      } else {
        setCartDetailsData([]);
        props.setCartDetailsData([]);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setCartDetailsData([]);
      props.setCartDetailsData([]);
      console.log("Error in getCart", e);
    }
  };

  const fetchCustomerAddress = (e, key) => {
    try {
      const value = e.target.value;

      setCustomerAddressDetails((prevDetails) => {
        let updatedDetails = { ...prevDetails };

        switch (key) {
          case "addressType":
            updatedDetails.AddressType = value;
            break;

          case "firstname":
            const firstNameLimit = 20;
            let firstNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
            let firstName = value.slice(0, firstNameLimit);
            updatedDetails.firstName = firstName.match(firstNameRe)
              ? Utility.htmlEncode(firstName)
              : "";
            break;

          case "lastname":
            const lastNameLimit = 20;
            let lastNameRe = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
            let lastName = value.slice(0, lastNameLimit);
            updatedDetails.lastName = lastName.match(lastNameRe)
              ? Utility.htmlEncode(lastName)
              : "";
            break;

          case "email":
            const emailLimit = 50;
            let email = value.slice(0, emailLimit).replace(/\s+/g, "");
            updatedDetails.emailID = Utility.htmlEncode(email);
            updatedDetails.invalidEmail = !validateEmail(email);
            break;

          case "mobile":
            const mobileLimit = 10;
            let mobileRe = /^\d*$/;
            let mobile = value.slice(0, mobileLimit);
            updatedDetails.mobileNo = mobile.match(mobileRe)
              ? Utility.htmlEncode(mobile)
              : "";
            break;

          case "address":
            const addressLimit = 100;
            let address = value.slice(0, addressLimit);
            updatedDetails.Address = Utility.htmlEncode(address);
            break;

          case "pinCode":
            const pinCodeLimit = 6;
            let pinCodeRe = /^\d*$/;
            let pinCode = value.slice(0, pinCodeLimit);
            updatedDetails.pinCode = pinCode.match(pinCodeRe)
              ? Utility.htmlEncode(pinCode)
              : "";
            break;

          case "locality":
            const localityLimit = 20;
            let locality = value.slice(0, localityLimit);
            updatedDetails.locality = Utility.htmlEncode(locality);
            break;

          case "city":
            if (value !== "" && value !== "Select City") {
              updatedDetails.city = value;
            }
            break;

          case "state":
            // let stateName = value.split("_")[0];
            // let stateId = value.split("_")[1];
            if (value !== "" && value !== "Select State") {
              updatedDetails.state = value;
              updatedDetails.city = ""; // Clear city when state changes
              getShopCities(value.split("_")[1]);
            }
            break;

          case "termCondition":
            checkCustomerProductAddress();
            break;

          default:
            break;
        }

        return updatedDetails;
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  const checkCustomerProductAddress = () => {
    debugger
    setCustomerAddressDetails((prevDetails) => {
      let updatedDetails = { ...prevDetails };

      if (!updatedDetails.firstName || updatedDetails.firstName === "") {
        updatedDetails.invalidFname = true;
        toast.error("Please enter your first name.");
      } else {
        updatedDetails.invalidFname = false;
      }

      if (!updatedDetails.lastName || updatedDetails.lastName === "") {
        updatedDetails.invalidLname = true;
        toast.error("Please enter your last name.");
      } else {
        updatedDetails.invalidLname = false;
      }
      if (
        !updatedDetails.emailID ||
        updatedDetails.emailID === "" ||
        !validateEmail(updatedDetails.emailID)
      ) {
        updatedDetails.invalidEmail = true;
        toast.error(
          updatedDetails.emailID === "" ||
          !updatedDetails.emailID
            ? "Please enter your email id."
            : "Invalid e-mail address"
        );
      } else {
        updatedDetails.invalidEmail = false;
      }

      if (
        !updatedDetails.mobileNo ||
        updatedDetails.mobileNo === "" ||
        updatedDetails.mobileNo.length < 10
      ) {
        updatedDetails.invalidMobile = true;
        toast.error("Please enter your mobile number.");
      } else {
        updatedDetails.invalidMobile = false;
      }

      if (!updatedDetails.Address || updatedDetails.Address === "") {
        updatedDetails.invalidAddress = true;
        toast.error("Please enter your address.");
      } else {
        updatedDetails.invalidAddress = false;
      }

      if (!updatedDetails.city || updatedDetails.city === "") {
        updatedDetails.invalidCity = true;
        toast.error("Please select your city.");
      } else {
        updatedDetails.invalidCity = false;
      }

      if (!updatedDetails.state || updatedDetails.state === "") {
        updatedDetails.invalidState = true;
        toast.error("Please select your state.");
      } else {
        updatedDetails.invalidState = false;
      }

      if (
        !updatedDetails.pinCode ||
        updatedDetails.pinCode === "" ||
        updatedDetails.pinCode.length !== 6
      ) {
        updatedDetails.invalidPincode = true;
        toast.error("Please enter your pincode.");
      } else {
        updatedDetails.invalidPincode = false;
      }

      if (
        updatedDetails.firstName !== "" &&
        updatedDetails.lastName !== "" &&
        updatedDetails.emailID !== "" &&
        updatedDetails.mobileNo !== "" &&
        updatedDetails.pinCode !== "" &&
        updatedDetails.pinCode.length === 6 &&
        updatedDetails.city !== "" &&
        updatedDetails.state !== "" &&
        // updatedDetails.Address !== "" &&
        updatedDetails.invalidEmail !== true
      ) {
        updatedDetails.termCondition = !updatedDetails.termCondition;
      } else {
        toast.error(
          updatedDetails.invalidEmail
            ? "Invalid email address"
            : "Please enter your details to proceed further!"
        );
      }

      return updatedDetails;
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleAddress = () => {
    if (openEditAddress) {
      editAddressHandler();
    } else {
      saveAddress();
    }
  };

  const saveAddress = async () => {
    setLoader(true);
    // setLoadingPrefixText("Adding your address details!");
    try {
      let response = await addressService.SaveAddress(customerAddresDetails);
      if (response?.status?.code === 200) {
        fetchAddressesByUser();
      } else if (response === 401) {
        props.setCurrentPage(window.location.pathname)
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setShowLoginForm(true);
      } 
      else if (response?.status.code === 400){
        checkCustomerProductAddress();
      }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const goToDeliveryAddress = () => {
    navigate("/deliverydetails");
  };

  const addNewAddressHandler = () => {
    setAddNewAddress(true);
    setShowAddressPage(true);
    setOpenEditAddress(false);
    setCustomerAddressDetails({
      firstName: "",
      lastName: "",
      emailID: "",
      mobileNo: "",
      AddressType: "Home",
      Address: "",
      pinCode: "",
      locality: "",
      country: "India",
      city: "",
      state: "",
      address_id: "",
      is_default: false,
      termCondition: false,
    });
  };

  const openDeleteModal = async (e, address_id) => {
    setShowDeleteModal(!showDeleteModal);
    await setAddressId(address_id);
  };

  const setDefaultAddress = (e, data) => {
    let detail = customerAddresDetails;
    let str = "";
    let arr = [];
    str = data.receiver_name;
    arr = str.split(/ (.*)/);
    detail.firstName =
      arr.length && arr[0] !== undefined && arr[0] !== null ? arr[0] : "";
    detail.lastName =
      arr.length && arr[1] !== undefined && arr[1] !== null ? arr[1] : "";
    detail.emailID = data.email;
    detail.mobileNo = data.mobile;
    detail.AddressType = data.addressType !== "" ? data.addressType : "Home";
    detail.Address = data.address;
    detail.pinCode = data.pin_code;
    detail.country = data.country;
    detail.state = data.state;
    detail.city = data.city;
    detail.address_id = data.address_id;
    detail.is_default = true;
    detail.termCondition = false;
    detail.locality = data.locality;
    setCustomerAddressDetails(detail);

    editAddressHandler();
  };

  const editAddressHandler = async () => {
    try {
      setLoader(true);
      if (
        customerAddresDetails.firstName !== "" &&
        customerAddresDetails.lastName !== "" &&
        customerAddresDetails.emailID !== "" &&
        customerAddresDetails.mobileNo !== "" &&
        customerAddresDetails.pinCode !== "" &&
        customerAddresDetails.pinCode.length === 6 &&
        customerAddresDetails.city !== "" &&
        customerAddresDetails.state !== "" &&
        customerAddresDetails.Address !== "" &&
        customerAddresDetails.invalidEmail !== true
      ) {
        if (
          customerAddresDetails.pinCode.length === 6 &&
          validateEmail(customerAddresDetails.emailID)
        ) {
          let response = await addressService.UpdateAddress(
            customerAddresDetails
          );
          if (response !== null) {
            if (response.status.code === 200) {
              setLoader(false);
              setOpenEditAddress(false);
              setAddNewAddress(false);
              fetchAddressesByUser();
            } else {
              setLoader(false);
              toast.error(
                response.error.message !== ""
                  ? response.error.message
                  : "Something went wrong !!"
              );
            }
          } else {
            setLoader(false);
            toast.error(
              response.error.message !== ""
                ? response.error.message
                : "Something went wrong !!"
            );
          }
        } else {
          checkCustomerProductAddress();
          setLoader(false);
          toast.error(
            !validateEmail(customerAddresDetails.emailID)
              ? "Invalid e-mail address"
              : "Please enter your details to procced further."
          );
        }
      } else {
        setLoader(false);
        checkCustomerProductAddress();
      }
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !!");
    }
  };

  const shippingAddress = async (ele) => {
    setLoader(true);
    // setLoadingPrefixText("Adding your address details!");
    try {
      let response = await addressService.ShippingAddress(ele);
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const selectedDeliveryAddress = async (e, ele) => {
    try {
      shippingAddress(ele);
      setSelectedAddress(ele.address_id);
      // var Details = this.props.deliveryPageDetails;
      //     Details.customerDetails.recipientData.firstName = ele.receiver_name?.split(" ")[0];
      //     Details.customerDetails.recipientData.lastName = ele.receiver_name?.split(" ")[1];
      //     Details.customerDetails.recipientData.email = ele.email;
      //     Details.customerDetails.recipientData.mobile = ele.mobile;
      //     this.props.getDeliveryDetails(Details);
      // this.props.setdeliveryAddress(ele)
      // this.setState({
      //     selectedAddress: ele.address_id
      // })
    } catch (ex) {
      setSelectedAddress("");
    }
  };

  const openEditHanler = (e, data) => {
    let detail = customerAddresDetails;
    let str = "";
    let arr = [];
    let state = states.find((ele) => ele?.stateName === data?.state);
    state = state?.stateName + "_" + state?.stateId;
    str = data.receiver_name;
    arr = str.split(/ (.*)/);
    detail.firstName = arr.length ? arr[0] : "";
    detail.lastName = arr.length ? arr[1] : "";
    detail.emailID = data.email;
    detail.mobileNo = data.mobile;
    detail.AddressType = data.addressType !== "" ? data.addressType : "Other";
    detail.Address = data.address;
    detail.pinCode = data.pin_code;
    detail.country = data.country;
    detail.state = state;
    detail.city = data.city;
    detail.address_id = data.address_id;
    detail.is_default = data.is_default;
    detail.locality = data.locality;
    detail.termCondition = true;
    detail.locality = data.locality;
    setCustomerAddressDetails(detail);
    setAddNewAddress(false);
    setOpenEditAddress(true);
    setShowAddressPage(true);
  };

  const deleteAddressHandler = async (e) => {
    setLoader(true);
    try {
      let response = await addressService.DeleteAddress(addressId);
      if (response !== null) {
        if (response.status.code === 200) {
          setLoader(false);
          setShowDeleteModal(false);
          await fetchAddressesByUser();
        } else {
          setLoader(false);
          toast.error(
            response.error[0].message !== ""
              ? response.error[0].message
              : "Something went wrong !!"
          );
        }
      } else {
        setLoader(false);
        toast.error(
          response.error[0].message !== ""
            ? response.error[0].message
            : "Something went wrong !!"
        );
      }
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !!");
    }
  };

  const handleCancel = async () => {
    setShowAddressPage(false);
  };

  return (
    <div className="main-container container_grey">
      {loader && (
        <div className="loader-overlay flight_loader">
          <div className="boot-strap-loader">
            <div className="spinner-border text-warning" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
          <p>
            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
          </p>
        </div>
      )}
      <div className="container">
        <div className="content-box-address">
          <h4>{!showAddress ? "Shipping Address" : "Address Details"}</h4>
          <div className="row justify-content-between">
            {!showAddress ? (
              <div className="product_details">
                {addressDetails !== undefined &&
                addressDetails !== null &&
                addressDetails?.length ? (
                  <ul className="buy_self_title address-list">
                    {/* <h4 className="mb-3">Shipping Address</h4> */}
                    {addressDetails.map((ele, i) => {
                      return (
                        <li className="w-100" key={i}>
                          <div className="address-name-details w-75">
                            <label htmlFor="name" className="space p-0">
                              <input
                                type="radio"
                                id="address"
                                name="address"
                                value="name"
                                className="mx-2"
                                checked={
                                  selectedAddress === ""
                                    ? ele.is_default
                                      ? true
                                      : false
                                    : selectedAddress === ele.address_id
                                    ? true
                                    : false
                                }
                                onClick={(e) => selectedDeliveryAddress(e, ele)}
                              />
                              {ele.receiver_name}
                              <span className="add-type">
                                {ele.addressType}
                              </span>{" "}
                              {ele.mobile}
                            </label>
                            <p className="pl-4 mt-2 mx-2">
                              {ele.address}, {ele.locality}, {ele.city},{" "}
                              {ele.state}, {ele.pin_code}
                            </p>
                          </div>
                          <div className="action-btn w-25">
                            <div className="btn-grp ">
                              <button className="btn mb-2">
                                <img
                                  src={images["editIcon.svg"]}
                                  alt="logo"
                                  className=""
                                  onClick={(e) => openEditHanler(e, ele)}
                                />
                              </button>
                              {!ele.is_default && (
                                <button className="btn mb-2">
                                  <img
                                    src={images["deleteIcon.svg"]}
                                    alt="logo"
                                    className=""
                                    onClick={(e) =>{
                                      setShowDeleteModal(!showDeleteModal);
                                      setAddressId(ele.address_id);
                                    }
                                    }
                                  />
                                </button>
                              )}
                              {ele.is_default ? (
                                <button className="btn btn-default-set active">
                                  Default Address
                                </button>
                              ) : (
                                <button
                                  className="btn btn-default-set w-100"
                                  onClick={(e) => setDefaultAddress(e, ele)}
                                >
                                  Set as a Default Address
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <li className="addNew-address">
                      <p onClick={() => addNewAddressHandler()}>
                        Change Address
                      </p>
                    </li>
                  </ul>
                ) : null}
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Product Details</th>
                      <th>Quantity</th>
                      <th></th>
                      <th>Offer Price</th>
                    </tr>
                  </thead>
                  {cartDetailsData?.basket_items?.length > 0 &&
                    cartDetailsData?.basket_items?.map((ele, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <div className="product_img">
                                <img
                                  alt=""
                                  src={commonServices.imageByDimensions(
                                    ele?.stock_item_image[0],
                                    "20by20"
                                  )}
                                />
                                <div className="product_title">
                                  <h4>{ele.brandName}</h4>
                                  <p className="m-0">{ele?.stock_item_title}</p>
                                  {parseInt(
                                    cartDetailsData?.price_summary
                                      ?.shipping_charges
                                  ) > 0 ? (
                                    <p>
                                      {"+₹ " +
                                        cartDetailsData?.price_summary
                                          ?.shipping_charges}
                                    </p>
                                  ) : (
                                    <p style={{ color: "green" }}>
                                      Free Shipping
                                    </p>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>{ele?.quantity}</td>
                            <td></td>
                            <td>₹ {ele?.price}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
              </div>
            ) : (
              <div className="col-8 mt-4 address_container p-4">
                <div className="d-flex justify-content-between">
                  <h4 className="smlh">Shipping Address</h4>
                  {/* <span>
                    <span style={{ color: "red" }}>*</span>Mandatory Details
                  </span> */}
                </div>
                <div className="addressDetails">
                  <div className="address-check-box">
                    <div className="check-box-div">
                      <div className="chck-wrap">
                        <input
                          name="select"
                          type="radio"
                          id="address"
                          value="Home"
                          checked={
                            customerAddresDetails?.AddressType === "Home"
                          }
                          onChange={(e) =>
                            fetchCustomerAddress(e, "addressType")
                          }
                        />
                        <label className="activecls">Home</label>
                      </div>
                      <div className="chck-wrap">
                        <input
                          name="select"
                          type="radio"
                          id="address"
                          value="Office"
                          checked={
                            customerAddresDetails?.AddressType === "Office"
                          }
                          onChange={(e) =>
                            fetchCustomerAddress(e, "addressType")
                          }
                        />
                        <label>Office</label>
                      </div>
                      <div className="chck-wrap">
                        <input
                          name="select"
                          type="radio"
                          id="address"
                          value="Other"
                          checked={
                            customerAddresDetails?.AddressType === "Other"
                          }
                          onChange={(e) =>
                            fetchCustomerAddress(e, "addressType")
                          }
                        />
                        <label>Other</label>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="First Name"
                            className={
                              customerAddresDetails.invalidFname
                                ? "input-area invalid"
                                : "input-area "
                            }
                          required
                          id="firstname12"
                          maxLength={20}
                          value={customerAddresDetails.firstName}
                          onChange={(e) => fetchCustomerAddress(e, "firstname")}
                        />
                        <label htmlFor="firstname12" className="label">
                          First Name<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Last Name"
                          className={
                            customerAddresDetails.invalidLname
                              ? "input-area invalid"
                              : "input-area "
                          }
                          required
                          id="lastname22"
                          maxLength={20}
                          value={customerAddresDetails.lastName}
                          onChange={(e) => fetchCustomerAddress(e, "lastname")}
                        />
                        <label htmlFor="lastname22" className="label">
                          Last Name
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="email"
                          placeholder="Email ID"
                          className={
                            customerAddresDetails.invalidEmail
                              ? "input-area invalid"
                              : "input-area "
                          }
                          required
                          id="email12"
                          maxLength={50}
                          value={customerAddresDetails.emailID}
                          onChange={(e) => fetchCustomerAddress(e, "email")}
                        />
                        <label htmlFor="email12" className="label">
                          Email ID<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Mobile Number"
                          className={
                            customerAddresDetails.invalidMobile
                              ? "input-area invalid"
                              : "input-area "
                          }
                          required
                          id="mobilenumber"
                          maxLength={"10"}
                          value={customerAddresDetails.mobileNo}
                          onChange={(e) => fetchCustomerAddress(e, "mobile")}
                        />
                        <label htmlFor="mobilenumber" className="label">
                          Mobile Number<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Pincode"
                          className={
                            customerAddresDetails.invalidPincode
                              ? "input-area invalid"
                              : "input-area "
                          }
                          required
                          id="Pincode2"
                          maxLength={6}
                          onChange={(e) => fetchCustomerAddress(e, "pinCode")}
                          value={customerAddresDetails.pinCode}
                        />
                        <label htmlFor="Pincode" className="label">
                          Pincode<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-4 form-group">
                      <div className="form-floating">
                        <select
                          className={
                            customerAddresDetails.invalidState
                              ? "form-select invalid"
                              : "form-select"
                          }
                          required
                          id="State2"
                          onChange={(e) => fetchCustomerAddress(e, "state")}
                          value={customerAddresDetails.state}
                        >
                          <option value="">Select State</option>
                          {states.length > 0 ? (
                            states
                              .sort(function (a, b) {
                                if (
                                  a.stateName.toLowerCase() <
                                  b.stateName.toLowerCase()
                                )
                                  return -1;
                                if (
                                  a.stateName.toLowerCase() >
                                  b.stateName.toLowerCase()
                                )
                                  return 1;
                                return 0;
                              })
                              .map((ele, i) => {
                                return (
                                  <>
                                    <option
                                      key={i}
                                      value={ele.stateName + "_" + ele.stateId}
                                    >
                                      {ele.stateName}
                                    </option>
                                  </>
                                );
                              })
                          ) : (
                            <option>No States Found</option>
                          )}
                        </select>
                        <label htmlFor="State2" className="label">
                          State<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-8">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Address (House No, Street, Area)"
                          className={
                            // customerAddresDetails.invalidAddress
                            //   ? "input-area invalid"
                             // :
                               "input-area"
                          }
                          required
                          id="lastname2"
                          maxLength={100}
                          onChange={(e) => fetchCustomerAddress(e, "address")}
                          value={customerAddresDetails.Address}
                        />
                        <label htmlFor="lastname" className="label">
                          Address (House No, Street, Area)
                          <span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Locality / Town"
                          className="input-area "
                          maxLength={20}
                          required
                          id="Locality/Town2"
                          onChange={(e) => fetchCustomerAddress(e, "locality")}
                          value={customerAddresDetails.locality}
                        />
                        <label htmlFor="Locality / Town" className="label">
                          Locality / Town
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 form-group">
                      <div className="form-floating">
                        <select
                          className={
                            customerAddresDetails.invalidCity
                              ? "form-select invalid"
                              : "form-select"
                          }
                          id="city"
                          value={customerAddresDetails.city}
                          onChange={(e) => fetchCustomerAddress(e, "city")}
                          placeholder="City / District"
                        >
                          <option value="">Select City</option>
                          {cities.length > 0 ? (
                            cities
                              .sort(function (a, b) {
                                if (
                                  a.cityName.toLowerCase() <
                                  b.cityName.toLowerCase()
                                )
                                  return -1;
                                if (
                                  a.cityName.toLowerCase() >
                                  b.cityName.toLowerCase()
                                )
                                  return 1;
                                return 0;
                              })
                              .map((ele, i) => {
                                return (
                                  <>
                                    <option key={i}>{ele.cityName}</option>
                                  </>
                                );
                              })
                          ) : (
                            <option>
                              {" "}
                              {customerAddresDetails.city === ""
                                ? "Please Select State First"
                                : customerAddresDetails.city}
                            </option>
                          )}
                        </select>
                        <label htmlFor="District" className="label">
                          City/District<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 mt-4">
                    <div className="col-6 check-box">
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        checked={
                          customerAddresDetails.termCondition === true
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          fetchCustomerAddress(e, "termCondition")
                        }
                      />
                      <label htmlFor="terms">
                        I agree with <a href="#"> terms and conditions</a>
                      </label>
                    </div>
                    {addressDetails?.length > 0 && (
                      <div className="col-6">
                        <button
                          className="cancel_btn"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="summary_container">
              <div className="order_summary p-4">
                <h4>Order Summary</h4>
                <ul>
                  <li>
                    Products
                    <span>
                      {
                        props.cardDetailsData?.price_summary?.price_breakup[0]
                          ?.Quantity
                      }
                    </span>
                  </li>
                  <li>
                    Amount
                    <span>
                      ₹ {props.cardDetailsData?.price_summary?.base_price}
                    </span>
                  </li>
                  <li>
                    Shipping Charges
                    <span>
                      ₹{props.cardDetailsData?.price_summary?.shipping_charges}
                    </span>
                  </li>
                  <li>
                    Total Amount
                    <span>
                      ₹ {props.cardDetailsData?.price_summary?.total_price}
                    </span>
                  </li>
                  <li className="req_pts">
                    Required Points
                    <span>
                      {props.cardDetailsData?.price_summary?.required_points}{" "}
                      pts.
                    </span>
                  </li>

                  <li>
                    Available Points
                    <span>{props?.availablePoints} pts.</span>
                  </li>
                </ul>
                {showAddress ? (
                  <div className="order-btn-div">
                    <button
                      className={
                        customerAddresDetails.termCondition
                          ? "prcd_prcs"
                          : "prcd_prcs disabled_btn"
                      }
                      disabled={!customerAddresDetails.termCondition}
                      onClick={() => handleAddress()}
                    >
                      Continue
                    </button>
                  </div>
                ) : (
                  <div className="order-btn-div">
                    <button
                      className="prcd_prcs"
                      onClick={() => goToDeliveryAddress()}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} setAddressId={setAddressId} handleAddressDelete={deleteAddressHandler} />}
      {/* <Modal
        show={showDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="otp_verification_modal"
      >
        <div className="verification_code">
          <div onClick={(e) => openDeleteModal(e, "")} className="exit">
            <img
              src={"/Assets/Images/cross_icon.svg"}
              alt="cross"
              className="cross"
            />
          </div>
          <div className="verification_img">
            <h3>Are you sure you want to delete?</h3>
          </div>
          <div className="otp_capture">
            <button
              className="verify_otp"
              id="Payment_OTPVerification"
              onClick={(e) => deleteAddressHandler(e, addressId)}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal> */}
      {props.isShowLoginForm && <Login />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowOTPPage: state.isShowOTPPage,
    cardDetailsData: state.cardDetailsData,
    customerDetails: state.customerDetails,
    availablePoints: state.availablePoints
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowOTPPage: (isShow) => {
      dispatch({ type: "IS_SHOW_OTP_PAGE", payload: isShow });
    },
    setOrderId: (isShow) => {
      dispatch({ type: "ORDER_ID", payload: isShow });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
    setCurrentPage: (page) => {
      dispatch({ type: "CURRENT_PAGE", payload: page });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetails);
