import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../../Utility/common.services";

function CancellationReturn(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);  
  }, []);

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="main-div">
      <div className="swp-banner">
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <img alt="" src={images["FAQs.png"]} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="cancellationReturn_container container">
        <h4>Cancellation & Return</h4>
        <h3>Q. What is TRC’s Cancellation Policy?</h3>
        <p>
          A. The Cancellation policy is only applicable for Merchandise orders,
          for vouchers & deals there is no cancellation policy. You have the
          option to request cancellation before the item has been dispatched.
          Once the order is cancelled, your points will be rolled back within
          24hours to your TRC wallet & cash (If used) will be refunded within 7
          days to your actual mode of payment.
        </p>
        <h3>Q. What is TRC’s Return Policy?</h3>
        <p>
          A. TRC’s returns policy gives you an option to return items purchased
          on TRC portal for any reason within the 2 days from the delivery,
          Returns will be picked from the same address where the product is
          delivered. During the reverse Pick Up, our delivery agent will do a
          quality check on the return. For a successful pick up, we will
          initiate the refund of points within 24 Hours to your TRC wallet &
          cash (If used) will be refunded within 7 days to your actual mode of
          payment.
        </p>
        <h3>Exception to Return Request</h3>
        <li>
          Return is not possible if the products is damaged from the customer’s
          end
        </li>
        <li>The product is different from what was shipped to the customer</li>
        <li>
          The product is returned not in original condition (with
          brand’s/manufacturer's box, MRP tag intact, user manual, warranty card
          and all the accessories therein).
        </li>
        <li>Personal care category listed on TRC portal</li>
        <li>Perfumes category listed on TRC portal</li>
        <li>Real, Precious/Semi-Precious Jewellery, Coins & Bullion</li>
        <li>
          For Electronics, items returned due to manufacturing defect will need
          a DOA (Dead on Arrival) Certificate or a Replacement Certificate from
          the authorized brand/service centre.
        </li>
        <li>
          For Electronics, components missing from closed seal box will need
          prior pictures shared before return.
        </li>
        <li>
          Innerwear (except Night Suits and Night Wear) cannot be returned for
          hygienic reasons.
        </li>
        <li>Stuffed/Soft Toys cannot be returned</li>
      </div>
    </div>
  );
}

export default CancellationReturn;
