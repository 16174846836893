import React from "react";
import './_travel.scss';

function Hotels(pros) {
    return(
        <div className="hotels_div">
            HOTELS
        </div>
    )
}
export default Hotels;
