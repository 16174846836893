import Moment from 'moment';

export const commonServices = {
    importImage,
    ApiResponseHandler,
    imageByDimensions,
    FormateDate
}

function importImage(r) {
    let images = {};
    r.keys().forEach(item => { images[item.replace('./', '')] = r(item); });
    return images
}

async function ApiResponseHandler(response) {
    let apiResponse = null;
    let errorRes = {
        "data": null,
        "status": {
            "code": 500,
            "message": 'Internal Server error'
        }
    }
    try {
        if (response.status === 200 || response.status === 201) {
            await response.json().then((res) => {
                if (res?.status?.code === 200 || res.status.code === "Success" || res?.status?.message === "Success") {
                    apiResponse = res;
                }
                else {
                    apiResponse = res;
                }
            });
        } 
        else if (response.status === 401){
            apiResponse = response.status
        }
    //     else if (response.status === 401) {
    //         window.location.href = window.location.origin
    //    }
     
    } catch {
        apiResponse = errorRes
    }
    return apiResponse
}

function imageByDimensions (imageArray, preferableImage){
    if (imageArray !== null && imageArray?.length) {
        for (let i = 0; i < imageArray?.length; i++) {
            let key = Object.keys(imageArray[i])[0];
            let value = Object.values(imageArray[i])[0];
            if (key === preferableImage) return value;
        }
        return Object.values(imageArray[0])[0];
    } else {
        return "";
    }
};

// function FormateDate(date) {
//     var startDate = new Date();

//     let dateStart = Moment(startDate).format("MM/DD/YYYY")

//     startDate.setMonth(startDate.getMonth() - date);

//     let DateEnd = Moment(startDate).format("MM/DD/YYYY")
//     return [dateStart, DateEnd];
// }

function FormateDate(inputDate) {
    const date = new Date(inputDate);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}