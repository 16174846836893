import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import Reducers from './Reducers/reducer';
// REDUCER

// const store = createStore(Reducers);
const store = configureStore({
    reducer: Reducers,
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), // Optional if you want to manually configure DevTools
});
// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

const root = createRoot(document.getElementById('root'));
root.render(<Provider store={store}><App /></Provider>)
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
