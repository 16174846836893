import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./_common.scss";
import { connect } from "react-redux";
import { commonServices } from "../../Utility/common.services";
import { useNavigate } from "react-router-dom";
import { paymentService } from "../../Utility/payment.service";
import { ToastContainer, toast } from "react-toastify";
import loaderImage from "../../Assets/Icons/loader1.gif"

function ConfirmOTP(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  const [loader, setLoader] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [invalidOtp, setInvalidOtp] = useState("");
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(60);

  const closeModel = () => {
    props.setShowOTPPage(false);
  };

  const verifyOTP = async () => {
     let enteredOtp = [otp1, otp2, otp3, otp4, otp5, otp6];
    let otpLength = enteredOtp.join("");
    if (otpLength.length === 6) {
      setLoader(true);
      setLoadingPrefixText("Payment Processing");
      try {
        let response = await paymentService.VerifyOtp(
          otpLength,
          props.cardDetailsData?.price_summary?.required_points?.toString(),
          props.orderId
        );
        if (response?.data?.isOTPValid && response?.data?.isPaymentComplete) {
          props.setShowOTPPage(false);
          sessionStorage.setItem("HasReturn", false);
          navigate("/orderconfirm");
        } 
        else if (!response?.data?.isOTPValid){
          toast.error(response?.status?.message);
          setLoader(false);
        }
        else if(parseInt(response?.data?.remainingAmount) > 0){
          props.setShowOTPPage(false);
          props.handlePaymentMode("cash", response?.data?.remainingAmount);
          toast.success(`${response?.status?.message} & remaining amount is ₹ ${response?.data?.remainingAmount}`);
          setLoader(false);
        }
        else {
          toast.error(response?.status?.message);
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
        console.log("Error in VerifyOTP", err);
      }
    }
  };

  useEffect(()=>{
    startTimer()
  },[])

  const startTimer = () => {
    setCanResend(false);
    setTimer(60);
    const countdown = setInterval(() => {
        setTimer(prev => {
            if (prev <= 1) {
                clearInterval(countdown);
                setCanResend(true);
                return 0;
            }
            return prev - 1;
        });
    }, 1000);
};

const handleResendOtp = () => {
  if (canResend) {
      sendOTP();
  } else {
      toast.info(`Please wait ${timer} seconds before resending OTP.`);
  }
};

  const handleChange = (e, key) => {
    let otp = e.target.value.substr(0, 1);
    let isExactly1Digits = /\b\d{1}\b/g.test(otp);
    if (isExactly1Digits) {
      if (key === 1) {
        setOtp1(otp);
      } else if (key === 2) {
        setOtp2(otp);
      } else if (key === 3) {
        setOtp3(otp);
      } else if (key === 4) {
        setOtp4(otp);
      } else if (key === 5) {
        setOtp5(otp);
      } else if (key === 6) {
        setOtp6(otp);
      }
    } else {
      if (key === 1) {
        setOtp1("");
      } else if (key === 2) {
        setOtp2("");
      } else if (key === 3) {
        setOtp3("");
      } else if (key === 4) {
        setOtp4("");
      } else if (key === 5) {
        setOtp5("");
      } else if (key === 6) {
        setOtp6("");
      }
    }
    setInvalidOtp(false);
  };

  const onKeyPressUpOtp = (e) => {
    let otp = e.target.value;
    let isExactly1Digits = /\b\d{1}\b/g.test(otp);
    if (isExactly1Digits || e.keyCode === 8) {
      var target = e.srcElement || e.target;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var next = target;
        while ((next = next.nextElementSibling)) {
          if (next == null) break;
          if (next.tagName.toLowerCase() === "input") {
            next.focus();
            break;
          }
        }
      }
      else if (myLength === 0) {
        var previous = target;
        while ((previous = previous.previousElementSibling)) {
          if (previous == null) break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
          }
        }
      }
    }
  };


  const sendOTP = async () => {
    try {
      let res = await paymentService.ResendPaymentOTP(props.orderId);
      if (res?.status?.code === 200) {
      } else {
      }
    } catch (e) {
      console.log("Error in sendOTP", e);
    }
  };

  return (
    <>
      {loader && (
        <div className="loader-overlay payment_page">
          <img alt="" src={loaderImage} />
          {/* <p>Please wait, Your payment is being processing</p> */}
        </div>
      )}{" "}
      <Modal
        show={props.isShowOTPPage}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="otp_verification_modal"
      >
        <div className="verification_code">
          <div onClick={() => closeModel()} className="exit">
            <img src={images["cross_icon.svg"]} alt="cross" className="cross" />
          </div>
          <div className="verification_img">
            <h3>Confirm OTP</h3>
          </div>
          <div className="input-group">
            <input
              autoComplete="off"
              type="text"
              // placeholder="7765678989"
              className={"input-area"}
              required
              id="mobilenumber"
              maxLength={20}
              value={
                "+" +
                props.customerDetails?.mobileCountryCode +
                " " +
                props.customerDetails?.mobile
              }
            />
            {/* <label htmlFor="firstname12" className="label">
                Enter Your Mobile Number
              </label> */}
            {/* <img src={images["greentick_icon.svg"]} /> */}
            <label htmlFor="mobilenumber" className="label disabled">
              Enter Your Mobile Number
            </label>
            <img className="start-add-on" src={images["mobilePhone.svg"]} />
            <img className="end-add-on" src={images["greentick_icon.svg"]} />
          </div>
          <div className="otp_capture">
          <div className="resend-otp-container mt-3">
              <span onClick={handleResendOtp} disabled={!canResend} className="resend-otp ">
                Resend OTP</span> <p className="timer">{canResend ? '' : `(${timer}s)`}</p>
                </div> 
            <ul>
              <li>Enter OTP</li>
              {/* {allCardsDetails !== null && allCardsDetails.userProfile.name !== null ? <li>mobile  {allCardsDetails.mobile}</li> : null} */}
            </ul>
            <div className="otp_input">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-1"
                value={otp1}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => handleChange(e, 1)}
                maxLength="1"
              />
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-2"
                value={otp2}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => {
                  handleChange(e, 2);
                }}
                maxLength="1"
              />
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-3"
                value={otp3}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => {
                  handleChange(e, 3);
                }}
                maxLength="1"
              />
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-4"
                value={otp4}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => {
                  handleChange(e, 4);
                }}
                maxLength="1"
              />
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-5"
                value={otp5}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => {
                  handleChange(e, 5);
                }}
                maxLength="1"
              />
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="Chrome-off"
                className="input_box"
                onWheel={(e) => e.target.blur()}
                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                pattern="/\b\d{1}\b/g"
                name="field-6"
                value={otp6}
                onKeyUp={(e) => onKeyPressUpOtp(e)}
                onChange={(e) => {
                  handleChange(e, 6);
                }}
                maxLength="1"
              />
            </div>
            {/* <div className="d-flex align-items-baseline" id="Payment_ResendOTP">
              <a
                href="#"
                style={
                  timer === 0
                    ? {
                        color: "#E0BC20",
                        cursor: "pointer",
                        textDecoration: "none",
                      }
                    : null
                }
                onClick={timer === 0 ? () => resendHandler() : null}
              >
                Resend OTP
              </a>
              <label>{timer > 0 ? "(" + timer(timer) + ")" : null}</label>
            </div> */}
            <button className="verify_otp" onClick={() => verifyOTP()}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowOTPPage: state.isShowOTPPage,
    cardDetailsData: state.cardDetailsData,
    orderId: state.orderId,
    customerDetails: state.customerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowOTPPage: (isShowOTPPage) => {
      dispatch({ type: "IS_SHOW_OTP_PAGE", payload: isShowOTPPage });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOTP);
