import React, { useState } from "react";
import { commonServices } from "../../Utility/common.services";
import './OrderDetails.scss';
import { useNavigate } from 'react-router-dom';

function OrderDetails() {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
  );

  const handleContinue = () =>{
    navigate('/paymentoption');
  }

  return (
    <>
      <div className="m-5 order_details_container">
        <h4>Order Details</h4>
        <div className="row order_details">
          <div className="product_details col-8">
            <table className="w-100">
              <thead>
                <tr>
                  <th>Product Details</th>
                  <th>Quantity</th>
                  <th></th>
                  <th>Offer Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="product_img">
                      <img alt="" src={images["purchaseImg.svg"]} />
                      <div className="product_title">
                      <h4>Twenty Dresses</h4>
                      <p>Get 10 % off on minimum order of Rs 2,499</p>
                      <p>+ ₹30 Shipping Charges</p>
                      </div>
                    </div>
                  </td>
                  <td>1</td>
                  <td></td>
                  <td>₹1,799</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="product_summary col-3">
              <h6>Order Summary</h6>
              <div className="summary_details">
                <ul>
                  <li>
                    Products
                    <span>1</span>
                  </li>
                  <li>
                    Total Amount
                    <span>Rs.1799</span>
                  </li>
                  <li>
                    Required Points
                    <span>550 pts</span>
                  </li>
                  <li>Redeem Points
                    <span className="green_color">550 pts</span>
                  </li>
                </ul>
              </div>
              <div className="summary_btn_wrap">
                <button onClick={() => handleContinue()}>Continue</button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OrderDetails;
