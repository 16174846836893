import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import { commonComponets } from "../../Common/commonComponent";
import Modal from "react-bootstrap/Modal";
import { commonServices } from "../../../Utility/common.services";
// import { orderService } from "../../../Utility/order.service";
import { homeService } from "../../../Utility/home.service";
import { ToastContainer, toast } from "react-toastify";
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from "react-redux";

const QueryModal = (props) => {
    const { queryForProduct, handleQueryModal, orderId, setDetailsModal,setQueryDetails } = props
    const images = commonServices.importImage(
        require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
    );
    const [loader, setLoader] = useState(false);
    const [loadingPrefixText, setLoadingPrefixText] = useState("");
    const [selectedReason, setSelectedReason] = useState()
    const [comment, setComment] = useState('')
    const [ticketId,setTicketId]=useState('')
    const [queryReasons, setQueryReasons] = useState([
        { id: '1', name: "Where is my Order? " },
        { id: '2', name: "I have placed order but did not receive any confirmation" },
        { id: '3', name: "Amount debited from my account" },
        { id: '4', name: "Partial Order received " },
        { id: '5', name: "Damaged Order received " },
        { id: '6', name: "Incorrect Order received " },
        { id: '7', name: "Want to update address " },
        { id: '8', name: "Points debited but did not received any confirmation " },
        { id: '9', name: "Product returned but did not received the refund" },
        { id: '10', name: "In portal status of order is delivered but I have not received the order " },
        { id: '11', name: "Want invoice of the product" },
        { id: '12', name: "Want to cancel the order" },
        { id: '13', name: "How do I track my order " },
        { id: '14', name: "I want to return the product " },
        { id: '15', name: "Can I pay Cash on Delivery " },
        { id: '16', name: "Can I place the order by paying cash only  " },
        { id: '17', name: "Is gift wrapping available" },
        { id: '18', name: "Want to update email id or mobile number" },
    ]);


    const handleQuerySubmission = async () => {
        setLoader(true);
        setLoadingPrefixText("Please wait");
        try {
            let response = await homeService.SendJiraTicketRequest(
                props.customerDetails?.firstName,
                props.customerDetails?.email,
                orderId,
                selectedReason.name,
                comment,
            );
            if (response?.status?.code === 200) {
                // toast.success(`Your Ticket Number is ${response?.data?.data?.ticketId}`);
                setQueryDetails(response?.data?.data)
                setDetailsModal(true)
                handleQueryModal()
                //setTrackingDetails(response?.data);
            }
            else {
                toast.error(response?.status.message);
            }
            setLoader(false);
            setLoadingPrefixText("");
        } catch (e) {
            setLoader(false);
            toast.error("Something went wrong !! Please try again !!");
            console.log("Error in getOrder", e);
        }
    }

    const handleTrackingQuery = async () => {
        setLoader(true);
        setLoadingPrefixText("Please wait");
        try {
            let response = await homeService.TrackJiraTicket(
                ticketId
            );
            if (response?.status?.code === 200) {
                document.getElementById('status_of_ticket').textContent=response?.data?.data?.status
                //setTrackingDetails(response?.data);
            }
            else {
                toast.error(response?.status.message);
            }
            setLoader(false);
            setLoadingPrefixText("");
        } catch (e) {
            setLoader(false);
            toast.error("Something went wrong !! Please try again !!");
            console.log("Error in getOrder", e);
        }
    }


    return (
        <div>
            {loader && (
                <div className="loader-overlay flight_loader">
                    <div className="boot-strap-loader">
                        <div className="spinner-border text-warning" role="status">
                            {/* <span className="sr-only">Loading...</span> */}
                        </div>
                    </div>
                    <p>
                        {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
                    </p>
                </div>
            )}
            <Modal
                className="trackModal_wrap"
                show={queryForProduct}
                centered
                size="md"
            >
                <div className="trackModal_content_wrap cancelModal-wrap ">
                    <div className="exit" onClick={handleQueryModal}>
                        <img
                            src={images["cross_icon.svg"]}
                            alt="cross"
                            className="cross"
                        />
                    </div>
                    <div className="query-container">
                        <Tabs
                            defaultActiveKey="query"
                            id="travel_opt"
                            className="travel_option"
                        >
                            <Tab eventKey="query" title="Raise A Query">

                                <div className="mt-2">
                                    {commonComponets.SingleDropDownComponent(
                                        "Select your query",
                                        queryReasons,
                                        setSelectedReason,
                                        selectedReason
                                    )}
                                </div>
                                <div class="text-area-form-group mt-3 mb-3">
                                        <textarea id="exampleTextarea" placeholder="Type Here" value={comment} onChange={(e) => setComment(e.target.value)} maxLength={500}></textarea>
                                        <label for="exampleTextarea">Description of the issue faced</label>
                                </div>
                                <div className="btn-cancel-order">
                                    <button className="cancelbtn w-100" onClick={handleQuerySubmission}>
                                        Submit
                                    </button>
                                </div>
                            </Tab>
                            <Tab eventKey="track" title="Track your request" >
                                <div className="input-group mt-2 mb-5" >
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        // placeholder="7765678989"
                                        value={ticketId}
                                        onChange={(e)=>setTicketId(e.target.value)}
                                        className={"input-area"}
                                        required
                                        id="mobilenumber"
                                    />
                                    <label htmlFor="mobilenumber" className="label disabled">
                                        Enter Your Query Number
                                    </label>
                                </div>
                                <p id="status_of_ticket"></p>
                                <div className="btn-cancel-order">
                                    <button className="cancelbtn w-100" onClick={handleTrackingQuery}>
                                        Submit
                                    </button>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <ToastContainer />
            </Modal>
           
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      customerDetails: state.customerDetails,
    };
  };

export default connect(mapStateToProps)(QueryModal);