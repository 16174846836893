import React, { useState,useEffect} from 'react'
import { commonServices } from "../../../Utility/common.services";
import { commonComponets } from "../../Common/commonComponent";
import { Utility } from "../../../Utility/utility.service";
import { micrositeService } from '../../../Utility/microsite.service';
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const VerifyEmail = (props) => {
    const { verifyEmail, setVerifyEmail, requestId ,setEmailVerifiedStatus,handleVerifyNow} = props
    const images = commonServices?.importImage(
        require.context("../../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
      );
    const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [invalidOtp, setInvalidOtp] = useState("");
    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");
    const [otp5, setOtp5] = useState("");
    const [otp6, setOtp6] = useState("");
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);

    useEffect(()=>{
        startTimer()
      },[])
    
      const startTimer = () => {
        setCanResend(false);
        setTimer(60);
        const countdown = setInterval(() => {
            setTimer(prev => {
                if (prev <= 1) {
                    clearInterval(countdown);
                    setCanResend(true);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handleResendOtp = () => {
        if (canResend) {
            handleVerifyNow();
        } else {
            toast.info(`Please wait ${timer} seconds before resending OTP.`);
        }
      };

    const handleChange = (e, key) => {
        let otp = e.target.value.substr(0, 1);
        let isExactly1Digits = /\b\d{1}\b/g.test(otp);
        if (isExactly1Digits) {
            if (key === 1) {
                setOtp1(otp);
            } else if (key === 2) {
                setOtp2(otp);
            } else if (key === 3) {
                setOtp3(otp);
            } else if (key === 4) {
                setOtp4(otp);
            } else if (key === 5) {
                setOtp5(otp);
            } else if (key === 6) {
                setOtp6(otp);
            }
        } else {
            if (key === 1) {
                setOtp1("");
            } else if (key === 2) {
                setOtp2("");
            } else if (key === 3) {
                setOtp3("");
            } else if (key === 4) {
                setOtp4("");
            } else if (key === 5) {
                setOtp5("");
            } else if (key === 6) {
                setOtp6("");
            }
        }
         setInvalidOtp(false);
    };

    const onKeyPressUpOtp = (e) => {
        //  var container = document.getElementsByClassName("otp_input")[0];
        let otp = e.target.value;
        let isExactly1Digits = /\b\d{1}\b/g.test(otp);
        if (isExactly1Digits || e.keyCode === 8) {
          var target = e.srcElement || e.target;
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          var myLength = target.value.length;
          if (myLength >= maxLength) {
            var next = target;
            // eslint-disable-next-line
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
              }
            }
          }
          // Move to previous field if empty (user pressed backspace)
          else if (myLength === 0) {
            var previous = target;
            // eslint-disable-next-line
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                previous.focus();
                break;
              }
            }
          }
        }
      };

    const handleVerifyEmail = async () => {
        try {
            let enteredOtp = [otp1, otp2, otp3, otp4, otp5, otp6];
            let otpLength = enteredOtp.join("");
            if (otpLength?.length >= 6) {
                  setLoader(true);
                  setLoadingPrefixText("Hold on!! We are validating you");
                let res = await micrositeService.ValidateOTP(
                    '',
                    requestId,
                    otpLength
                );
                if (res?.status?.code === 200) {
                    setEmailVerifiedStatus(true)
                   setVerifyEmail(false)
                } else {
                    toast.error(res?.status?.message);
                }
                setLoader(false);
                setLoadingPrefixText("");
                setLoadingSuffixText("");
            } else {
                toast.error("Please enter valid OTP");
            }
        } catch (e) {
            console.log("Error in validateOTP", e);
        }
    }
    return (
        <div>
            <Modal
                show={verifyEmail}
                onHide={() => setVerifyEmail(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
                className="login_modal"
            >
                {loader && (
                    <div className="loader-overlay flight_loader">
                        <div className="boot-strap-loader">
                            <div className="spinner-border text-warning" role="status">
                                {/* <span className="sr-only">Loading...</span> */}
                            </div>
                        </div>
                        <p>
                            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
                            {loadingSuffixText ? loadingSuffixText : null}
                        </p>
                    </div>
                )}
                <div className='login-container'>
                    <div className="exit" onClick={() =>setVerifyEmail(false)}>
                        <img
                            src={images["cross_icon.svg"]}
                            alt="cross"
                            className="cross"
                        />
                    </div>
                    <div className='inner-container pt-5'>
                    <div className="otp_capture">
          <div className="resend-otp-container mt-3 mb-2">
              <span onClick={handleResendOtp} disabled={!canResend} className="resend-otp ">
                Resend OTP</span> <p className="timer">{canResend ? '' : `(${timer}s)`}</p>
                </div> 
                </div>
                        <div className="otp_input mb-5">
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-1"
                                value={otp1}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => handleChange(e, 1)}
                                maxLength="1"
                            />
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                 style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-2"
                                value={otp2}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => {
                                    handleChange(e, 2);
                                }}
                                maxLength="1"
                            />
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-3"
                                value={otp3}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => {
                                    handleChange(e, 3);
                                }}
                                maxLength="1"
                            />
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-4"
                                value={otp4}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => {
                                    handleChange(e, 4);
                                }}
                                maxLength="1"
                            />
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-5"
                                value={otp5}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => {
                                    handleChange(e, 5);
                                }}
                                maxLength="1"
                            />
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                autoComplete="Chrome-off"
                                className="input_box"
                                onWheel={(e) => e.target.blur()}
                                style={invalidOtp ? { border: "1px solid #E92953" } : null}
                                pattern="/\b\d{1}\b/g"
                                name="field-6"
                                value={otp6}
                                onKeyUp={(e) => onKeyPressUpOtp(e)}
                                onChange={(e) => {
                                    handleChange(e, 6);
                                }}
                                maxLength="1"
                            />
                        </div>
                        <button
                className={`otp-button ${otp1 && otp2 && otp3 && otp4 && otp5 && otp6 ? "" : "disabled"
                }`}
                onClick={() => handleVerifyEmail()}
              >
                Verify OTP
              </button>
                    </div>
                </div>
                <ToastContainer />
            </Modal>
        </div>
    )
}

export default VerifyEmail
