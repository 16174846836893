import React, { useEffect, useState } from "react";
import * as icons from "../../Assets/Icons/index";
import { dealService } from "../../Utility/deal.service";
import { useLocation } from "react-router-dom";
import { commonComponets } from "../Common/commonComponent";
import { commonServices } from "../../Utility/common.services";
import { connect } from "react-redux";
import ShoppingCart from "../Common/ShoppingCart";
import CardDetails from "../Common/commonSwiper";
import { cartService } from "../../Utility/cart.service";
import { useNavigate } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer, toast } from "react-toastify";
import { micrositeService } from "../../Utility/microsite.service";
import TermsConditionModal from "../Common/TermsConditionModal";
import Login from "../Login/Login";

const DealDetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productQuantity, setProductQuantity] = useState(1);
  const [isIncrement, setIsIncrement] = useState(true);
  const [isDecrement, setIsDecrement] = useState(false);
  const [dealDetails, setDealDetails] = useState({});
  const [similarDeals, setSimilarDeals] = useState([]);
  const [openTermsAndConditionModal, setOpenTermsAndConditionModal] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");

  const handleTermsAndConditions = () => {
    setOpenTermsAndConditionModal(!openTermsAndConditionModal);
  };
  const incNum = () => {
    const updatedDealDetails = { ...dealDetails };

    if (Number(updatedDealDetails.minimumQuantity) < 10) {
      updatedDealDetails.minimumQuantity =
        Number(updatedDealDetails.minimumQuantity) + 1;

      setDealDetails(updatedDealDetails);

      setIsIncrement(true);
      setIsDecrement(false);
    }
    else {
      toast.error("You can add up to 10 quantities!")
    }
  };

  const decNum = () => {
    const updatedDealDetails = { ...dealDetails };

    if (updatedDealDetails.minimumQuantity > 1) {
      updatedDealDetails.minimumQuantity =
        Number(updatedDealDetails.minimumQuantity) - 1;

      setDealDetails(updatedDealDetails);

      setIsIncrement(false);
      setIsDecrement(true);
    }
  };

  useEffect(() => {
    console.log(location.state, "hi");
    getDealsDetails(location.state);
  }, []);

  const getSimilarDeals = async (departmentId, skuId) => {
    try {
      let res = await dealService.GetSimilarDeals(departmentId, skuId);
      if (res?.status?.code === 200) {
        setSimilarDeals(res.data);
      } else {
        setSimilarDeals([]);
      }
    } catch (e) {
      setSimilarDeals([]);
      console.log("Error in getSimilarDeals", e);
    }
  };

  const getDealsDetails = async (skuId) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching your deal details");
    try {
      let res = await dealService.GetDealDetails(skuId);
      if (res?.status?.code === 200) {
        getSimilarDeals(res?.data?.departmentId, res?.data?.sku);
        document.getElementById('description').innerHTML = res?.data?.stockItemDescription
        setDealDetails(res.data);
        if (skuId !== location.state) {
          navigate(location.pathname, { state: skuId });
        }
        window.scrollTo(0, 0);
      } else {
        setDealDetails([]);
      }
      setLoader(false);
      setLoadingPrefixText('')
    } catch (e) {
      setLoader(false);
      setDealDetails([]);
      console.log("Error in getDealsDetails", e);
    }
  };

  const addToCart = async () => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are adding it to your cart");
    try {
      let res = await cartService.SaveCartItem(
        dealDetails.stockItemTypeId,
        dealDetails.stockItemId,
        dealDetails.minimumQuantity
      );
      if (res?.status?.code === 200) {
        props.setShowShoppingCart(true);
      } else if (res === 401) {
        props.setCurrentPage(window.location.pathname)
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setShowLoginForm(true);
        props.setCartDetailsData([]);
      } else {
        toast.error(res?.status?.message);
      }
      setLoader(false);
      setLoadingPrefixText('')
    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
      console.log("Error in SaveCartItem", e);
    }
  };

  const buyNow = () => {
    navigate("/deliverydetails");
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard" className="disabled">
                HOME
              </a></li>
            <li className="breadcrumb-item">
              <a href="/deals" className="disabled">
                DEALS
              </a>
            </li>
          </ol>
        </nav>

        <div className="deals_details_container mb-4">
          <div className="image-container">
            <img
              src={commonServices.imageByDimensions(
                dealDetails?.stockItemImage,
                "20by20"
              )}
            />
          </div>
          <div className="productDetails_wrap">
            <div>
              <h3>{dealDetails?.brandName}</h3>
            </div>
            <span>{dealDetails?.stockItemTitle}</span>
            <h4>
            {dealDetails?.mrp !== dealDetails?.price ?
                <React.Fragment>
                  <span className="without_discount">
                    ₹{dealDetails?.mrp}&nbsp;
                  </span>
                  <span className="connecter">
                    &nbsp;{"-"}&nbsp;
                  </span>
                  ₹ {dealDetails?.price}&nbsp;
                </React.Fragment>
                :
                <React.Fragment>
                  <span className="without_discount only-price">
                    ₹{dealDetails?.mrp}
                  </span>
                </React.Fragment>
              }
              <span className="points"> / {dealDetails?.points} Pts.</span>
            </h4>
            {/* <div className="price">
            <h4>{dealDetails?.price}</h4>
            <span className="offer_span">{"(10%off)"}</span>
            <div className="point_box_common">
              <img src={icons.star} alt="star" />
              <div className="span_nm">{dealDetails?.points} Pts.</div>
            </div>
          </div> */}
            {/* <p>
            MRP: <span className="nodiscount_price">{dealDetails?.mrp}</span>{" "}
            inclusive of all taxes
          </p> */}
            <div className="select_quant_btn">
              <p className="quantity">Select Quantity</p>
              <div className="quantity_btn mb-4">
                <button
                  disabled={dealDetails?.minimumQuantity === 1 ? true : false}
                  style={
                    dealDetails?.minimumQuantity === 1
                      ? { cursor: "none" }
                      : null
                  }
                  className={
                    dealDetails?.minimumQuantity > 1
                      ? isDecrement
                        ? "btnbgcolor"
                        : "btnbordernone"
                      : "btnbordernone"
                  }
                  onClick={() => decNum()}
                  type="button"
                >
                  {/* {dealDetails?.minimumQuantity > 1 && isDecrement ? (
                  <img effect="blur" alt="btn" src={icons.colorminus} />
                ) : ( */}
                  <img effect="blur" alt="btn" src={icons.grayminus} />
                  {/* )} */}
                </button>
                <input
                  disabled
                  className="form-control"
                  value={dealDetails?.minimumQuantity}
                />
                <button
                  id="AddToCart_Quantity"
                  onClick={() => incNum()}
                  type="button"
                  className={
                    dealDetails?.minimumQuantity < 10
                      ? isIncrement
                        ? "btnbgcolor"
                        : "btnbordernone"
                      : "btnbordernone"
                  }
                >
                  {/* {dealDetails?.minimumQuantity < 10 && isIncrement ? (
                  <img effect="blur" alt="btn" src={icons.plus} />
                ) : ( */}
                  <img effect="blur" alt="btn" src={icons.grayplus} />
                  {/* )} */}
                </button>
              </div>
            </div>
            <div className="btn-cart-buy">
              <button className="addcart" onClick={() => addToCart()}>
                Add to Cart
              </button>
              {/* <button className="buynow" onClick={() => buyNow()}>
              Buy Now
            </button> */}
            </div>

            {/* <div className="available_offers mt-2">
            <h4>Available Offers</h4>
            <p>
              What is Lorem Ipsum Lorem Ipsum is simply 10% dummy text of the
              printing
            </p>
            <p>What is Lorem Ipsum is simply 10% dummy </p>
            <p>What is Lorem Lorem Ipsum is simply 10% dummy of the printing</p>
          </div> */}


            <div className="mt-4">
              <p className="description-heading">Product Description</p>
              <p id="description">
                N.A.
              </p>
            </div>

            {dealDetails?.tnc && (
              <p
                className="terms-conditions"
                onClick={handleTermsAndConditions}
              >
                Terms & Conditions
              </p>
            )}
            {/* <ul>
              <li dangerouslySetInnerHTML={createMarkup(dealDetails.tnc)}></li>
              </ul> */}
          </div>
        </div>
        {props.isShowShoppingCart && <ShoppingCart />}
        {props.isShowLoginForm && <Login />}

        {similarDeals?.length > 0 && (
          <div className="similar_product mt-5">
            <div className="top-view">
              <span>Similar Products</span>
              {/* <button>Show More</button> */}
            </div>
            <div className="swp mb-5">
              <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
              >
                {similarDeals?.map((ele, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="dealCard_wrap"
                        onClick={() => getDealsDetails(ele.sku)}
                      >
                        <div className="image-container">
                          <img
                            src={commonServices.imageByDimensions(
                              ele.stockItemImage,
                              "20by20"
                            )}
                            alt="images"
                          />
                        </div>

                        <div className="deal-details">
                          <h6>{ele?.brandName}</h6>
                          <h4>{ele?.stockItemTitle}</h4>
                          <span className="price-description">
                          {ele?.mrp !== ele?.price ?
                                <React.Fragment>
                                  <span className="without_discount">
                                    ₹{ele?.mrp}&nbsp;
                                  </span>
                                  <span className="connecter">
                                    &nbsp;{"-"}&nbsp;
                                  </span>
                                  ₹ {ele?.price}&nbsp;
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span className="without_discount only-price">
                                    ₹{ele?.mrp}
                                  </span>
                                </React.Fragment>
                              }
                            <span className="points"> / {ele.points} Pts.</span>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        )}
        <TermsConditionModal
          openTermsAndConditionModal={openTermsAndConditionModal}
          setOpenTermsAndConditionModal={setOpenTermsAndConditionModal}
          tnc={dealDetails.tnc}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowShoppingCart: state.isShowShoppingCart,
    isShowLoginForm: state.isShowLoginForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
    setCurrentPage: (page) => {
      dispatch({ type: "CURRENT_PAGE", payload: page });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealDetails);
