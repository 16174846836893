// import { commonServices } from './common.services';
import { config } from './config';
import { commonServices } from './common.services';

export const micrositeService = {
    GetCustomerAvailablePoints,
    GenerateOTP,
    ValidateOTP,
    BypassValidateOTP,
    SearchCustomer,
    LogOut,
    SearchMember,
    GetCustomerTransactionDetails,
    RegisterCustomer,
    UpdateMemberProfile
}


async function BypassValidateOTP() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "programCode": config.programCode,
            "memberID": "7776987110",
            "requestID": "",
            "otp": ""
         })
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/BypassValidateOTP', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GenerateOTP(mobileNumber,email='') {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({  
            "programCode": config.programCode,
            "mobileNumber": [
                mobileNumber
            ],
            "email": email
            //"isConsent": "false"
        })
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/GenerateOTP', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function ValidateOTP(mobileNumber, requestId, otp ) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({  
            "programCode": config.programCode,
            "memberID": mobileNumber,
            "requestID": requestId,
            "otp": otp,
            // "isConsent": "false",
            // "isRegister": ""
        })
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/ValidateOTP', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function RegisterCustomer(mobileNumber) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "mobileNo": mobileNumber,
          })
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/RegisterCustomer', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function SearchCustomer() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/SearchCustomer', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetCustomerAvailablePoints(points, paymentMode, orderId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/GetCustomerAvailablePoints', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function LogOut() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/LogOut', requestOptions)
        .then((res) => {
            return res.json().then(res);
            //return commonServices.ApiResponseHandler(res);
        });
}

async function SearchMember() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'MicrositeHelper/SearchMember', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetCustomerTransactionDetails(postperPage,currentPage) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({ 
            "transactionTypeId": 0,
            "transactionDetailsCount": 0,
            "pageSize": postperPage,
            "pageNumber": currentPage,
            "startDate": "",
            "endDate": "",
            "billNo": "" 
        })

    }

    return await fetch(config.apiUrl + 'MicrositeHelper/GetCustomerTransactionDetails', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}


async function UpdateMemberProfile(customerProfileDetails) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({
            "firstName": customerProfileDetails?.firstName,
            "lastName": customerProfileDetails?.lastName,
            "dateOfBirth": commonServices.FormateDate(customerProfileDetails?.dateOfBirth),
            "address1": "",
            "address2": "",
            "city": "",
            "state": "",
            "pinCode": "",
            "emailId": customerProfileDetails?.email,
            "gender": "",
            "anniversaryDate": "",
            "channelCode": "",
            "customerTypeCode": "",
            "referralCode": "",
            "numberofChildren": "",
            "favouriteCusine": "",
            "alternateNumber": "",
            "spouseName": "",
            "childCount": ""
          })

    }

    return await fetch(config.apiUrl + 'MicrositeHelper/UpdateMemberProfile', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}