import Modal from "react-bootstrap/Modal";
import { commonServices } from "../../Utility/common.services";

const TermsConditionModal = (props)=>{
    const {openTermsAndConditionModal,setOpenTermsAndConditionModal,tnc}=props
    const images = commonServices.importImage(
        require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
      );

    const handleClose = () => {
        setOpenTermsAndConditionModal(false)
      };

    // const createMarkup = (value) => { return { __html: value }; };

    const createMarkup = (value) => {
      if (typeof value === "string" && !value.startsWith("<")) {
        const points = value?.split(/\r?\n/)?.filter(line => line.trim() !== "");
        return { __html: `${points?.map(point => `<p>${point}</p></br/>`).join("")}` };
      }
      return { __html: value }; 
    };

return(
    <Modal
    show={openTermsAndConditionModal}
    aria-labelledby="example-custom-modal-styling-title"
    centered
    size="md"
    // onHide={() => handleClose()}
    className="terms-condition-modal"
  >
    <div className="terms-condition-container">
       <div className="exit">
          <img onClick={() => handleClose()} src={images["cross_icon.svg"]} alt="cross" className="cross" />
        </div>
        <div className="inner-container">
        <h4>Terms and Condition</h4>
        <p dangerouslySetInnerHTML={createMarkup(tnc)}></p>
        </div>
    </div>

  </Modal>
)
}

export default TermsConditionModal;