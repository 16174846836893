import { commonServices } from './common.services';
import { config } from './config';

export const voucherService = {
    GetVouchers,
    GetBrands,
    GetDepartments,
    GetFeatureSections,
    GetSimilarVouchers,
    GetVoucherDetails,
    GetCategories
}

async function GetVouchers(size, from, departmentId, brandId, pointFrom, pointTo, categories, sortBy, sortMode, searchText) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({  
            "size": size,
            "from": from,
            "departmentId": departmentId,
            "brandId": brandId,
            "pointFrom": parseInt(pointFrom),
            "pointTo": parseInt(pointTo),
            "sortBy": sortBy,
            "sortMode": sortMode,
            "categoriesId": categories,
            "searchText": searchText
        })
    }

    return await fetch(config.apiUrl + 'Voucher/GetVouchers', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetBrands(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({'departmentId': departmentId})

    }

    return await fetch(config.apiUrl + 'Voucher/GetBrands', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetDepartments() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        }
    }

    return await fetch(config.apiUrl + 'Voucher/GetDepartments', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetFeatureSections() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        }
    }

    return await fetch(config.apiUrl + 'Voucher/GetFeatureSections', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetSimilarVouchers(departmentId, skuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"departmentId": departmentId, 'skuId': skuId})

    }

    return await fetch(config.apiUrl + 'Voucher/GetSimilarVouchers', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetVoucherDetails(skuId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({"skuId": skuId})

    }

    return await fetch(config.apiUrl + 'Voucher/GetVoucherDetails', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}
async function GetCategories(departmentId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,

        },
        body: JSON.stringify({'departmentId': departmentId ? departmentId : ""})
    }

    return await fetch(config.apiUrl + 'Voucher/GetCategories', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}