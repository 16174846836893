import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { commonComponets } from "./commonComponent";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../../Utility/common.services";
import { connect } from "react-redux";
import { cartService } from "../../Utility/cart.service";
import { ToastContainer, toast } from "react-toastify";

function ShoppingCart(props) {
  const navigate = useNavigate();
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [dropdown, setDropDown] = useState(false);
  const [cartDetailsData, setCartDetailsData] = useState([]);
  const [isIncrement, setIsIncrement] = useState(true);
  const [isDecrement, setIsDecrement] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");

  const handleDropDown = () => {
    setDropDown(!dropdown);
  };

  const handleClose = () => props.setShowShoppingCart(false);

  const incNum = async (data) => {
    if (data.quantity < 10){
    await updateCartItem(
      data.stock_item_type_id,
      data.quantity + 1,
      data.stock_item_id
    );
    await getCart();
    }
    else{
      toast.error("You can add up to 10 quantities!")
    }
  };

  const decNum = async (data) => {
    if (data.quantity > 1) {
      await updateCartItem(
        data.stock_item_type_id,
        data.quantity - 1,
        data.stock_item_id
      );
      await getCart();
    }
  };

  const updateCartItem = async (stockItemId, stockItemTypeId, quantity) => {
    setLoader(true);
    setLoadingPrefixText("Updating cart");
    try {
      let response = await cartService.UpdateCart(
        stockItemId,
        quantity,
        stockItemTypeId,
        ""
      );
      if (response?.status?.code === 200) {
      } else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const proceedToCheckout = () => {
    sessionStorage.setItem("HasReturn", false);
    props.setShowShoppingCart(false);
    if (cartDetailsData?.feature_id === "PRO013"){
      navigate("/addressdetails")
    }
    else{
      navigate("/deliverydetails");
    }
  };

  useEffect(() => {
    getCart();
  }, []);

  const getCart = async () => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching details");
    try {
      let res = await cartService.GetCart();
      if (res?.status?.code === 200) {
        setCartDetailsData(res?.data);
        props.setCartDetailsData(res?.data);
      } else {
        setCartDetailsData([]);
        props.setCartDetailsData([]);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setCartDetailsData([]);
      props.setCartDetailsData([]);
      console.log("Error in getCart", e);
    }
  };

  const deleteCart = async (stockItemId) => {
    setLoader(true);
    setLoadingPrefixText("Deleting cart");
    try {
      let res = await cartService.DeleteCart(stockItemId);
      if (res?.status?.code === 200) {
        getCart();
      } else {
        toast.error(res?.status?.message);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const continueShopping = () => {
    props.setShowShoppingCart(false);
    if (cartDetailsData?.feature_id === "VOU014") {
      navigate("/voucher");
    }
    else if (cartDetailsData?.feature_id === "PRO013"){
      navigate("/shop");
    }
     else {
      navigate("/deals");
    }
  };

  const startShopping = () => {
    props.setShowShoppingCart(false);
    navigate("/dashboard");
  };

  return (
    <>
      <Modal
        className="modalCart"
        show={props.isShowShoppingCart}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">
            Shopping Cart{" "}
            {cartDetailsData?.count > 0 && "(" + cartDetailsData?.count + ")"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cart-wrap ">
          {loader && (
            <div className="loader-overlay flight_loader">
              <div className="boot-strap-loader">
                <div className="spinner-border text-warning" role="status">
                  {/* <span className="sr-only">Loading...</span> */}
                </div>
              </div>
              <p>
                {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              </p>
            </div>
          )}
            {cartDetailsData?.basket_items?.length > 0 ? (
              <>
                {cartDetailsData?.basket_items?.map((ele, index) => {
                  return (
                    <div className="product-div" key={index}>
                      <img
                        alt=""
                        className="img-prd"
                        src={commonServices.imageByDimensions(
                          ele?.stock_item_image,
                          "20by20"
                        )}
                      />
                      <div className="prd-descrption">
                        <div className="name-dlt">
                          <h6>{ele?.stock_item_title}</h6>

                          <img
                            src={images["deleteIcon.svg"]}
                            alt="name"
                            onClick={() => deleteCart(ele.stock_item_id)}
                          />
                        </div>
                        <div className="price-pts">
                          <span>
                            ₹ {ele?.price}
                            <span className="pointstxt">
                              {" "}
                              / {ele.points} pts.
                            </span>
                          </span>
                          {/* <div>{commonComponets.PointBox(ele?.points)}</div> */}
                        </div>
                        <div className="quantity-size-wrap">
                          <div className="quantity-box">
                            <span>Select Quantity</span>
                            <div className="inc-dec-box">
                              <div
                                className="btn-inc-dec"
                                onClick={() => decNum(ele)}
                              >
                                <img alt="" src={images["minusIcon.png"]} />
                              </div>
                              <div className="count-display">
                                {ele.quantity}
                              </div>
                              <div
                                className="btn-inc-dec inc"
                                onClick={() => incNum(ele)}
                              >
                                <img alt="" src={images["addIcon.png"]} />
                              </div>
                            </div>
                          </div>
                          {ele.feature_id !== "DEA015" &&
                          ele.feature_id === "" ? (
                            <div className="size-box-div">
                              <span>Size</span>
                              <div
                                className="size-box"
                                onClick={() => handleDropDown()}
                              >
                                <span>M</span>

                                <div className="clk-box">
                                  <img alt="" src={images["downArrow.svg"]} />
                                </div>

                                {dropdown ? (
                                  <ul className="drpdown">
                                    <li>S</li>
                                    <li>M</li>
                                    <li>L</li>
                                    <li>XL</li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className=" summary-box mb-5">
                  <h6>Summary</h6>
                  <div className="summary-details">
                    <ul>
                      <li>
                        Quantity
                        <span>
                          {
                            cartDetailsData?.price_summary?.price_breakup[0]
                              ?.Quantity
                          }
                        </span>
                      </li>
                      <li>
                        Total MRP
                        <span>
                          Rs.{cartDetailsData?.price_summary?.total_price}
                        </span>
                      </li>
                      <li>
                        Shipping Charges
                        <span>
                          Rs.{cartDetailsData?.price_summary?.shipping_charges}
                        </span>
                      </li>
                      <li>
                        Total Amount
                        <span>
                          Rs.{cartDetailsData?.price_summary?.total_price}
                        </span>
                      </li>
                      <li className="orange_color">
                        Required Points
                        <span>
                          {cartDetailsData?.price_summary?.required_points} pts.
                        </span>
                      </li>
                      <li>
                        Available Points
                        <span>
                          {props.customerDetails?.availablePoints} pts.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="summary-btn-wrap">
                    <button
                      className="prcd_prcs"
                      onClick={() => proceedToCheckout()}
                    >
                      Proceed to Checkout
                    </button>
                    <button onClick={() => continueShopping()}>
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="empty_cart">
                <div className="empty_bag">
                  <img alt="" src={images["empty_cart.svg"]} />

                </div>
                <h5>Your shopping bag is empty</h5>
                <button className="start_shop" onClick={() => startShopping()}>
                  Start Shopping{" "}
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowShoppingCart: state.isShowShoppingCart,
    customerDetails: state.customerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
