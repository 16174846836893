import { commonServices } from "../../Utility/common.services";

function ErrorPage(){
    const images = commonServices.importImage(
        require.context("../../Assets/Icons", false, /\.(png|jpe?g|svg)$/)
      );
    
    return (
        <div className='error_container'>
            <img src={images["paymentfailed.png"]} alt="failed"/>
            <h4>Transaction Failed</h4>
        </div>
    );
}

export default ErrorPage;

