import React, { useEffect, useState } from "react";
import { commonServices } from "../../Utility/common.services";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./_shop.scss";
import { connect } from "react-redux";
import { commonComponets } from "../Common/commonComponent";
import { homeService } from "../../Utility/home.service";
import { shopService } from "../../Utility/shop.service";
import AllProducts from "./AllProducts";
import { useNavigate } from "react-router-dom";

function Shop(props) {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [slidePerview, setSlidePerview] = useState(
    window.innerWidth < 540
      ? 1.5
      : window.innerWidth < 720
        ? 2.8
        : window.innerWidth < 960
          ? 3.5
          : 3
  );

  const [products, setProducts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [loadingSuffixText, setLoadingSuffixText] = useState("");
  const [isShowListiongPage, setIsShowListiongPage] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [size, setSize] = useState(500);
  const [from, setFrom] = useState(0);
  const [pointFrom, setPointFrom] = useState(1);
  const [pointTo, setPointTo] = useState(500000);
  const [sortBy, setSortBy] = useState("");
  const [sortMode, setSortMode] = useState("");
  const [priceToFiltered, setPriceToFiltered] = useState([0, 0]);
  const [categoriesToFiltered, setCategoriesToFiltered] = useState(props.selectedCategory ? props.selectedCategory : []);
  const [brandsToFiltered, setBandsToFiltered] = useState([]);
  const [pageSectionData, setPageSectionData] = useState([]);
  const [isShowApply, setIsShowApply] = useState(false);
  const [categoryItemCount, setCategoryItemCount] = useState(8);
  const [categoriesItemsLength, setCategoriesItemsLength] = useState(0);
  const [categoriesSection, setCategoriesSection] = useState([]);

  const setDimension = () => {
    let screenSize = window.innerWidth;
    setSlidePerview(
      screenSize < 540
        ? 1.5
        : screenSize < 720
          ? 2.8
          : screenSize < 960
            ? 3.5
            : 3
    );
  };

  // useEffect(() => {
  //   setDimension();
  // }, [props.screenSize]);

  useEffect(() => {
    props.setSelectedDepartment("");
    props.setSelectedBrand("");
    props.setSelectedCategory([]);

    getPageSections();
    getDepartments();

    window.scrollTo(0, 0);
    window.addEventListener('popstate', function (event) {
      setIsShowListiongPage(false)
    });
  }, []);

  const getPageSections = async () => {
    try {
      let res = await homeService.GetPageSections("shop");
      if (res !== null) {
        setCategoriesSection(res.filter((ele) => ele.sectionCode === "Categories")[0]);
        setCategoriesItemsLength(res.filter((ele) => ele.sectionCode === "Categories")[0]?.items?.length || 0)
        setPageSectionData(res);
      } else {
        setPageSectionData([]);
      }
    } catch (e) {
      console.log("Error in getPageSections", e);
    }
  };

  const getCategories = async () => {
    try {
      let res = await shopService.GetCategories(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setCategories(res.data);
      } else {
        setCategories([]);
      }
    } catch (e) {
      setCategories([]);
      console.log("Error in getCategories", e);
    }
  };

  const getBrands = async () => {
    try {
      let res = await shopService.GetBrands(props.selectedDepartment);
      if (res?.status?.code === 200) {
        setBrands(res.data);
      } else {
        setBrands([]);
      }
    } catch (e) {
      setBrands([]);
      console.log("Error in getBrands", e);
    }
  };

  const getDepartments = async () => {
    try {
      let res = await shopService.GetDepartments();
      if (res?.status?.code === 200) {
        setDepartments(res.data);
      } else {
        setDepartments([]);
      }
    } catch (e) {
      console.log("Error in getDepartments", e);
    }
  };

  useEffect(() => {
    getProducts(
      props.selectedDepartment,
      brandsToFiltered,
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      props.searchValue
    );
  }, [
    props.selectedDepartment,
    brandsToFiltered,
    categoriesToFiltered,
    props.sortBy,
    props.sortMode,
    //props.selectedCategory,
    props.searchValue
  ]);

  useEffect(() => {
    getCategories();
    getBrands();
  }, [props.selectedDepartment]);

  useEffect(() => {
    if (props.isShowListingPage) {
      // setCategoriesToFiltered(
      //   props.selectedCategory ? props.selectedCategory : []
      // );   
      setIsShowListiongPage(true);
      props.setShowListingPage(false);
    }
  }, [props.isShowListingPage]);

  const getProducts = async (
    departments,
    brands,
    pointFrom,
    pointTo,
    categories,
    sBy,
    sMode,
    searchText
  ) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching products");
    // setLoadingSuffixText("Shop page for you...");
    try {
      let res = await shopService.GetProducts(
        size,
        from,
        departments,
        brands,
        pointFrom,
        pointTo,
        sBy,
        sMode,
        categories,
        searchText
      );
      if (res?.status?.code === 200) {
        setProducts(res.data?.result);
      } else {
        setProducts([]);
      }
      setLoader(false);
      setLoadingPrefixText("");
      setLoadingSuffixText("");
    } catch (e) {
      setLoader(false);
      setProducts([]);
      console.log("Error in getProducts", e);
    }
  };

  const openListingPage = (key, departmentId, id) => {
    if (key === "Categories") {
      props.setSelectedDepartment(id); ///id is category id
    } else if (key === "Brands") {
      props.setSelectedBrand(id);
      setBandsToFiltered([id]);
    } else if (key === "Electronics") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedCategory([id]);
      setCategoriesToFiltered([id]);
    } else if (key === "HomeAndKitchen") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedCategory([id]);
      setCategoriesToFiltered([id]);
    } else if (key === "Shoes") {
      props.setSelectedDepartment(departmentId);
      props.setSelectedBrand([id]);
      setBandsToFiltered([id]);
    } else if (key === "Products") {
      if (id && id !== "") {
        navigate("/productdetails", { state: id });
      }
    }
    //props.setShowListingPage(true);
    setIsShowListiongPage(true);
  };

  const filterSectionHandler = async (event, key, value) => {
    try {
      let catagoriesArr = [];
      let brandsArr = [];
      props.setSearchValue("");

      if (key === "category") {
        let categories = [...categoriesToFiltered];

        if (categories.includes(value)) {
          const updatedCategories = categories.filter(
            (categoryId) => categoryId !== value
          );
          setCategoriesToFiltered(updatedCategories);
        } else {
          catagoriesArr.push(value);
          setCategoriesToFiltered([...categoriesToFiltered, ...catagoriesArr]);
        }
      } else if (key === "brand") {
        let brands = [...brandsToFiltered];

        if (brands.includes(value)) {
          const updatedBrands = brands.filter((brandId) => brandId !== value);
          await setBandsToFiltered(updatedBrands);
        } else {
          brandsArr.push(value);
          await setBandsToFiltered([...brandsToFiltered, ...brandsArr]);
        }
      } else if (key === "price") {
        setPointFrom(value[0]);
        setPointTo(value[1]);
        setIsShowApply(true);
        props.setIsFilterChecked(true);
      }
    } catch (error) {
      console.log("error in filterSectionHandler is", error);
    }
  };

  const onChangeBrand = (id) => {
    setBandsToFiltered(id);
    setCategoriesToFiltered(id);
  };

  const applyFilter = async () => {
    getProducts(
      props.selectedDepartment,
      brandsToFiltered,
      pointFrom,
      pointTo,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      ""
    );
    setIsShowApply(false);
  };

  const resetFilter = () => {
    setPointFrom(1);
    setPointTo(500000);
    props.setIsFilterChecked(false)
    getProducts(
      props.selectedDepartment,
      brandsToFiltered,
      1,
      500000,
      categoriesToFiltered,
      props.sortBy,
      props.sortMode,
      ""
    )
  }

  const redirectTo = (url) => {
    navigate(url);
  }

  return (
    <>
      <div className="swp-banner">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation
          modules={[Navigation, Scrollbar, A11y]}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {pageSectionData?.length > 0 &&
            pageSectionData
              .filter((ele) => ele.sectionCode === "HeroBanner")
              .map((ele) =>
                ele.items?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} onClick={() => redirectTo(item?.redirectUrl)}>
                      <img alt="" src={item.imageUrl} />
                    </SwiperSlide>
                  );
                })
              )}
        </Swiper>
      </div>

      <div className="main_container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
              {loadingSuffixText ? loadingSuffixText : null}
            </p>
          </div>
        )}
        {!isShowListiongPage ? (
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" className="disabled">
                    HOME
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/shop">SHOP</a>
                </li>
              </ol>
            </nav>
            <div className="top-view">
              <span>Shop By Product</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Products")
                ?.items?.length && (
                  <button onClick={() => openListingPage("Products", "", "")}>
                    View All
                  </button>
                )}
            </div>
            <div className="swp pb-3">
              <Swiper
                spaceBetween={22}
                slidesPerView={slidePerview}
                navigation
                modules={[Navigation, Scrollbar, A11y]}
                pagination={{ clickable: true }}
              >
                {pageSectionData?.find((ele) => ele.sectionCode === "Products")?.items
                  ?.length > 0 ?
                  pageSectionData
                    .filter((ele) => ele.sectionCode === "Products")
                    .map((ele) =>
                      ele.items?.map((item, index) => {
                        return (
                          <SwiperSlide
                            key={index}
                            onClick={() =>
                              openListingPage("Products", "", item.uId)
                            }
                          >
                            <div className="top-swiper-container">
                              <div className="img-container">
                                <img
                                  alt=""
                                  src={commonServices.imageByDimensions(
                                    item.skuDetails?.imageUrl,
                                    "20by20"
                                  )}
                                />
                              </div>
                              <div className="product_details">
                                <h6>{item.skuDetails?.brandName}</h6>
                                <h5>{item?.skuDetails?.title}</h5>
                                <span className="with_discount">
                                  {item?.skuDetails?.mrp !== item?.skuDetails?.price ?
                                    <React.Fragment>
                                      <span className="without_discount">
                                        ₹{item?.skuDetails?.mrp}&nbsp;
                                      </span>
                                      <span className="connecter">
                                        &nbsp;{"-"}&nbsp;
                                      </span>
                                      ₹ {item?.skuDetails?.price}&nbsp;
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                      <span className="without_discount only-price">
                                        ₹{item?.skuDetails?.mrp}&nbsp;
                                      </span>
                                    </React.Fragment>
                                  }
                                  <span> / {item?.skuDetails?.points}Pts.</span>
                                </span>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })
                    )
                  : (
                    <div>
                      <img src={images["nodata.jpeg"]} />
                      <p>No Product Available</p>
                    </div>
                  )
                }
                {/* <SwiperSlide>{commonComponets.ProductCard}</SwiperSlide>
                <SwiperSlide>{commonComponets.ProductCard}</SwiperSlide>
                <SwiperSlide>{commonComponets.ProductCard}</SwiperSlide>
                <SwiperSlide>{commonComponets.ProductCard}</SwiperSlide> */}
              </Swiper>
            </div>

            <div className="top-view mt-5 pt-1">
              <span>Shop By Brands</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Brands")
                ?.items?.length && (
                  <button onClick={() => openListingPage("Brands", "", "")}>
                    View All
                  </button>
                )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Brands")?.items
              ?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={slidePerview}
                    navigation
                    modules={[Navigation, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Brands")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage("Brands", "", item?.uId)
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item?.itemDisplayName}</h4>
                                  </div>
                                </div>{" "}
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Electronics</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Electronics")
                ?.items?.length && (
                  <button
                    onClick={() =>
                      openListingPage(
                        "Electronics",
                        pageSectionData?.find(
                          (ele) => ele.sectionCode === "Electronics"
                        )?.groupId,
                        ""
                      )
                    }
                  >
                    View All
                  </button>
                )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Electronics")
              ?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={slidePerview}
                    navigation
                    modules={[Navigation, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Electronics")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "Electronics",
                                    ele?.groupId,
                                    item?.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item?.itemDisplayName}</h4>
                                  </div>
                                </div>{" "}
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Home & Kitchen</span>
              {pageSectionData?.find(
                (ele) => ele.sectionCode === "HomeAndKitchen"
              )?.items?.length && (
                  <button
                    onClick={() =>
                      openListingPage(
                        "HomeAndKitchen",
                        pageSectionData?.find(
                          (ele) => ele.sectionCode === "HomeAndKitchen"
                        )?.groupId,
                        ""
                      )
                    }
                  >
                    View All
                  </button>
                )}
            </div>
            {pageSectionData?.find(
              (ele) => ele.sectionCode === "HomeAndKitchen"
            )?.items?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={slidePerview}
                    navigation
                    modules={[Navigation, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "HomeAndKitchen")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "HomeAndKitchen",
                                    ele?.groupId,
                                    item?.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item?.itemDisplayName}</h4>
                                  </div>
                                </div>{" "}
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Shoes</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Shoes")
                ?.items?.length && (
                  <button
                    onClick={() =>
                      openListingPage(
                        "Shoes",
                        pageSectionData?.find(
                          (ele) => ele.sectionCode === "Shoes"
                        )?.groupId,
                        ""
                      )
                    }
                  >
                    View All
                  </button>
                )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Shoes")?.items
              ?.length > 0 ? (
              <>
                <div className="swp pb-3">
                  <Swiper
                    spaceBetween={22}
                    slidesPerView={slidePerview}
                    navigation
                    modules={[Navigation, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  >
                    {pageSectionData?.length > 0 &&
                      pageSectionData
                        .filter((ele) => ele.sectionCode === "Shoes")
                        .map((ele) =>
                          ele.items?.map((item, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                onClick={() =>
                                  openListingPage(
                                    "Shoes",
                                    ele?.groupId,
                                    item?.uId
                                  )
                                }
                              >
                                <div className="productcard_wrap">
                                  <div className="img-container">
                                    <img alt="" src={item.imageUrl} />
                                  </div>
                                  <div className="product_details">
                                    <h4>{item?.itemDisplayName}</h4>
                                  </div>
                                </div>{" "}
                              </SwiperSlide>
                            );
                          })
                        )}
                  </Swiper>
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}

            <div className="top-view mt-5 pt-1">
              <span>Shop Categories</span>
              {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
                ?.items?.length && (
                  <button onClick={() => openListingPage("Categories", "", "")}>
                    View All
                  </button>
                )}
            </div>
            {pageSectionData?.find((ele) => ele.sectionCode === "Categories")
              ?.items?.length > 0 ? (
              <>
                <div className="row">
                  {pageSectionData?.length > 0 &&
                    pageSectionData
                      .filter((ele) => ele.sectionCode === "Categories")
                      .map((ele) =>
                        ele.items?.slice(0, categoryItemCount)?.map((item, index) => {
                          return (
                            <div
                              className="col-md-3 mb-3"
                              onClick={() =>
                                openListingPage("Categories", "", item?.uId)
                              }
                              key={index}
                            >
                              <div className="categories">
                                <img alt="" src={item.imageUrl} />
                                <div className="categories-detail">
                                  <h4>{item?.itemDisplayName}</h4>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                </div>
              </>
            ) : (
              <div>
                <img src={images["nodata.jpeg"]} />
                <p>No Product Available</p>
              </div>
            )}

            <div className="d-flex justify-content-center align-items-center mb-5 pt-3 pb-3">
              {categoryItemCount !== categoriesItemsLength && categoriesItemsLength > 8 ? (
                <div className="button-container" onClick={() => setCategoryItemCount(categoriesItemsLength)}>
                  <span>Show More</span>
                  <img src={images["down_arrow.svg"]} alt="name" />
                </div>
              ) : (
                categoriesItemsLength > 0 &&
                categoriesItemsLength > 8 && (
                  <div className="button-container" onClick={() => setCategoryItemCount(8)}>
                    <span>Show Less</span>
                    <img src={images["down_arrow.svg"]} alt="name" />
                  </div>
                )
              )}
            </div>
          </div>

        ) : (
          <AllProducts
            Products={products}
            Categories={categories}
            Brands={brands}
            Departments={departments}
            priceToFiltered={priceToFiltered}
            pointFrom={pointFrom}
            pointTo={pointTo}
            filter={(event, key, value) =>
              filterSectionHandler(event, key, value)
            }
            categoriesToFiltered={categoriesToFiltered}
            brandsToFiltered={brandsToFiltered}
            onChangeBrand={onChangeBrand}
            applyFilter={applyFilter}
            isShowApply={isShowApply}
            resetFilter={resetFilter}
          />
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    selectedBrand: state.selectedBrand,
    selectedDepartment: state.selectedDepartment,
    selectedCategory: state.selectedCategory,
    sortBy: state.sortBy,
    sortMode: state.sortMode,
    isShowListingPage: state.isShowListingPage,
    searchValue: state.searchValue
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setSelectedCategory: (selectedCat) => {
      dispatch({ type: "SELECTED_CATEGORY", payload: selectedCat });
    },
    setSelectedBrand: (selectedBrand) => {
      dispatch({ type: "SELECTED_BRAND", payload: selectedBrand });
    },
    setSelectedDepartment: (selectedDept) => {
      dispatch({ type: "SELECTED_DEPARTMENT", payload: selectedDept });
    },
    setShowListingPage: (isShow) => {
      dispatch({ type: "IS_LISTING_PAGE", payload: isShow });
    },
    setSearchValue: (val) => dispatch({ type: 'SEARCH_VALUE', payload: val }),
    setIsFilterChecked: (val) => dispatch({ type: 'IS_FILTER_CHECK', payload: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
