import { commonServices } from './common.services';
import { config } from './config';

export const redisService = {
    GetShopStates,
    GetShopCities
}

async function GetShopStates() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
    }

    return await fetch(config.apiUrl + 'RedisData/GetShopStates/api/RedisData/GetShopStates', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}

async function GetShopCities(stateId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'isAjaxCall': 'true',
            'cache-control': 'no-cache',
            "program": config.programCode,
        },
        body: JSON.stringify({  
            stateId: stateId
        })
    }
    return await fetch(config.apiUrl + 'RedisData/GetShopCities/api/RedisData/GetShopCities', requestOptions)
        .then((res) => {
            return commonServices.ApiResponseHandler(res);
        });
}