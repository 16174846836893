import './_footer.scss'
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonServices } from "../../Utility/common.services";
import Accordion from 'react-bootstrap/Accordion';
import footerData from './footerFaq.json';


function FAQ(props) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('Merchandise');
  const [selectedButton, setSelectedButton] = useState('faq');
  const [faqData, setFaqData] = useState([]);
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );


  useEffect(() => {
    window.scrollTo(0, 0);  
  }, []);

  useEffect(() => {
    setFaqData(footerData[selectedItem]);
  }, [selectedItem]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setFaqData(footerData[item]);
  };

  return (
    <div className="main-div">
      <div className="swp-banner">
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <img alt="" src={images["FAQs.png"]} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="faq-container container">
        <div className="btns">
          <button className={`btn_css ${selectedButton==='faq' ? 'active':''}`} onClick={()=>setSelectedButton('faq')}>FAQ's</button>
          <button className={`btn_css ${selectedButton==='contact' ? 'active':''}`} onClick={()=>setSelectedButton('contact')}>Contact Us</button>
        </div>
        <div className="row mt-5 inner-faq-container">
          {selectedButton === 'faq' ? 
          <>
           <div className="col-12 questions_answers">
            <h4>How can we help you?</h4>
          </div>
          <div className="col-3">
            <div className="sidebar">
              <ul>
                <li className={selectedItem === 'Merchandise' ? 'active' : ''} onClick={() => handleItemClick('Merchandise')}>Merchandise</li>
                <li className={selectedItem === 'deals' ? 'active' : ''} onClick={() => handleItemClick('deals')}>Deals</li>
                <li className={selectedItem === 'voucher' ? 'active' : ''} onClick={() => handleItemClick('voucher')}>Voucher</li>
              </ul>
            </div>
          </div>

          <div className='col-1'>
            <div className="vertical-line"></div>
          </div>

          <div className='col-8 mb-5'>
          <Accordion>
              {faqData.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{item.faq}</Accordion.Header>
                  <Accordion.Body>
                    {item.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          </> : 
          <div className='col-12'>
           <p className='text-center'>Toll Free Number: <span>847-000-3279</span></p>
           <p className='text-center'>Email ID: <span>customercare@trc.in</span></p>
          </div>
          }
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {

  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
