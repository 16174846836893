import React, { useEffect, useState, useRef } from "react";
import "./header.scss";
import cart from "../../Assets/Icons/cartIcon.svg";
import fav from "../../Assets/Icons/heartIcon.svg";
import { commonServices } from "../../Utility/common.services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../Login/Login";
import * as icons from "../../Assets/Icons/index";
import { useNavigate } from "react-router-dom";
import { micrositeService } from "../../Utility/microsite.service";
import { cartService } from "../../Utility/cart.service";
import { toast } from "react-toastify";
import RedeemCodeModal from "../Common/RedeemCodeModal";
import ShoppingCart from "../Common/ShoppingCart";
import HeaderSearch from "./HeaderSearch";
import { homeService } from "../../Utility/home.service";
import { config } from "../../Utility/config";

function Header(props) {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const timeoutRef = useRef(null);

  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [screnS, setScrenS] = useState(window.innerWidth);
  const [dropdownList, setDropdownList] = useState([
    "Profile",
    "Order History",
    "Point Balance",
    "Logout",
  ]);
  const [dropdown, setDropDown] = useState(false);
  const [openRedeemCodeModal, setOpenRedeemCodeModal] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [headerModalShow,setHeaderModalShow] = useState(false);
  const [registrationDetail,setRegistrationDetail]=useState({})
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [customerDetails, SetcustomerDetails] = useState(props.customerDetails)

  const registeringWithCode=async()=>{
    if(window.location.href.split('?').length>=2){
      const code= window.location.href.split('?')[1]
      if(code.split('=').length>=2){
         const codeValue = code.substring(code.indexOf('=') + 1);
         try {
          let response = await homeService.GetRegistrationDetail(codeValue);
          if (response?.status?.code === 200) {
              props.setShowLoginForm(true);
              props.setIsLoginThroughCode(true)
              // setRegistrationDetail(response.data)
              props.setRegistrationDetail(response.data)
          } else {
            toast.error(response?.returnMessage);
          }
        } catch (e) {
          console.log("Error in logout", e);
        }
      }
    }
  }

  useEffect(()=>{
    registeringWithCode()
  },[])

  useEffect(()=>{
    if (customerDetails?.isLogin){
      getAvailablePoints();
    }
  }, [customerDetails])

  const getAvailablePoints = async () =>{
    try {
      let res = await micrositeService.GetCustomerAvailablePoints();
      if (res?.status?.code === 200) {
        props.setAvailablePoints(res?.data?.availablePoints)
      } else {
        props.setAvailablePoints(0);
        toast.error(res?.status?.message)
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      props.setAvailablePoints(0);
      console.log("Error in redeemCode", e);
    }
  }

  useEffect(() => {
    searchCustomer();
    // const getSize = () => {
    //   setScrenS(window.innerWidth);
    //   props.setScreenSize(window.innerWidth);
    // };
    // window.addEventListener("resize", getSize);
    // return () => {
    //   window.removeEventListener("resize", getSize);
    // };
  }, []);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      props.setAvailablePoints(0); 
      props.setCustomerDetails({});
      props.setCartDetailsData([]);
      toast.error('Session expired due to inactivity');
      window.location.href = "/dashboard";
    }, 20 * 60 * 1000);
  };

  useEffect(() => {
    const events = ['mousemove', 'keypress'];
    events.forEach((event) => window.addEventListener(event, resetTimeout));

    resetTimeout();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimeout));
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const searchCustomer = async () => {
    try {
      let response = await micrositeService.SearchCustomer();
      if (response?.status?.code === 200) {
        getCart();
        props.setCustomerDetails(response?.data);
        props.setAvailablePoints(response?.data?.availablePoints)
      } 
      else if (response === 401){
        props.setCustomerDetails({});
        props.setAvailablePoints(0)
        props.setShowLoginForm(true);
      }
      else {
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
      }
    } catch (e) {
      props.setAvailablePoints(0)
      props.setCustomerDetails({});
      console.log("Error in searchCustomer", e);
    }
  };

  const getCart = async () => {
    try {
      let response = await cartService.GetCart();
      if (response?.status?.code === 200) {
        setCartCount(response?.data?.count);
        props.setCartDetailsData(response?.data);
      } 
      else if (response === 401){
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setCartDetailsData([]);
      }
      else {
        setCartCount(0);
      }
    } catch (e) {
      setCartCount(0);
      props.setCartDetailsData([]);
      console.log("Error in getCart", e);
    }
  };

  const handleShowLoginForm = () => {
    props.setShowLoginForm(true);
  };

  const openDropdown = () => {
    setDropDown((prev) => !prev);
  };

  const openPage = (ele) => {
    if (ele === "Logout") {
      logout();
    } else {
      let activePage = ele === "Profile" ? 1 : ele === "Point Balance" ? 5 : 3;
      navigate("/profile", { state: { activePage: activePage } });
      setDropDown(false);
    }
  };

  const logout = async () => {
    setLoader(true);
    try {
      let response = await micrositeService.LogOut();
      if (response?.returnCode === 200) {
        toast.success(response?.returnMessage);
        window.location.href = "/dashboard";
      } else {
        toast.error(response?.returnMessage);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error in logout", e);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDown(false); 
    }
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setOpenSearchBar(false); 
    }
  };

  const handleSearchBarOpening = () => {
    setOpenSearchBar(!openSearchBar);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToCart = () => {
    props.setShowShoppingCart(true);
  };

  const handleRefresh = (e, targetPath) => {
    const currentPath = window.location.pathname;

    if (currentPath === targetPath) {
        e.preventDefault(); 
        window.location.reload();  
    } else {
        navigate(targetPath); 
    }
  };

  return (
    <>
          {loader && (
        <div className="loader-overlay flight_loader">
          <div className="boot-strap-loader">
            <div className="spinner-border text-warning" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
          <p>
            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
          </p>
        </div>
      )}
    <div className="header_main_div">
      <div className="bgHeader">
        <div className="imgSearch">
          <div className="optionTab">
            <Link className="option_div" to="/dashboard">
              <img
                src={images["rewardcompany_logo.svg"]}
                alt="img"
                className="logo_div"
              />
            </Link>
            {/* <div className="searchBox">
            <input
              type="text"
              placeholder="Enter brand name to search offers"
              className="inputForm"
            />
            <button className="srcImg">
              <img src={images["searchIcon.svg"]} alt="img" />
            </button>
          </div> */}
            <Link className={`option_div ${window.location.pathname==='/shop' ? 'active':''}`} to="/shop"  onClick={(e) => handleRefresh(e, "/shop")}>
              Shop
            </Link>
            {config?.programCode !== "er-trc" ?
            <>
            <Link className={`option_div ${window.location.pathname==='/deals' ? 'active':''}`} to="/deals"  onClick={(e) => handleRefresh(e, "/deals")}>
              Deals
            </Link>
            <Link className={`option_div ${window.location.pathname==='/voucher' ? 'active':''}`} to="/voucher" onClick={(e) => handleRefresh(e, "/voucher")} >
              Voucher
            </Link>
            </>
            :null}
            {/* <Link className="option_div" to="/">
              Gifting
            </Link>
            <Link className="option_div" to="/utility">
              Utility
            </Link>
            <Link className="option_div" to="/travel">
              Travel
            </Link> */}
    
          </div>
        </div>
        <div className="login-img_wrap">
          {/* <img src={images["search_logo.svg"]} alt="img" className="" /> */}
          <div className={`redeem_div`} onClick={
            props.customerDetails?.isLogin ?
              () => setOpenRedeemCodeModal(!openRedeemCodeModal) :
              () => handleShowLoginForm()
          }>
            {/* <img src={images["ticket_logo.svg"]} alt="img" className="" /> */}
            <label>Redeem Code</label>
          </div>
          {/* <img src={icons.rewardsIcon} alt="img" className="reward_img img-fluid" /> */}
          <div className="icon_div">
            {/* {openSearchBar &&
                <div className="input-group" ref={searchRef}>
                  <input
                    type="text"
                    placeholder="Enter brand name to search offers"
                    className={"input-area"}
                  />
                  <div className="end-add-on">
                  <img className="img-fluid" src={images["search_icon.svg"]} />
                </div>
            } */}
            <img src={icons.magnifyGlass} alt="img" className={`img-fluid`} onClick={()=>setHeaderModalShow(!headerModalShow)}/>
            {/* <img src={icons.heartIcon} alt="img" className="img-fluid" /> */}
            <div onClick={
                props.customerDetails?.isLogin ?
                () => goToCart() :
                () => handleShowLoginForm()
              }>
              <img src={icons.cartIcon} alt="img" className="img-fluid" />
              {props.cardDetailsData?.count &&
                parseInt(props.cardDetailsData.count) > 0 ? (
                <span className="badge">{props.cardDetailsData.count}</span>
              ) : null}
            </div>
          </div>
          {props.isShowShoppingCart && <ShoppingCart />}

          <button
            className="loginBtn"
            onClick={
              props.customerDetails?.isLogin
                ? () => openDropdown()
                : () => handleShowLoginForm()
            }
          >
            {props.customerDetails?.isLogin ? (
              <>
              <div className="loginBtn-description">
                <span>Hi {props.customerDetails?.firstName}</span>
                <p className="points">
                  TRC Points: {props.availablePoints}
                </p>
              </div>
              <img src={images["downArrow.svg"]} alt="img" />
              </>
            ) : (
              <div className="loginBtn-description">
                <p className="points center">Login / Sign Up</p>
              </div>
            )}
          </button>

          {dropdown ? (
            <ul className="drpdown" ref={dropdownRef}>
              {dropdownList?.map((ele, index) => {
                return (
                  <li key={index} onClick={() => openPage(ele)}>
                    {ele}
                  </li>
                );
              })}
            </ul>
          ) : null}

          {props.isShowLoginForm && !props.customerDetails?.isLogin && (
            <Login />
          )}
        </div>
      </div>
      {/* {props.screenSize > 735 &&
                <div className="subHeader row">
                    <div className="optionTab col-9">
                        <Link className="option_div" to="/deals">Deal</Link>
                        <Link className="option_div" to="/shop">Shop</Link>
                        <Link className="option_div" to="/">Gifting</Link>
                        <Link className="option_div" to="/utility">Utility</Link>
                        <Link className="option_div" to="/travel">Travel</Link>
                        <Link className="option_div" to="/">Voucher</Link>
                    </div>
                    <div className="redeem_div col-3">
                        <img src={images["star.svg"]} alt="img" className="" />
                        <label>Redeem Code</label>
                    </div>
                </div>
            } */}
      {openRedeemCodeModal && (
        <RedeemCodeModal
          closeModal={openRedeemCodeModal}
          setCloseModal={setOpenRedeemCodeModal}
        />
      )}
      <HeaderSearch headerModalShow={headerModalShow} setHeaderModalShow={setHeaderModalShow}/>
    </div>
    </>
 
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowLoginForm: state.isShowLoginForm,
    customerDetails: state.customerDetails,
    cardDetailsData: state.cardDetailsData,
    isShowShoppingCart: state.isShowShoppingCart,
    availablePoints: state.availablePoints,
    registrationDetail: state.registrationDetail
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
    setIsLoginThroughCode: (loginThroughCode) => {
      dispatch({ type: "LOGIN_THROUGH_CODE", payload: loginThroughCode });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
    setRegistrationDetail: (registrationDetail) => {
      dispatch({ type: "REGISTRATION_DETAILS", payload: registrationDetail });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
