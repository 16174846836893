import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import "../../../Assets/Style/style.scss";
import { commonServices } from "../../../Utility/common.services";
import { micrositeService } from "../../../Utility/microsite.service";
import PaginatedPages from "../../Common/PaginatedPages";
import { connect } from "react-redux";
import Moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function PointsBalance(props) {
  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [pointsHistory, setPointsHistory] = useState([]);
  const [memberDetails, setMemberDetails] = useState();
  const [postperPage, setPostPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    searchMember();
  }, [])

  useEffect(() => {
    getCustomerTransactionDetails();
  }, [postperPage, currentPage])

  const postPerPageUpdate = (event) => {
    let value = parseInt(event.target.value);
    setPostPerPage(value)
    setCurrentPage(1)
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    // this.setState({
    //     currentPage: pageNumber,
    //     from: from
    // }, () => this.getDonations())
  }

  const handleNext = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const searchMember = async () => {
    setLoader(true);
    setLoadingPrefixText("Getting your details!");
    try {
      let res = await micrositeService.SearchMember();
      if (res?.status?.code === 200) {
        setMemberDetails(res.data)
      }
      else {
        toast.error(res?.status?.message);
      }
      setLoader(false);

    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
      console.log("Error in searchMember", e);
    }
  }

  const getCustomerTransactionDetails = async () => {
    setLoader(true);
    setLoadingPrefixText("Getting your transaction details!");
    try {
      let res = await micrositeService.GetCustomerTransactionDetails(postperPage, currentPage);
      if (res?.status?.code === 200) {
        setPointsHistory(res.data.memberTransactionResponseListDTO)
        setCount(res?.data?.count)
      }
      else {
        toast.error(res?.status?.message);
        if (currentPage > 1) {
          setLastPage(true)
        }
      }
      setLoader(false);

    } catch (e) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
      console.log("Error in searchMember", e);
    }
  }


  return (
    <>
      <ToastContainer />
      <div className="points-balance-container">
        {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
        <div className="points-summary-details-box content-box mb-3">
          <h4>Points Summary</h4>
          <div className="d-flex justify-content-flex-start align-items-center mt-2 parent-box">
            <div className="inner-details-box">
              <img src={images["wallet.svg"]} alt="image" />
              <h3>Total Earned</h3>
              <p>{memberDetails?.totalPointsAccrued ?? 'N.A.'}</p>
            </div>
            <div className="inner-details-box">
              <img src={images["availablePoints.svg"]} alt="image" />
              <h3>Available Points</h3>
              <p>{props.customerDetails?.availablePoints ?? 'N.A.'}</p>
            </div>
            <div className="inner-details-box">
              <img src={images["pointsRedeemed.svg"]} alt="image" />
              <h3>Points Redeemed</h3>
              <p>{memberDetails?.totalPointsRedeemed ?? 'N.A.'}</p>
            </div>
            <div className="inner-details-box">
              <img src={images["pointsExpired.svg"]} alt="image" />
              <h3>Points Expired</h3>
              <p>{memberDetails?.totalPointsLapsed ?? 'N.A.'}</p>
            </div>
          </div>
        </div>
        <div className="table-orders content-box">
          <h4>Points History</h4>
          <div className="table-context">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Transaction Date</th>
                  <th>Description</th>
                  <th>Transaction ID</th>
                  <th>Credit</th>
                  <th>Debit</th>
                  <th>Transaction Type</th>
                </tr>
              </thead>
              <tbody>
                {pointsHistory?.length > 0 ?
                  pointsHistory?.map((ele, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{Moment(ele?.billDate).format("MM/DD/YYYY") ?? '-'}</td>
                        <td>{ele.narration ?? '-'}</td>
                        {/* <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {billno}
                            </Tooltip>
                          )}
                          placement="bottom"
                        > */}
                            <td className="bill-no" title={ele.billNo}>{ele.billNo ? (ele.billNo.length > 12 ? `${ele.billNo.slice(0, 12)}...` : ele.billNo) : '-'}</td>
                        {/* </OverlayTrigger> */}
                        <td>{ele.totalAccruedPoints ?? '-'}</td>
                        <td>{ele.totalRedeemPoints ?? '-'}</td>
                        <td>{ele.isRedeemed ? 'Redeem' : 'Earn'}</td>
                      </tr>
                    );
                  })
                  :
                  <td colSpan="12">
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <img src={images["nodata.jpeg"]} />
                        <p>No Points History Found</p>
                      </div>

                    </div>
                  </td>
                }
              </tbody>
            </table>
            <div className="custom-pagination row mt-3">
              {true ?
                <div className='col-lg-6 visible-desktop'>
                  <div className="page-items">
                    <select id="inputState" className="form-control" value={postperPage} onChange={postPerPageUpdate}>
                      <option defaultValue>5</option>
                      <option>10</option>
                      <option>15</option>
                      <option>20</option>
                    </select>
                    <span>Items per page</span>
                  </div>
                </div>
                : null
              }
              <div className='col-lg-6 p-xs-0'>
                <div className="button-container-pagination">
                  <button className={`${currentPage === 1 || loader ? 'disabled' : ''}`} onClick={handlePrevious} disabled={currentPage === 1 || loader}>
                    {'<'}
                  </button>
                  <button className={`${pointsHistory.length < postperPage || loader || lastPage ? 'disabled' : ''}`} onClick={handleNext} disabled={pointsHistory.length < postperPage || loader}>
                    {'>'}
                  </button>
                </div>
                {/* <div className='pagination-div'>
                <PaginatedPages
                  postsPerPage={postperPage}
                  totalPosts={count}
                  //totalPosts={currentPosts !== null ? currentPosts.length : 0}
                  paginateNumber={paginate}
                  openedPage={currentPage}
                />
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    customerDetails: state.customerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsBalance);
