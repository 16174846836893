import React, { useEffect, useState } from "react";
import * as icons from "../../Assets/Icons/index";
import { dealService } from "../../Utility/deal.service";
import { useLocation } from "react-router-dom";
import { commonComponets } from "../Common/commonComponent";
import { commonServices } from "../../Utility/common.services";
import { connect } from "react-redux";
import ShoppingCart from "../Common/ShoppingCart";
import CardDetails from "../Common/commonSwiper";
import { cartService } from "../../Utility/cart.service";
import { useNavigate } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { voucherService } from "../../Utility/voucher.service";
import TermsConditionModal from "../Common/TermsConditionModal";
import { ToastContainer, toast } from "react-toastify";
import Login from "../Login/Login";

const VoucherDetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productQuantity, setProductQuantity] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [isIncrement, setIsIncrement] = useState(true);
  const [isDecrement, setIsDecrement] = useState(false);
  const [voucherDetails, setVoucherDetails] = useState({});
  const [similarVoucher, setSimilarVoucher] = useState([]);
  const [openTermsAndConditionModal, setOpenTermsAndConditionModal] = useState(false)

  const handleTermsAndConditions = () => {
    setOpenTermsAndConditionModal(!openTermsAndConditionModal)
  }

  const incNum = () => {
    const updatedDealDetails = { ...voucherDetails };

    if (Number(updatedDealDetails.minimumQuantity) < 10) {
      updatedDealDetails.minimumQuantity =
        Number(updatedDealDetails.minimumQuantity) + 1;

      setVoucherDetails(updatedDealDetails);

      setIsIncrement(true);
      setIsDecrement(false);
    }
    else {
      toast.error("You can add up to 10 quantities!")
    }
  };

  const decNum = () => {
    const updatedVoucherDetails = { ...voucherDetails };

    if (updatedVoucherDetails.minimumQuantity > 1) {
      updatedVoucherDetails.minimumQuantity =
        Number(updatedVoucherDetails.minimumQuantity) - 1;

      setVoucherDetails(updatedVoucherDetails);

      setIsIncrement(false);
      setIsDecrement(true);
    }
  };

  useEffect(() => {
    getVoucherDetails(location.state);
  }, []);

  const getSimilarVouchers = async (departmentId, skuId) => {
    try {
      let res = await voucherService.GetSimilarVouchers(departmentId, skuId);
      if (res?.status?.code === 200) {
        setSimilarVoucher(res.data);
      } else {
        setSimilarVoucher([]);
      }
    } catch (e) {
      setSimilarVoucher([]);
      console.log("Error in getSimilarDeals", e);
    }
  };

  const getVoucherDetails = async (skuId) => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are fetching your voucher details");
    try {
      let res = await voucherService.GetVoucherDetails(skuId);
      if (res?.status?.code === 200) {
        getSimilarVouchers(res?.data?.departmentId, res?.data?.sku);
        setVoucherDetails(res.data);
        document.getElementById('description').innerHTML = res?.data?.stockItemDescription
        if (skuId !== location.state) {
          navigate(location.pathname, { state: skuId });
        }
        window.scrollTo(0, 0);
      } else {
        setVoucherDetails([]);
      }
      setLoader(false);
      setLoadingPrefixText("");
    } catch (e) {
      setVoucherDetails([]);
      console.log("Error in getVoucherDetails", e);
    }
  };

  const addToCart = async () => {
    setLoader(true);
    setLoadingPrefixText("Hold on!! We are adding it to your cart");
    try {
      let res = await cartService.SaveCartItem(
        voucherDetails.stockItemTypeId,
        voucherDetails.stockItemId,
        voucherDetails.minimumQuantity
      );
      if (res?.status?.code === 200) {
        props.setShowShoppingCart(true);
      }
      else if (res === 401) {
        props.setCurrentPage(window.location.pathname)
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        props.setShowLoginForm(true);
        props.setCartDetailsData([]);
      }
      else {
        toast.error(res?.status?.message);
      }
      setLoader(false);
      setLoadingPrefixText('')
    } catch (e) {
      setLoader(false);
      console.log("Error in SaveCartItem", e);
    }
  };

  const buyNow = () => {
    navigate("/deliverydetails");
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
      {loader && (
          <div className="loader-overlay flight_loader">
            <div className="boot-strap-loader">
              <div className="spinner-border text-warning" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
            <p>
              {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
            </p>
          </div>
        )}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard" className="disabled">
                HOME
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/voucher" className="disabled">
                VOUCHER
              </a>
            </li>
          </ol>
        </nav>

        <div className="deals_details_container mb-4">
          <div className="image-container">
            <img
              src={commonServices.imageByDimensions(
                voucherDetails?.stockItemImage,
                "20by20"
              )}
            />
          </div>
          <div className="productDetails_wrap">
            <div>
              <h3>{voucherDetails?.brandName}</h3>
            </div>
            <span>{voucherDetails?.stockItemTitle}</span>
            <h4>
              {voucherDetails?.mrp !== voucherDetails?.price ?
                <React.Fragment>
                  <span className="without_discount">
                    ₹{voucherDetails?.mrp}&nbsp;
                  </span>
                  <span className="connecter">
                    &nbsp;{"-"}&nbsp;
                  </span>
                  ₹ {voucherDetails?.price}&nbsp;
                </React.Fragment>
                :
                <React.Fragment>
                  <span className="without_discount only-price">
                    ₹{voucherDetails?.mrp}
                  </span>
                </React.Fragment>
              }
              <span className="points"> / {voucherDetails?.points} Pts.</span>
            </h4>

            <div className="select_quant_btn">
              <p className="quantity" >Select Quantity</p>
              <div className="quantity_btn mb-4">
                <button
                  disabled={voucherDetails?.minimumQuantity === 1 ? true : false}
                  style={
                    voucherDetails?.minimumQuantity === 1
                      ? { cursor: "none" }
                      : null
                  }
                  className={
                    voucherDetails?.minimumQuantity > 1
                      ? isDecrement
                        ? "btnbgcolor"
                        : "btnbordernone"
                      : "btnbordernone"
                  }
                  onClick={() => decNum()}
                  type="button"
                >
                  {/* {voucherDetails?.minimumQuantity > 1 && isDecrement ? (
                  <img effect="blur" alt="btn" src={icons.colorminus} />
                ) : ( */}
                  <img effect="blur" alt="btn" src={icons.grayminus} />
                  {/* )} */}
                </button>
                <input
                  disabled
                  className="form-control"
                  value={voucherDetails?.minimumQuantity}
                />
                <button
                  id="AddToCart_Quantity"
                  onClick={() => incNum()}
                  type="button"
                  className={
                    voucherDetails?.minimumQuantity < 10
                      ? isIncrement
                        ? "btnbgcolor"
                        : "btnbordernone"
                      : "btnbordernone"
                  }
                >
                  {/* {voucherDetails?.minimumQuantity < 10 && isIncrement ? (
                  <img effect="blur" alt="btn" src={icons.plus} />
                ) : ( */}
                  <img effect="blur" alt="btn" src={icons.grayplus} />
                  {/* )} */}
                </button>
              </div>
            </div>
            <div className="btn-cart-buy">
              <button
                className="addcart"
                onClick={() => addToCart()}
              >
                Add to Cart
              </button>
              {/* <button className="buynow" onClick={() => buyNow()}>
              Buy Now
            </button> */}
            </div>

            <div className="mt-4">
              <p className="description-heading">Product Description</p>
              <p id="description">
                N.A.
                {/* {voucherDetails?.stockItemDescription} */}
              </p>
            </div>

            {voucherDetails?.stockItemTerms && (
              <p className="terms-conditions" onClick={handleTermsAndConditions}>Terms & Conditions</p>
            )}

            {/* <div className="available_offers mt-2">
            <h4>Available Offers</h4>
            <p>
              What is Lorem Ipsum Lorem Ipsum is simply 10% dummy text of the
              printing
            </p>
            <p>What is Lorem Ipsum is simply 10% dummy </p>
            <p>What is Lorem Lorem Ipsum is simply 10% dummy of the printing</p>
          </div> */}

            {/* <div className="mt-4">
            <p className="description-heading">Product Description</p>
            <p id="description">
              {voucherDetails?.stockItemDescription}
            </p>
          </div> */}
            {voucherDetails?.tnc && (
              <p className="terms-conditions" onClick={handleTermsAndConditions}>
                Terms & Conditions
              </p>
            )}
            {/* <div className="product_description">
            <h4>Product Description</h4>
            <p>{voucherDetails?.stockItemDescription}</p>
          </div>
          {voucherDetails?.stockItemTerms && (
            <div className="terms_conditions">
              <h4>Terms & Conditions</h4>
              <p>{voucherDetails?.stockItemTerms}</p>
            </div>
          )} */}
          </div>
        </div>
        {props.isShowShoppingCart && <ShoppingCart />}
        {props.isShowLoginForm && <Login />}
        {
          similarVoucher?.length > 0 && (
            <div className="similar_product mt-5">
              <div className="top-view">
                <span>Similar Products</span>
                {/* <button>Show More</button> */}
              </div>
              <div className="swp mb-5">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={4}
                  navigation
                  modules={[Navigation, Scrollbar, A11y]}
                  pagination={{ clickable: true }}
                //</div>scrollbar={{ draggable: true }}
                >
                  {
                    similarVoucher?.map((ele, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="dealCard_wrap" onClick={() => getVoucherDetails(ele.sku)}>
                            <div className="image-container">
                              <img
                                src={commonServices.imageByDimensions(
                                  ele.stockItemImage,
                                  "20by20"
                                )}
                                alt="images"
                              />
                            </div>

                            <div className="deal-details">
                              <h6>{ele?.brandName}</h6>
                              <h4>{ele?.stockItemTitle}</h4>
                              <span className="price-description">
                                {ele?.mrp !== ele?.price ?
                                  <React.Fragment>
                                    <span className="without_discount">
                                      ₹{ele?.mrp}&nbsp;
                                    </span>
                                    <span className="connecter">
                                      &nbsp;{"-"}&nbsp;
                                    </span>
                                    ₹ {ele?.price}&nbsp;
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <span className="without_discount only-price">
                                      ₹{ele?.mrp}
                                    </span>
                                  </React.Fragment>
                                }
                                <span className="points"> / {ele.points} Pts.</span>
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  }
                </Swiper>
              </div>
            </div>
          )
        }


        <TermsConditionModal
          openTermsAndConditionModal={openTermsAndConditionModal}
          setOpenTermsAndConditionModal={setOpenTermsAndConditionModal}
          tnc={voucherDetails?.tnc} />

        {/* <TermsConditionModal
        openTermsAndConditionModal={openTermsAndConditionModal}
        setOpenTermsAndConditionModal={setOpenTermsAndConditionModal}
        tnc={voucherDetails?.stockItemTerms}
      /> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    isShowShoppingCart: state.isShowShoppingCart,
    isShowLoginForm: state.isShowLoginForm
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setShowShoppingCart: (isShowShoppingCart) => {
      dispatch({ type: "IS_SHOW_SHOPPING_CART", payload: isShowShoppingCart });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
    setCurrentPage: (page) => {
      dispatch({ type: "CURRENT_PAGE", payload: page });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setCartDetailsData: (cardDetails) => {
      dispatch({ type: "CARD_DETAILS", payload: cardDetails });
    },
    setAvailablePoints: (points) => {
      dispatch({ type: "AVAILABLE_POINTS", payload: points });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherDetails);
