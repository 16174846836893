import React, { useState, useEffect } from "react";
import { commonServices } from "../../Utility/common.services";
import "./_common.scss";
import MultiRangeSlider from "./multirangeSlider";
import ReactSlider from "react-slider";
import { connect } from "react-redux";

const Tooltip = ({ value, style }) => {
  return (
    <div className="tooltip" style={style}>
      {value}
    </div>
  );
};

function valuetext(value) {
  return `${value}Rs.`;
}

function Filter(props) {
  const {
    Categories,
    Brands,
    priceToFiltered,
    pointFrom,
    pointTo,
    categoriesToFiltered,
    brandsToFiltered,
  } = props;
  const images = commonServices.importImage(
    require.context("../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [openFilter, setfilter] = useState("0");
  const [categoryItemCount, setCategoryItemCount] = useState(5);
  const [brandItemCount, setBrandItemCount] = useState(5);
  // const [values, setValues] = useState([1, 100000]);
  const [values, setValues] = useState([pointFrom, pointTo]);
  const [minPrice, setMinPrice] = useState(pointFrom);
  const [maxPrice, setMaxPrice] = useState(pointTo);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const handleClick = (e) => {
    if (e === 1) {
      setIsCategoryOpen(!isCategoryOpen);
    } else {
      setIsBrandOpen(!isBrandOpen);
    }
  };

  const handleSliderChange = (newValues) => {
    setValues(newValues);
    setMinPrice(newValues[0]);
    setMaxPrice(newValues[1]);

    props.filter("", "price", newValues);
  };

  const handleMinPriceChange = (event) => {
    const newMin = Number(event.target.value);
    if (newMin <= values[1]) {
      let newValue = [newMin, values[1]];
      setValues((prevValue) => ({
        ...prevValue,
        newMin,
      }));
      // setValues([newMin, values[1]]);
      // props.filter(event, "price", newValue);
    }
  };

  const handleMaxPriceChange = (event) => {
    let newMax = parseInt(event.target.value, 10);
    let newValue = [values[0], newMax];
    if (newMax <= 100000) {
      // setValues((prevValue) => ({
      //   ...prevValue,
      //   newValue,
      // }));
      setValues(newValue);
      // props.filter(event, "price", newValue);
    }
  };

  const resetFilter = () =>{
    setValues([1, 500000]);
    setMinPrice(1);
    setMaxPrice(500000);
    props.resetFilter()
  }

  return (
    <div className="filter_wrap">
      <h4>Filter</h4>
      {/* <div className="btn-filter ">
        <button className="clr-btn">Clear All</button>
        <button className="apply-btn">Apply</button>
      </div> */}
      {Categories?.length > 0 && (
        <div className="filter-category">
          <div className="accordian-wrap top_border">
            <div className="category" onClick={() => handleClick(1)}>
              <span>Categories</span>
              <img src={images["downArrow.svg"]} alt="name" />
            </div>
            {isCategoryOpen ? (
              <div
                className={
                  categoryItemCount > 10
                    ? "checkbox_wrap show_scroll"
                    : "checkbox_wrap"
                }
              >
                {Categories?.slice(0, categoryItemCount)?.map((ele, index) => {
                  return (
                    <div className="check-box" key={index}>
                      <input
                        type="checkbox"
                        id={ele.name + index}
                        onChange={(event) =>
                          props.filter(event, "category", ele.id.toString())
                        }
                        checked={categoriesToFiltered?.includes(ele.id)}
                      />
                      <label htmlFor={ele.name + index}>{ele.name}</label>
                    </div>
                  );
                })}
                {categoryItemCount !== Categories?.length &&
                Categories?.length > 5 ? (
                  <button
                    className="show_more_less"
                    onClick={() => setCategoryItemCount(Categories?.length)}
                  >
                    Show More{" (" + (Categories?.length - 5) + ")"}
                  </button>
                ) : (
                  Categories?.length > 0 &&
                  Categories?.length > 5 && (
                    <button
                      className="show_more_less"
                      onClick={() => setCategoryItemCount(5)}
                    >
                      Show Less
                    </button>
                  )
                )}
              </div>
            ) : null}
          </div>
        </div>
      )}
      {Brands?.length > 0 && (
        <div className="filter-category">
          <div className="accordian-wrap">
            <div className="category" onClick={() => handleClick(2)}>
              <span>Brands</span>
              <img src={images["downArrow.svg"]} alt="name" />
            </div>
            {isBrandOpen ? (
              <div
                className={
                  brandItemCount > 10
                    ? "checkbox_wrap show_scroll"
                    : "checkbox_wrap"
                }
              >
                {Brands?.slice(0, brandItemCount).map((ele, index) => {
                  return (
                    <div className="check-box" key={index}>
                      <input
                        type="checkbox"
                        id={ele.name + index}
                        onChange={(event) =>
                          props.filter(event, "brand", ele.id.toString())
                        }
                        checked={brandsToFiltered?.includes(ele.id)}
                      />
                      <label htmlFor={ele.name + index}>{ele.name}</label>
                    </div>
                  );
                })}

                {brandItemCount !== Brands?.length && Brands?.length > 5 ? (
                  <button
                    className="show_more_less"
                    onClick={() => setBrandItemCount(Brands?.length)}
                  >
                    Show More {" (" + (Brands?.length - 5) + ")"}
                  </button>
                ) : (
                  Brands?.length > 0 &&
                  Brands?.length > 5 && (
                    <button
                      className="show_more_less"
                      onClick={() => setBrandItemCount(5)}
                    >
                      Show Less
                    </button>
                  )
                )}
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className="filter-category mt-2">
        <div className="accordian-wrap">
          <div className="category">
            <span>Price Range</span>
          </div>
          <div className="range_slider">
            <ReactSlider
              min={1}
              max={500000}
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              withTracks
              value={values}
              onChange={handleSliderChange}
              getAriaValueText={valuetext}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="input-group">
              <input
                type="text"
                className="input-area"
                value={"Rs. " + minPrice}
                aria-label="Minimum price input"
                placeholder="Min"
                onChange={(e) => handleMinPriceChange(e)}
                min={minPrice}
                max={values[1]}
                disabled
              />
            </div>
            <span className="mx-2 binding_element">to</span>
            <div className="input-group">
              <input
                type="text"
                className="input-area"
                value={"Rs. " + maxPrice}
                aria-label="Maximum price input"
                placeholder="Max"
                onChange={(e) => handleMaxPriceChange(e)}
                //min={value[0]}
                min={minPrice}
                max={maxPrice}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="filter_btns">
          {props.isFilterChecked &&
          <button onClick={() => resetFilter()}>Reset</button>
          }
          <button className={props.isShowApply ? "apply_btn" : "apply_btn disabled_btn"} disabled={!props.isShowApply} onClick={() => props.applyFilter()}>Apply </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isFilterChecked: state.isFilterChecked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
