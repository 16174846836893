import React, { useState, useEffect } from "react";
import "../_userDetails.scss";
import { commonServices } from "../../../Utility/common.services";
import { commonComponets } from "../../Common/commonComponent";

import { connect } from "react-redux";
import { addressService } from "../../../Utility/address.service";
import { ToastContainer, toast } from "react-toastify";
import AddressModal from "./AddressModal";
import EditProfileModal from "./EditProfileModal";
import { micrositeService } from '../../../Utility/microsite.service';
import { redisService } from '../../../Utility/redis.service';
import VerifyEmail from "./VerifyEmail";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Common/DeleteModal";

function ProfileDashboard(props) {
  const navigate = useNavigate();

  const images = commonServices.importImage(
    require.context("../../../Assets/Icons/", false, /\.(png|jpe?g|svg)$/)
  );
  const [editModal, setEditModal] = useState(false);
  const [addeditModal, setaddEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadingPrefixText, setLoadingPrefixText] = useState("");
  const [addresses, setAddresses] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [particularAddress, setParticularAddress] = useState({});
  const [states, setStates] = useState([]);
  const [verifyEmail,setVerifyEmail]=useState(false)
  const [emailVerifiedStatus,setEmailVerifiedStatus]=useState(false)
  const address={
    AddressType: "Home",
    termCondition: false,
  }
  const [customerAddresDetails, setCustomerAddressDetails] = useState(address);
  const [requestId, setRequestId] = useState("");

  const handleEditModal = () => {
    setEditModal(!editModal);
  };
  const handleAddressEditModal = (e) => {
    setParticularAddress({})
    setCustomerAddressDetails(address)
    setaddEditModal(false);
  };

  useEffect(() => {
    fetchAddressesByUser();
  }, []);

  console.log(particularAddress,customerAddresDetails,"sweti")

  const openEditHanler = async(data) => {
    console.log(data)
    let detail = customerAddresDetails;
    let str = "";
    let arr = [];
    // const response=await getShopStates();
    let state = states?.find((ele) => ele?.stateName === data?.state);
    console.log(state,states)
    state = state?.stateName + "_" + state?.stateId;
    str = data?.receiver_name;
    arr = str.split(/ (.*)/);
    detail.firstName = arr.length ? arr[0] : "";
    detail.lastName = arr.length ? arr[1] : "";
    detail.emailID = data.email;
    detail.mobileNo = data.mobile;
    detail.AddressType = data.addressType !== "" ? data.addressType : "Other";
    detail.Address = data.address;
    detail.pinCode = data.pin_code;
    detail.country = data.country;
    detail.state = state;
    detail.city = data.city;
    detail.address_id = data.address_id;
    detail.is_default = data.is_default;
    detail.locality = data.locality;
    detail.termCondition = true;
    detail.locality = data.locality;
    console.log(detail)
    setCustomerAddressDetails(detail);
    setaddEditModal(true)
    // setAddNewAddress(false);
    // setOpenEditAddress(true);
    // setShowAddressPage(true);
  };

  const runningStates=async()=>{
    await getShopStates();
  }

  useEffect(()=>{runningStates()},[particularAddress])


  useEffect(() => {
    console.log(Object?.keys(particularAddress))
    if (states.length>0 && Object?.keys(particularAddress).length>0) {
      openEditHanler(particularAddress)
    }

    //getShopCities();
    //fetchAddressesByUser();
  }, [states,particularAddress]);

  const handleAddressRemove = async() =>{
    setLoader(true);
    setLoadingPrefixText("Deleting your address details!");
    try {
      if(addressId.is_default){
        toast.error("You cannot delete the default address!!");
        return
      }
      let response = await addressService.DeleteAddress(addressId.address_id);
      if (response?.status?.code === 200) {
        toast.success('Address Deleted Successfully!!');
        fetchAddressesByUser()
        setAddressId('')
        setShowDeleteModal(false)
        // fetchAddressesByUser();
      }
      // else if (response === 401) {
      //   props.setShowLoginForm(true);
      //   props.setCurrentPage("/addressdetails")
      // }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  }

  // useEffect(()=>{
   
  // },[customerAddresDetails])

  // const checkCustomerProductAddress = () => {
  //   debugger
  //   setCustomerAddressDetails((prevDetails) => {
  //     let updatedDetails = { ...prevDetails };

  //     if (!updatedDetails.firstName || updatedDetails.firstName === "") {
  //       updatedDetails.invalidFname = true;
  //       toast.error("Please enter your first name.");
  //     } else {
  //       updatedDetails.invalidFname = false;
  //     }

  //     if (!updatedDetails.lastName || updatedDetails.lastName === "") {
  //       updatedDetails.invalidLname = true;
  //       toast.error("Please enter your last name.");
  //     } else {
  //       updatedDetails.invalidLname = false;
  //     }
  //     if (
  //       !updatedDetails.emailID ||
  //       updatedDetails.emailID === "" ||
  //       !validateEmail(updatedDetails.emailID)
  //     ) {
  //       updatedDetails.invalidEmail = true;
  //       toast.error(
  //         updatedDetails.emailID === "" ||
  //         !updatedDetails.emailID
  //           ? "Please enter your email id."
  //           : "Invalid e-mail address"
  //       );
  //     } else {
  //       updatedDetails.invalidEmail = false;
  //     }

  //     if (
  //       !updatedDetails.mobileNo ||
  //       updatedDetails.mobileNo === "" ||
  //       updatedDetails.mobileNo.length < 10
  //     ) {
  //       updatedDetails.invalidMobile = true;
  //       toast.error("Please enter your mobile number.");
  //     } else {
  //       updatedDetails.invalidMobile = false;
  //     }

  //     if (!updatedDetails.Address || updatedDetails.Address === "") {
  //       updatedDetails.invalidAddress = true;
  //       toast.error("Please enter your address.");
  //     } else {
  //       updatedDetails.invalidAddress = false;
  //     }

  //     if (!updatedDetails.city || updatedDetails.city === "") {
  //       updatedDetails.invalidCity = true;
  //       toast.error("Please select your city.");
  //     } else {
  //       updatedDetails.invalidCity = false;
  //     }

  //     if (!updatedDetails.state || updatedDetails.state === "") {
  //       updatedDetails.invalidState = true;
  //       toast.error("Please select your state.");
  //     } else {
  //       updatedDetails.invalidState = false;
  //     }

  //     if (
  //       !updatedDetails.pinCode ||
  //       updatedDetails.pinCode === "" ||
  //       updatedDetails.pinCode.length !== 6
  //     ) {
  //       updatedDetails.invalidPincode = true;
  //       toast.error("Please enter your pincode.");
  //     } else {
  //       updatedDetails.invalidPincode = false;
  //     }

  //     // if (
  //     //   updatedDetails.firstName !== "" &&
  //     //   updatedDetails.lastName !== "" &&
  //     //   updatedDetails.emailID !== "" &&
  //     //   updatedDetails.mobileNo !== "" &&
  //     //   updatedDetails.pinCode !== "" &&
  //     //   updatedDetails.pinCode.length === 6 &&
  //     //   updatedDetails.city !== "" &&
  //     //   updatedDetails.state !== "" &&
  //     //   updatedDetails.Address !== "" &&
  //     //   updatedDetails.invalidEmail !== true
  //     // ) {
  //     //   updatedDetails.termCondition = !updatedDetails.termCondition;
  //     // } else {
  //     //   toast.error(
  //     //     updatedDetails.invalidEmail
  //     //       ? "Invalid email address"
  //     //       : "Please enter your details to proceed further!"
  //     //   );
  //     // }

  //     return updatedDetails;
  //   });
  // };


  const getShopStates = async () => {
    setLoader(true);
    try {
      let response = await redisService.GetShopStates();
      if (response?.status?.code === 200) {
        setStates(response?.data);
      } else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };


  const fetchAddressesByUser = async () => {
    setLoader(true);
    setLoadingPrefixText("Fetching your address details!");
    try {
      let response = await addressService.FetchAddressesByUser();
      if (response?.status?.code === 200) {
        setAddresses(response.data)
      } 
      else if (response === 401){
        props.setAvailablePoints(0)
        props.setCustomerDetails({});
        setAddresses([]);
        navigate("/dashboard");
      }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const saveAddress = async (customerAddresDetails) => {
    setLoader(true);
    setLoadingPrefixText("Adding your address details!");
    try {
       const hasDefault = addresses.some(address => address.is_default)
      let response = await addressService.SaveAddress({...customerAddresDetails,is_default: hasDefault ? false : true});
      if (response?.status?.code === 200) {
        handleAddressEditModal()
        fetchAddressesByUser()
        // fetchAddressesByUser();
      }
      // else if (response === 401) {
      //   props.setShowLoginForm(true);
      //   props.setCurrentPage("/addressdetails")
      // }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const editAddress = async (customerAddresDetails,setAsDefault=false) => {
    setLoader(true);
    setLoadingPrefixText("Editing your address details!");
    console.log(customerAddresDetails,"yes")
    let response;
    try {
      if(customerAddresDetails?.is_default){
        let resp = await addressService.UpdateDefaultAddress(customerAddresDetails);
        if (resp?.status?.code === 200) {
          handleAddressEditModal()
          fetchAddressesByUser()
          // fetchAddressesByUser();
        }
        else {
          toast.error(response?.status?.message);
        }
        return
      }
      if(setAsDefault){
        console.log(customerAddresDetails)
          let state = states.find((ele) => ele?.stateName === customerAddresDetails?.state)
        response = await addressService.UpdateAddress({...customerAddresDetails,is_default:true,
          state:state?.stateName + "_" + state?.stateId});
      }
      else{
        response = await addressService.UpdateAddress(customerAddresDetails);
      }
      if (response?.status?.code === 200) {
        handleAddressEditModal()
        fetchAddressesByUser()
        setCustomerAddressDetails(address)
        setParticularAddress({})
        // fetchAddressesByUser();
      }
      // else if (response === 401) {
      //   props.setShowLoginForm(true);
      //   props.setCurrentPage("/addressdetails")
      // }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  };

  const handleEditProfileDetails = async(customerProfileDetails) =>{
    setLoader(true);
    setLoadingPrefixText("Editing your profile details!");
    try {
      let response = await micrositeService.UpdateMemberProfile(customerProfileDetails);
      if (response?.status?.code === 200) {
        handleEditModal()
        let res = await micrositeService.SearchCustomer();
        if (res?.status?.code === 200) {
          toast.success('Profile Details updated successfully!!');
          props.setCustomerDetails(res.data);
        }
        //fetchAddressesByUser()
        // fetchAddressesByUser();
      }
      // else if (response === 401) {
      //   props.setShowLoginForm(true);
      //   props.setCurrentPage("/addressdetails")
      // }
      else {
        toast.error(response?.status?.message);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      toast.error("Something went wrong !! Please try again !!");
    }
  }

  const handleVerifyNow=async()=>{
    try {
      let res = await micrositeService.GenerateOTP('', props.customerDetails.email);
      if (res?.status?.code === 200) {
        setVerifyEmail(true)
        setRequestId(res.data);
        // setRequestId(res.data);
        // setIsShowValidate(true);
        // startTimer();
      } else {
        toast.error(res?.status.message);
      }
      // setLoader(false);
      // setLoadingPrefixText("");
      // setLoadingSuffixText("");
    } catch (e) {
      console.log("Error in generateOTP", e);
    }
  
  }



  return (
    <div className="profile_detail">
      {loader && (
        <div className="loader-overlay flight_loader">
          <div className="boot-strap-loader">
            <div className="spinner-border text-warning" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
          <p>
            {loadingPrefixText ? loadingPrefixText : null} <br />{" "}
          </p>
        </div>
      )}
      <div className="mb-3">
        <div className="circle-name-box">
          <div className="oval">
            <div className="circle-name">
              <span>{props?.customerDetails?.firstName?.[0]?.toUpperCase()}{props?.customerDetails?.lastName?.[0]?.toUpperCase()}</span>
            </div>
          </div>
          <div className="name-details-pr">
            <h3>Hi {props.customerDetails.firstName}</h3>
            {/* <span>{props.customerDetails.customerType}</span> */}
          </div>
        </div>
      </div>
      <div className="detail_card content-box">
        <div className="prf-btn">
          <span>Profile Details</span>
          <button onClick={handleEditModal}>Edit</button>
        </div>
        <div className="content-container">
          <div className="details">
            <h4>Full Name</h4>
            <label>{props.customerDetails.firstName} {props.customerDetails.lastName}</label>
          </div>
          <div className="details">
            <h4>Email Id</h4>
            <label>
              {props.customerDetails.email} {emailVerifiedStatus ?  <img  src={images["greentick_icon.svg"]} />:<span onClick={()=>handleVerifyNow()}>&nbsp;Verify Now</span>}
            </label>
            {/* <li>
              <h4>Gender</h4>
              <label>Female</label>
            </li> */}
          </div>
          <div className="details">
            <h4>Mobile Number</h4>
            <label>
              {props.customerDetails.mobile}&nbsp;<img src={images["greentick_icon.svg"]} alt="img" />
            </label>
          </div>
        </div>
      </div>
      <div className="detail_card content-box mt-3">
        <div className="prf-btn">
          <span>Address</span>
          <button onClick={()=>{setParticularAddress({});setaddEditModal(true)}}>+ Add new Address</button>
        </div>
        <div className="row">
          {addresses && addresses.map((address, index) => {
            return (
              <div className="col-sm-6 mt-3 address-container" key={index}>
                <div className="address-box">
                  <div className="name-default">
                    <div className="name-mobile">
                      <span>{address.receiver_name}</span>
                      <p>+{address.mobile} / {address.addressType}</p>
                    </div>
                    {address.is_default ?
                      <div className="img-tag">
                        <img src={images["greentick_icon.svg"]} alt="img" />&nbsp;<span>Default</span>
                      </div>
                      :
                      <div className="img-tag">
                        <p onClick={()=>editAddress(address,true)}>Set as Default</p>
                      </div>
                    }
                  </div>
                  <div className="cmplt-address">
                    <p>{address.address}</p>
                    <p>{address.locality}</p>
                    {/* <p> Vile Parle (W) </p> */}
                    <p> {address.city} , {address.state}</p>
                    <p> {address.country}</p>
                    <p>{address.pin_code}</p>
                  </div>
                </div>
                <div className="btn-box-adr">
                  <button onClick={()=>{setParticularAddress(address)}}>Edit</button>
                  <button onClick={()=>{setShowDeleteModal(true);setAddressId(address)}} >Remove</button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      
      {/* AddressEdit */}
      {addeditModal && 
      <AddressModal 
      addeditModal={addeditModal} handleAddressEditModal={handleAddressEditModal} saveAddress={saveAddress} 
      particularAddress={particularAddress}  editAddress={editAddress} setStates={setStates} states={states} 
      customerAddresDetails={customerAddresDetails} setCustomerAddressDetails={setCustomerAddressDetails}
     />}
      {editModal && <EditProfileModal editModal={editModal} handleEditModal={handleEditModal} handleEditProfileDetails={handleEditProfileDetails}/>}
      {verifyEmail && <VerifyEmail verifyEmail={verifyEmail} setVerifyEmail={setVerifyEmail} requestId={requestId} setEmailVerifiedStatus={setEmailVerifiedStatus} handleVerifyNow={handleVerifyNow}/>}
      {showDeleteModal && <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} setAddressId={setAddressId} handleAddressDelete={handleAddressRemove}/>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    customerDetails: state.customerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (sSize) => {
      dispatch({ type: "SCREEN_SIZE", payload: sSize });
    },
    setCustomerDetails: (custDetails) => {
      dispatch({ type: "CUSTOMER_DETAILS", payload: custDetails });
    },
    setShowLoginForm: (isShowLoginForm) => {
      dispatch({ type: "IS_SHOW_LOGIN_FORM", payload: isShowLoginForm });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboard);

