import $ from "jquery";

const htmlEncode = (value) => {
    return $("<div/>").text(value).html().replace(/&/g, "%26");
};
const groupByGroupKey = (data) => {
    const groupedData = {};
    data.forEach(item => {
        const group = item.groupKey;
        if (!groupedData[group]) {
            groupedData[group] = [];
        }
        groupedData[group].push(item);
    });
    console.log(Object.values(groupedData))
    return Object.values(groupedData);
}
export const Utility = {
    htmlEncode,
    groupByGroupKey
}